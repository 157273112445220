
<section id="slider" class="inner_bnr" style="background:url(assets/images/contact_bnr.jpg) no-repeat center center / cover;">
	<h2 class="upr">How can we help you today?</h2>
	<span class="upr">Fill in the form and we will get back to you in two working days.</span>
</section>

<section id="contact_dtl">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 mb-3 mb-md-0 order-1 order-md-1">
				<div class="contact_bx">
					<h4>UK Detail</h4>
					<p>Unit 29, Enterprise Industrial Estate,Bolina Road London SE16 3LF United Kingdom</p>
					<p>+44 (0)203 239 2186</p>
					<p><a href="mailto:info@teamplayerhr.com">info@TeamPlayerHR.com</a></p>
				</div>
			</div>

			<div class="col-lg-12 col-md-12 col-sm-12 mb-5 mb-md-0 order-2 order-md-3">
				<div class="map">
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.4306141098004!2d-0.05490438469247265!3d51.48696492001864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876031b89f4b6d9%3A0xfa724697c19a354b!2sEnterprise%20Industrial%20Estate%2C%20Bolina%20Rd%2C%20Bermondsey%2C%20London%20SE16%203LF%2C%20UK!5e0!3m2!1sen!2sin!4v1592404738475!5m2!1sen!2sin" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
				</div>
			</div>
		</div>
	</div>
</section>

<section id="contact_form">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 col-md-6 mt-4 mb-4 mb-md-0">
				<div class="form_detl">
					<h2>Reach Out, Get in Touch</h2>
					<p>Have questions about TeamPlayer<sup>HR</sup> We’re here to help. Please reach out. We’d love to hear from you.</p>
					<figure>
						<img src="assets/images/media-contact.png" class="img-fluid" />
					</figure>
					<div class="clearfix"></div>
					<ul class="social">
						<li>
							<a href="https://www.facebook.com/teamplayerhr.IM">
								<i class="flaticon-facebook"></i>
							</a>
						</li>
						<li>
							<a href="https://twitter.com/TeamPlayerHR">
								<i class="flaticon-twitter"></i>
							</a>
						</li>
						<li>
							<a href="https://www.linkedin.com/company/teamplayerhr/">
								<i class="flaticon-linkedin"></i>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-lg-6 col-md-6 mt-4">
        <form [formGroup]="ctForm" (ngSubmit)="onSubmit()">
				<div class="cnt_form">
					<div class="form-group">
						<input type="text" class="form-control" formControlName="name" placeholder="Full Name *" />
            <span class="error" *ngIf="errorCT.name.length > 0">
              {{errorCT.name}}
            </span>
					</div>
					<div class="form-group">
						<input type="text" class="form-control" formControlName="email" placeholder="Email *" />
            <span class="error" *ngIf="errorCT.email.length > 0">
              {{errorCT.email}}
            </span>
					</div>
					<div class="form-group">
						<input type="text" class="form-control" formControlName="phone" placeholder="Phone *" />
            <span class="error" *ngIf="errorCT.phone.length > 0">
              {{errorCT.phone}}
            </span>
					</div>
					<div class="form-group">
						<input type="text" class="form-control" formControlName="subject" placeholder="Subject *" />
            <span class="error" *ngIf="errorCT.subject.length > 0">
              {{errorCT.subject}}
            </span>
					</div>
					<div class="form-group">
						<textarea class="form-control" formControlName="message_text" placeholder="Your Message *"></textarea>
            <span class="error" *ngIf="errorCT.message_text.length > 0">
              {{errorCT.message_text}}
            </span>
					</div>
          <div class="formGroup" style="margin-bottom: 30px;">
            <input class="form-control" [min]="min" formControlName="selected_date" name="selected_date" [(ngModel)]="selected_date" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Contact Date *">
            <owl-date-time #dt1></owl-date-time>
            <span class="error" *ngIf="errorCT.selected_date.length > 0">
              {{errorCT.selected_date}}
            </span>
          </div>
					<div class="form-group sbmt_btn">
						<input type="submit" class="btn btn-primary" name="submit" value="Submit Message">
					</div>
				</div>
        </form>
			</div>
		</div>
	</div>
</section>

<section id="intrested">
	<div class="container">
		<div class="row">
			<div class="col-lg-9 col-md-8 align-self-center">
				<h3>Interested in finding out more? Why not see it in action...</h3>
			</div>
			<div class="col-lg-3 col-md-4 align-self-center text-right mt-4 mt-md-0">
				<a class="readmore white" data-toggle="modal" data-target="#demo_form_modal" href="javascript:void(0);">Request a demo</a>
			</div>
		</div>
	</div>
</section>
