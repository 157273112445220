<div class="row">
    <div class="col-md-12">
        <h3>Manage Questionnaire </h3>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <ul>																																					
			<li *ngFor="let item of data; let i = index">
                <a [routerLink]="['/user/survey-group',item.id]" routerLinkActive="router-link-active" >({{i+1}}) Manage {{item.name}}</a>
            </li>
        </ul>
    </div>
</div>

