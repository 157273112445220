import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Globals } from '../global';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  auth:any;
  selected_date:any= "";
  baseURL:any = "https://admin.teamplayerhr.com/";
  public min = new Date();
  errorOrg = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    organization_name: "",
    agreeTerms: "",
    agreePrivacy: "",
    user_role:"",
    no_of_employees:"",
    selected_date: ""
  };
  signupOrgForm = this.fb.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', Validators.required],
    organization_name: [''],
    phone: ['', Validators.email],
    agreeTerms: ['', Validators.required],
    agreePrivacy: ['', Validators.required],
    user_role: ['', Validators.required],
    no_of_employees: ['', Validators.required],
    selected_date: ['', Validators.required],
  });
  submitting:boolean;
  constructor(private global: Globals,private fb: FormBuilder, private api: ApiService,private toastr: ToastrService, private recaptchaV3Service: ReCaptchaV3Service,) { }

  ngOnInit(): void {
    this.fixNav();
    this.auth = this.global.getUser();
    //console.log(this.auth);
    this.baseURL = this.global.APIURL;
  }

  fixNav(){
    var nums = $('header').offset().top;
    $(window).scroll(function() {
      if ($(window).scrollTop() > nums) {
        $('header').addClass('fixednav');
      } else {
        $('header').removeClass('fixednav');
        nums = $('header').offset().top;
      }
    });
  }

  validateData(ob:any){
    this.errorOrg = {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      organization_name: "",
      agreeTerms: "",
      agreePrivacy: "",
      user_role:"",
      no_of_employees:"",
      selected_date: ""
    };
    var status = true;
    if(ob.first_name == "" || ob.first_name.length == 0)
    {
      this.errorOrg.first_name = "First Name is required.";
      status = false;
    }
    if(ob.last_name == "" || ob.last_name.length == 0)
    {
      this.errorOrg.last_name = "Last Name is required.";
      status = false;
    }
    if(ob.phone == null)
    {
      this.errorOrg.phone = "Please enter valid phone no.";
      status = false;
    }
    else if(ob.phone == "" || ob.phone.length == 0)
    {
      this.errorOrg.phone = "Phone is required.";
      status = false;
    }

    if(ob.email == "" || ob.email.length == 0)
    {
      this.errorOrg.email = "Email is required.";
      status = false;
    }
    else if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(ob.email) == false)
    {
      this.errorOrg.email = "Please enter valid email.";
      status = false;
    }

    if(ob.organization_name == "" || ob.organization_name.length == 0)
    {
      this.errorOrg.organization_name = "Organization name is required.";
      status = false;
    }
    if(ob.user_role == "" || ob.user_role.length == 0)
    {
      this.errorOrg.user_role = "Role is required.";
      status = false;
    }
    if(ob.no_of_employees == "" || ob.no_of_employees.length == 0)
    {
      this.errorOrg.no_of_employees = "No of employee is required.";
      status = false;
    }
    if(ob.selected_date == "" || ob.selected_date.length == 0)
    {
      this.errorOrg.selected_date = "Contact date is required.";
      status = false;
    }
    if(ob.agreeTerms == "" || ob.agreeTerms.length == 0)
    {
      this.errorOrg.agreeTerms = "Check Agree to Terms & Conditions.";
      status = false;
    }
    if(ob.agreePrivacy == "" || ob.agreePrivacy.length == 0)
    {
      this.errorOrg.agreePrivacy = "Check Agree to Privacy Policy.";
      status = false;
    }
    return status;
  }

  openDemoModal(){
    $("#demo_form_modal").modal('show');
  }

  onSubmit() {
    this.recaptchaV3Service.execute('request_demo')
      .subscribe((token) => console.log('recaptcha executed'));
    var validation = this.validateData(this.signupOrgForm.value);

    if(validation == true)
    {
      this.submitting = true;
      var frmData1 = this.signupOrgForm.value;
      frmData1.selected_date = this.formatDate(this.selected_date);
      this.api.demoRequest(frmData1).subscribe((data:any)=>{
        this.submitting = false;
        var res = data;
        this.toastr.success('', res.message);
        this.signupOrgForm = this.fb.group({
          first_name: ['', Validators.required],
          last_name: ['', Validators.required],
          email: ['', Validators.required],
          organization_name: [''],
          phone: [''],
          agreeTerms: ['', Validators.required],
          agreePrivacy: ['', Validators.required],
          user_role: ['', Validators.required],
          no_of_employees: ['', Validators.required],
          selected_date: ['', Validators.required],
        });

      },(res:any)=>{
        this.submitting = false;
        var temp = res.error.data;
        for (const [key, value] of Object.entries(temp)) {
          this.errorOrg[key] = value[0];
        }
        this.toastr.error(res.error.message,'Error');
      })
    }
  }

  formatDate(date:any) {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();
    var hour = d.getHours().toString();
    var minute = d.getMinutes().toString();

    if (month.length < 2)
    {
      month = '0' + month;
    }
    if (day.length < 2)
    {
      day = '0' + day;
    }
    if (hour.length < 2)
    {
      hour = '0' + hour;
    }
    if (minute.length < 2)
    {
      minute = '0' + minute;
    }

    var result = [year, month, day].join('-') +' '+ [hour, minute].join(':');;
    return result;
}

}
