import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  public user: any;
  //public APIURL: string = "https://admin.teamplayerhr.com/";
  public APIURL: string = "https://superadmin.teamplayerhr.com/";
  // public APIURL: string = "http://localhost:8000/";
  public paypalConfig:any = {
    env: 'production',
    client: {
      sandbox: 'ATm_jtzwbknJxWqa1NfmT2riE5-GdD6ehzzeC3Qd0dh7DBtHPmdZDW69O0PMPb1H6uXQlhlfhLkD8WTs',
      production: 'AUx2xJXYjPWhiAgXEaNTr-n784VszsMzy_g3aVHYhOU1KpT1Gydi3nHs4SL4Z-bzfn70DrCxptIIyNYC'
    }
  }
  titleCase(str:string) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }
  setUser(name:string,email:string,token:string,role:any,image:any) {
    name = this.titleCase(name);
    let user:object = {user_name:name,email:email,token:token,role:role,image:image};
    this.user = user;
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  setUserImage(image:any) {
    let data = localStorage.getItem('user');
    if(data != null)
    {
      this.user = JSON.parse(data);
    }
    let user:object = {user_name:this.user.user_name,email:this.user.email,token:this.user.token,role:this.user.role,image:image};
    this.user = user;

    localStorage.setItem('user', JSON.stringify(this.user));
  }
  
   setUserNameee(username:any) {
    let data = localStorage.getItem('user');
    if(data != null)
    {
      this.user = JSON.parse(data);
    }
	//console.log(username);
    let user:object = {user_name:username,email:this.user.email,token:this.user.token,role:this.user.role,image:this.user.image};
    this.user = user;

    localStorage.setItem('user', JSON.stringify(this.user));
  }

  setResultParam(group_id:any,user_id:any) {
    let param:object = {group_id:group_id,user_id:user_id};
    localStorage.setItem('result_param', JSON.stringify(param));
  }

  setTestParams(group_id:any,progress:any) {

    let param:object = {group_id:group_id,progress:progress};
    localStorage.setItem('test_param', JSON.stringify(param));
  }

  setDemoTestParams(group_id:any,progress:any) {

    let param:object = {group_id:group_id,progress:progress};
    localStorage.setItem('demo_test_param', JSON.stringify(param));
  }

  setTeamResultParam(group_id:any,user_id:any,subgroup_id:any,user_type:any, participantIds:any) {
    let param:object = {group_id:group_id,user_id:user_id,subgroup_id:subgroup_id,user_type:user_type,participant_ids:participantIds};
    localStorage.setItem('result_team_param', JSON.stringify(param));
  }
  setDemoTeamResultParam(group_id:any,user_id:any,subgroup_id:any,user_type:any,transaction_id:any) {
    let param:object = {group_id:group_id,user_id:user_id,subgroup_id:subgroup_id,user_type:user_type,transaction_id:transaction_id};
    localStorage.setItem('result_demo_team_param', JSON.stringify(param));
  }

  getUser() {
    let data = localStorage.getItem('user');
    if(data != null)
    {
      this.user = JSON.parse(data);
    }
    return this.user;
  }
  unsetUser(){
    this.user = null;
    localStorage.clear();
  }
}
