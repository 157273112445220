import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from './global';

@Injectable({
  providedIn: 'root'
})

export class DemoapiService {
  baseUrl:string = "";
  httpOptions:any
  auth:any;
  constructor(private http: HttpClient,private global: Globals) {
    this.auth = this.global.getUser();
    this.baseUrl = this.global.APIURL;
  }
  deleteInvite(param:any) {
    return this.http.post(this.baseUrl+"api/demo/delete_invite",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  invitee_list(id:any) {
    return this.http.get(this.baseUrl+"api/demo/questionnaire/invitee_list?id="+id,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  getBrainTreeToken(){
    return this.http.get(this.baseUrl+"api/user/braintree_token",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  questionnaireList() {
    return this.http.get(this.baseUrl+"api/demo/questionnaire",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  questionnaireDetail(id:any) {
    return this.http.get(this.baseUrl+"api/demo/questionnaire/detail?id="+id,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  surveyResult(param:any) {
    return this.http.post(this.baseUrl+"api/demo/survey_result",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  surveyResultTeam(param:any) {
    return this.http.post(this.baseUrl+"api/demo/survey_result_team",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  appSurveyResultTeam(param:any) {
    console.log('appSurveyResultTeam');
    let header = param.token;
    let data = {group_id:param.group_id,subgroup_id:param.subgroup_id,user_id:param.user_id,user_type:param.user_type}
    return this.http.post(this.baseUrl+"api/demo/survey_result_team",data,{
      headers: new HttpHeaders({"Authorization":header})
    });
  }

  updatePayment(param:any) {
    return this.http.post(this.baseUrl+"api/demo/updatePayment",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  postFile(fileToUpload: File) {
    console.log(fileToUpload);
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.baseUrl+"api/demo/upload",formData);
  }

  createTeam(param:any) {
    return this.http.post(this.baseUrl+"api/demo/add_subgroup",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  addUserTeam(param:any) {
    return this.http.post(this.baseUrl+"api/demo/add_user_subgroup",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  getTeams(id:any) {
    var param = {group_id:id};
    return this.http.post(this.baseUrl+"api/demo/get_subgroup",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  updateTeamUsertype(param:any) {
    return this.http.put(this.baseUrl+"api/demo/team_usertype",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  sendInvite(param:any) {
    return this.http.post(this.baseUrl+"api/demo/send_invite",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  deleteInGroup(param:any) {
    return this.http.post(this.baseUrl+"api/demo/delete_in_group",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  unDeleteInGroup(param:any) {
    return this.http.post(this.baseUrl+"api/demo/undelete_in_group",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  sendMultipleInvite(param:any) {
    return this.http.post(this.baseUrl+"api/demo/send_multiple_invite",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  joinGroup(param:any) {
    return this.http.post(this.baseUrl+"api/demo/join_group",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  getInvitation() {
    return this.http.get(this.baseUrl+"api/demo/invitation",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  sendReminder(param:any) {
    return this.http.post(this.baseUrl+"api/demo/send_reminder",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  getUserGroup() {
    return this.http.get(this.baseUrl+"api/demo/group_joined",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  getQuestion(){
    return this.http.get(this.baseUrl+"api/demo/questions",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  saveAnswer(param:any){
    return this.http.post(this.baseUrl+"api/demo/questions/save_answer",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  completeSurvey(){
    return this.http.post(this.baseUrl+"api/demo/questions/complete_survey",{},{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  setScore(param:any){
    return this.http.post(this.baseUrl+"api/demo/questions/set_score",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  createGroup(param:any) {
    return this.http.post(this.baseUrl+"api/demo/add_group",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  getPlan() {
    return this.http.get(this.baseUrl+"api/demo/plan");
  }
  getDemoMeta(page:any) {
    return this.http.get(this.baseUrl+"api/meta?page="+page);
  }

  getSubscriptionPlan() {
    return this.http.get(this.baseUrl+"api/user/app/subscription",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  getPPCPlan() {
    return this.http.get(this.baseUrl+"api/user/app/ppc_plans",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  
  getVATPrice() {
    return this.http.get(this.baseUrl+"api/user/app/vatprice",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  updateAppSubPayment(param:any) {
    return this.http.post(this.baseUrl+"api/user/updateAppSubPayment",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  updatePPCPayment(param:any) {
    return this.http.post(this.baseUrl+"api/user/updatePPCPayment",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  updateDemoPayment(param:any) {
    return this.http.post(this.baseUrl+"api/user/updateDemoPayment",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  renewAccount(param:any) {
    return this.http.post(this.baseUrl+"api/demo/renewAccount",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
}
