<section id="calculator">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 value_calculator_heading mt-5 mb-3">
				<h3 >Value Calculator</h3>
			</div>
			<div class="col-lg-12 mt-4">


					<div class="row">
						<div class="col-lg-6 inputSide inputContainer">
							<div class="defaultInput" id="divDefault">
								<div class="formGroup">
									<div class="lbs">
										<label for="">Number of Employees</label> <h3 id="noEmp">{{no_of_empoyees}}</h3>
									</div>

									<ng5-slider [(value)]="no_of_empoyees" (valueChange)="PerformCalculation()" [options]="noOfEmployeesOptions"></ng5-slider>
									<div class="showPattern">

										<span class="pattrn"></span>

									</div>
								</div>
								<div class="formGroup">
									<div class="lbs">
										<label for="">Typical team size</label> <h3 id="tmsiz">{{typical_team_size}}</h3>
									</div>
									<ng5-slider [(value)]="typical_team_size" (valueChange)="PerformCalculation()" [options]="typical_team_sizeOptions"></ng5-slider>
									<div class="showPattern">

										<span class="pattrn"></span>

									</div>
								</div>
								<div class="formGroup" style="margin-bottom: 30px;" id="avgSalary"s>
									<label for="">Average Salary per employee</label>
									<button value="20000" [ngClass]="{'selected':avg_salary_per_employee == 20000}" (click)="setAverageSalary(20000)">£ 20,000</button>
									<button [ngClass]="{'selected':avg_salary_per_employee == 24000}" (click)="setAverageSalary(24000)" value="24000">£ 24,000</button>
									<button value="28000" [ngClass]="{'selected':avg_salary_per_employee == 28000}" (click)="setAverageSalary(28000)">£ 28,000</button>
									<input type="text" name="averageSalary" (change)="employeSalaryChange()" [(ngModel)]="tmp_avg_salary_per_employee"  id="averageSalary" placeholder="£ Enter yourself">
									<input type="hidden" id="avgSalaryHid" [(ngModel)]="avg_salary_per_employee" value="24000" name="avgSalaryhid">
								</div>
								<div class="formGroup" style="margin-bottom: 30px;" id="avgMngSalary">
									<label for="">Average Management Salary per year</label>
                  <button [ngClass]="{'selected':avg_mgment_salary_per_year == 40000}" value="40000" (click)="setAverageManagementSalary(40000)">£ 40,000</button>
                  <button [ngClass]="{'selected':avg_mgment_salary_per_year == 50000}" value="50000" (click)="setAverageManagementSalary(50000)">£ 50,000</button>
                  <button [ngClass]="{'selected':avg_mgment_salary_per_year == 60000}" value="60000" (click)="setAverageManagementSalary(60000)">£ 60,000</button>
                  <input type="text" (change)="managementSalaryChange()" name="averageManagerSalary" [(ngModel)]="tmp_avg_mgment_salary_per_year" id="averageSalary2" placeholder="£ Enter yourself">
									<input type="hidden" [(ngModel)]="avg_mgment_salary_per_year"  id="avgMgrSalaryHid" value="50000" name="avgMgrSalaryhid">

								</div>

								<div class="advncFields" >
									<div class="formGroup">
										<div class="lbs">
											<label for="">Percentage Team Assignments where TPHR used</label> <span>%</span><h3 id="perasst">{{percentage_assignment}}</h3>
										</div>
										<ng5-slider [(value)]="percentage_assignment" (valueChange)="PerformCalculation()" [options]="percentage_assignmentOptions"></ng5-slider>
										<div class="showPattern">

											<span class="pattrn"></span>

										</div>
									</div>

									<div class="formGroup" style="margin-bottom: 20px; clear: both; overflow: hidden;">
										<div class="halfWidth">
											<label for="">Team assignments frequency (Yearly)</label>
											<input type="text" value="2" (change)="PerformCalculation()" [(ngModel)]="team_assignment_frequency_yearly" name="frequency_team_assigment" id="frequency_team_assigment">
										</div>
										<div class="halfWidth">
											<label for="">Fully Loaded Cost factor</label>
											<input type="text" value="1.5" (change)="PerformCalculation()" [(ngModel)]="fullly_loaded_cost_factor" name="loaded_cost_factor" id="loaded_cost_factor">
										</div>
									</div>

									<div class="formGroup">
										<div class="lbs">
											<label for="">Recruitment cost as % of starting salary</label> <span>%</span><h3 id="rectry">{{recrutmnt_cost_starting_salary}}</h3>
										</div>
										<ng5-slider [(value)]="recrutmnt_cost_starting_salary" (valueChange)="PerformCalculation()" [options]="recrutmnt_cost_starting_salaryOptions"></ng5-slider>
										<div class="showPattern">

											<span class="pattrn"></span>

										</div>
									</div>

									<div class="formGroup">
										<div class="lbs">
											<label for="">Number of Recruits per year (growth or churn)</label> <span>%</span><h3 id="rectnumbers">{{no_recruiters_per_year}}</h3>
										</div>
										<ng5-slider [(value)]="no_recruiters_per_year" (valueChange)="PerformCalculation()" [options]="no_recruiters_per_yearOptions"></ng5-slider>
										<div class="showPattern">

											<span class="pattrn"></span>

										</div>
									</div>

									<div class="formGroup">
										<div class="lbs">
											<label for="">Percentage of Management time on HR issues</label> <span>%</span><h3 id="issuemangepct">{{percntge_on_mangment_time_hr_issues}}</h3>
										</div>
										<ng5-slider [(value)]="percntge_on_mangment_time_hr_issues" (valueChange)="PerformCalculation()" [options]="percntge_on_mangment_time_hr_issuesOptions"></ng5-slider>
										<div class="showPattern">

											<span class="pattrn"></span>

										</div>
									</div>

									<div class="formGroup" style="margin-bottom: 30px;">
										<label for="">Typical Pay in Liieu of Notice for Leavers (months)</label>
										<input style="width: 100%;" type="text" (change)="PerformCalculation()" [(ngModel)]="lieu_notice_of_leaves" value="1" name="lieue_notice" id="lieue_notice">
									</div>

								</div>

							</div>

							<div class="sendEmail" id="divValueCalculatorEmail">
                <form method="post" (ngSubmit)="sendValueEmail()">
                  <div class="formGroup" style="margin-bottom: 30px;">
                    <label for="">Client Name</label>
                    <input type="text" [(ngModel)]="emailData.client_name" name="client_name" placeholder="Enter Name" required/>
                  </div>
                  <div class="formGroup" style="margin-bottom: 30px;">
                    <label for="">Client Company</label>
                    <input type="text" [(ngModel)]="emailData.client_company" name="client_company" placeholder="Client Company" />
                  </div>
                  <div class="formGroup" style="margin-bottom: 30px;">
                    <label for="">Email Address</label>
                    <input type="email" [(ngModel)]="emailData.client_email" name="client_email" placeholder="Email Address" required/>
                  </div>
                  <div class="formGroup" style="margin-bottom: 30px;">
                    <label for="">Phone number</label>
                    <input type="text" [(ngModel)]="emailData.client_phone" name="client_phone" placeholder="Phone number" required/>
                  </div>
                  <input type="submit" class="btn primary">
                </form>
							</div>

							<div class="buttons_panel">
								<a id="advance" class="btn primary">Advance</a>
								<!-- <a id="proceed"  class="btn seconday">Proceed</a> -->
							</div>

						</div>
						<div class="col-lg-6 results" id="valueResultDiv">
							<div class="calculator_generate">
							<div class="labels" style="margin-top: 0;">
                <p></p>
                <p style="text-align: center; margin-top: 0;">
									Without
									<span style="display: block;">
										TeamPlayer
										<sup>HR</sup>
									</span>
								</p>
								<p style="text-align: center; margin-top: 0;">
									<b>With
									<span style="display: block;">TeamPlayer
										<sup>HR</sup>
									</span></b>
								</p>

							</div>

							<div class="reslt">
                <h4>Projected HR Cost</h4>
                <p id="projected_without_tphr">3,860,400</p>
								<input type="hidden" id="projected_without_tphrHid" value="3860400" name="projected_HR_cost_without_tphr">
								<p id="projected_with_tphr">2,996,280</p>
								<input type="hidden" id="projected_with_tphrHid" value="2996280" name="projected_HR_cost_with_tphr">
              </div>

							<div class="reslt">
                <h4>Projected TeamPlayer<sup>HR</sup>  Cost per year</h4>
                <p>&nbsp;</p>
								<p id="idTPHRCostPerYear">24,000</p>
								<input type="hidden" id="tphr_cost_per_year" value="24000" name="tphr_cost_per_year">

							</div>
							<div class="reslt">
                <h4>Projected annual savings from TPHR</h4>
                <p>&nbsp;</p>
								<p id="idAnualSavingsTPHR">864,120</p>
								<input type="hidden" id="tphr_anual_savings" value="24000" name="tphr_anual_savings">

							</div>

							<div class="graphTop">
								<span>
								<input type="checkbox" [(ngModel)]="agile_check" (change)="PerformCalculation()" id="switch" name="agile" /><label class="tottleswi" for="switch">Agile</label>
                <span *ngIf="agile_check == true">Agile</span>
                <span *ngIf="agile_check == false">Non-Agile</span>
								</span>
								<h4>Projected HR costs</h4>
							</div>

							<div class="graphWrapper">

								<svg class="graph" viewBox="0 0 555 200">
									<g>
										<path id="svg_1" d="m10,190c1,0 313,24 532,-180" stroke-width="3.5" stroke="#008cb2" fill="none"/>
										<path id="svg_2" d="m10,190c1,0 313,24 532,-190" stroke-width="3.5" stroke="#d93f36" fill="none"/>
									</g>
								</svg>
								<span id="projectedWITHTPHR">2996K</span>
								<span id="projectedWITHOUTTPHR">3996K</span>

							</div>
							</div>
						</div>
					</div>


			</div>
		</div>
	</div>
</section>
