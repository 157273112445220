import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, ViewContainerRef } from '@angular/core';
import { Globals } from '../../global';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-purchase-questionnaire',
  templateUrl: './purchase-questionnaire.component.html',
  styleUrls: ['./purchase-questionnaire.component.css']
})
export class PurchaseQuestionnaireComponent implements OnInit {
  showButton:boolean = false;
  FullQuestionnairedata:any;
  paymentDetail:any;
  paypal:any;
  plan:any;
  data:any = [{"id":1,"name":"1 Survey","number_survay":"1","amount":"240.00","created_at":"2020-09-16T18:57:40.000000Z","created_by":"1","updated_at":"2020-09-16T18:57:40.000000Z","updated_by":"1"},{"id":2,"name":"5 Survey","number_survay":"5","amount":"1200.00","created_at":"2020-09-16T18:57:40.000000Z","created_by":"1","updated_at":"2020-09-16T18:57:40.000000Z","updated_by":"1"},{"id":3,"name":"15 Survey","number_survay":"15","amount":"3600.00","created_at":"2020-09-16T18:57:40.000000Z","created_by":"1","updated_at":"2020-09-16T18:57:40.000000Z","updated_by":"1"},{"id":4,"name":"25 Survey","number_survay":"25","amount":"6000.00","created_at":"2020-09-16T18:57:40.000000Z","created_by":"1","updated_at":"2020-09-16T18:57:40.000000Z","updated_by":"1"}];
  constructor(private global: Globals,private route:ActivatedRoute,private router: Router,private api: ApiService,private toastr: ToastrService) {

   }

  ngOnInit(): void {
	  this.api.getMeta('full-questionnaire').subscribe((data: any[])=>{
      var res:any = data;
      this.FullQuestionnairedata = res.data.content;
      
    });
	
    this.api.getPlans().subscribe((data: any[])=>{
      let res:any = data;
      this.data = res.data;
    })
  }

  renderButton(plan:any)
  {
    this.showButton = false;
    setTimeout(()=>{
      this.plan = plan;
      this.showButton = true;
    }, 10);
  }

  updatePayment(id:any,res:any){
    let param = {id:id,data:JSON.stringify(res)};

    this.api.updatePayment(param).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success(res.message);
      $("#mainContainer").hide();
      $("#paymentSuccess").show();
    })
  }

}
