import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../global';
import { DemoapiService } from '../../demoapi.service';
import { ToastrService } from 'ngx-toastr';
import jspdf, { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { NgxSpinnerService } from "ngx-spinner";
import { timer } from 'rxjs';
const source = timer(1000, 2000);

@Component({
  selector: 'app-demo-survey-result-team',
  templateUrl: './demo-survey-result-team.component.html',
  styleUrls: ['./demo-survey-result-team.component.css']
})
export class DemoSurveyResultTeamComponent implements OnInit {

  data:any;
  result:any;
  pdf:jspdf;
  sectionPageCount:number;
  sectionUserPageCount:number;
  showIm:any = 'true';
  constructor(private global: Globals,private route:ActivatedRoute, private router: Router,private api: DemoapiService,private toastr: ToastrService,private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('result_demo_team_param'));
    console.log(this.data);
    if(this.data.group_id == undefined || this.data.user_id == undefined || this.data.subgroup_id == undefined)
    {
      this.router.navigate(['user/demo']);
    }
    else
    {
      this.api.surveyResultTeam(this.data).subscribe((data: any[])=>{
        let res:any = data;
        this.result = res.data;
      });
    }
  }

  currentDate(){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var todayDate = yyyy + '-' + mm + '-' + dd;

    return todayDate;
  }

  captureScreen()
  {
    this.spinner.show();
    this.sectionPageCount = 0;
    this.sectionUserPageCount = 0;
    this.pdf = new jsPDF();

    this.addCoverPage().then(
      res => this.addParticipentPage().then(
        res2 => this.addSectionUserScorePage().then(
          res3 =>this.addSectionScorePage().then(
            ()=>{
                  console.log('save');
                  this.pdf.save('TeamPlayerHR-Report.pdf');
                  this.spinner.hide();
                }
          )
        )
      )
    );
  }

  captureSummary()
  {
    this.spinner.show();
    this.sectionPageCount = 0;
    this.sectionUserPageCount = 0;
    this.pdf = new jsPDF();

    this.addCoverPage().then(
      res => this.addParticipentPage().then(
        ()=>{
          console.log('save');
          this.pdf.save('TeamPlayerHR-Summary.pdf');
          this.spinner.hide();
        }
      )
    );
  }

  addCoverPage(){
    return new Promise((resolve, reject) => {
      var data = document.getElementById('coverPage');
      html2canvas(data).then(canvas => {
          let imgWidth = 0;
          let imgHeight = 0;
          let position = 0;
          if(window.innerWidth > window.innerHeight)
          {
            imgWidth = this.pdf.internal.pageSize.getWidth();
            imgHeight = canvas.height * imgWidth / canvas.width;
          }
          else
          {
            imgHeight = this.pdf.internal.pageSize.getHeight();
            imgWidth = canvas.width * imgHeight / canvas.height;
            position = 15;
          }
        console.log("addCoverPage");
        const contentDataURL = canvas.toDataURL('image/png');
        var width = this.pdf.internal.pageSize.getWidth();
        this.pdf.addImage(contentDataURL, 'PNG', position,0, imgWidth, imgHeight);
        setTimeout(()=>{
          resolve(true);
        },10);
      });
    });

  }

  addParticipentPage(){
    return new Promise((resolve, reject) => {
        var data2 = document.getElementById('contentPage');
        html2canvas(data2).then(canvas => {

          let imgWidth = 0;
          let imgHeight = 0;
          let position = 0;
          if(window.innerWidth > window.innerHeight)
          {
            imgWidth = this.pdf.internal.pageSize.getWidth();
            imgHeight = canvas.height * imgWidth / canvas.width;
          }
          else
          {
            imgHeight = this.pdf.internal.pageSize.getHeight();
            imgWidth = canvas.width * imgHeight / canvas.height;
            position = 15;
          }

          const contentDataURL2 = canvas.toDataURL('image/png');
          var width = this.pdf.internal.pageSize.getWidth();
          this.pdf.addPage();
          this.pdf.addImage(contentDataURL2, 'PNG', 0, 0, imgWidth, imgHeight);
          setTimeout(()=>{
            resolve(true);
          },10);
        });

    });

  }

  addSectionScorePage()
  {
    return new Promise((resolve, reject) => {
        for(var i = 0; i < this.result.user_list.length; i++)
        {
          var dataTmp = document.getElementById('indivdualPage'+i);
          html2canvas(dataTmp).then(canvas => {
            let imgWidth = 0;
            let imgHeight = 0;
            let position = 0;
            if(window.innerWidth > window.innerHeight)
            {
              imgWidth = this.pdf.internal.pageSize.getWidth();
              imgHeight = canvas.height * imgWidth / canvas.width;
            }
            else
            {
              imgHeight = this.pdf.internal.pageSize.getHeight();
              imgWidth = canvas.width * imgHeight / canvas.height;
              position = 15;
            }
            const contentDataURL3 = canvas.toDataURL('image/png');
            var width = this.pdf.internal.pageSize.getWidth();
            this.pdf.addPage();
            this.pdf.addImage(contentDataURL3, 'PNG', 0, 0, imgWidth, imgHeight);
            this.sectionPageCount++;
            //debugger;
            if(this.sectionPageCount == this.result.user_list.length)
            {
              setTimeout(()=>{
                if(resolve != null)
                resolve(true);
              },10);
            }
          });

        }
    });
  }

  addSectionUserScorePage()
  {
    return new Promise((resolve, reject) => {
        for(var i = 0; i < this.result.section_list.length; i++)
        {
          var dataTmp = document.getElementById('sectionPage'+i);
          html2canvas(dataTmp).then(canvas => {
            let imgWidth = 0;
            let imgHeight = 0;
            let position = 0;
            if(window.innerWidth > window.innerHeight)
            {
              imgWidth = this.pdf.internal.pageSize.getWidth();
              imgHeight = canvas.height * imgWidth / canvas.width;
            }
            else
            {
              imgHeight = this.pdf.internal.pageSize.getHeight();
              imgWidth = canvas.width * imgHeight / canvas.height;
              position = 15;
            }
            console.log("addSectionUserScorePage");
            const contentDataURL3 = canvas.toDataURL('image/png');
            var width = this.pdf.internal.pageSize.getWidth();
            this.pdf.addPage();
            this.pdf.addImage(contentDataURL3, 'PNG', 0, 0, imgWidth, imgHeight);
            this.sectionUserPageCount++;
            if(this.sectionUserPageCount == this.result.section_list.length)
            {
              setTimeout(()=>{
                resolve(true);
              },10);
            }
          });

        }

    });
  }

}
