import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormArray } from '@angular/forms';
import { Globals } from '../../global';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  auth:any;
  fileToUpload: File = null;
  profile_image:any;
  occupationList:any =[];
  appQPurchaseList:any =[];
  appSubPurchaseList:any =[];
  ppcPurchaseList:any =[];
  fullQPurchaseList:any =[];
  subscriptionPurchaseList:any =[];
  renewalPurchaseList:any =[];
  baseURL:any = "https://admin.teamplayerhr.com/";
  userProfile:any = {first_name:"",last_name:"",phone:"",occupation:""};
  profileImageForm = this.fb.group({
    profile_image: ['']
  });

  errorOrg = {
    first_name: "",
    last_name: "",
    phone: "",
    occupation: ""
  };

  userData:any = {"id":"","user_id":"","first_name":"","last_name":"","title":"","cv":'',"organization_name":'',"phone":"","sector":'',"address_line_1":"","address_line_2":"","city":'',"state":'',"country":'',"zip":"","email":""};
  constructor(private global: Globals,private api: ApiService,private toastr: ToastrService,private fb: FormBuilder) {
    this.auth = this.global.getUser();
    this.baseURL = this.global.APIURL;
    this.api.getProfile().subscribe((data: any[])=>{
      let res:any = data;
      this.userData = res.data;
    });
  }

  ngOnInit(): void {
    this.getDemoQuestionnairePurchase();
    this.getAppSubscriptionPurchase();
    this.getPPCPurchase();
    this.getFullQuestionnairePurchase();
    this.getSubscriptionPurchase();
    this.getRenewalPurchase();
	
  }

  getRenewalPurchase(){
    this.api.getRenewalPurchase().subscribe((data:any)=>{
      var res = data;
      this.renewalPurchaseList = res.data;
    })
  }
  getPPCPurchase(){
    this.api.getPPCPurchase().subscribe((data:any)=>{
      var res = data;
      this.ppcPurchaseList = res.data;
    })
  }
  getDemoQuestionnairePurchase(){
    this.api.getDemoQuestionnairePurchase().subscribe((data:any)=>{
      var res = data;
      this.appQPurchaseList = res.data;
    })
  }
  getFullQuestionnairePurchase(){
    this.api.getFullQuestionnairePurchase().subscribe((data:any)=>{
      var res = data;
      this.fullQPurchaseList = res.data;
    })
  }
  getSubscriptionPurchase(){
    this.api.getSubscriptionPurchase().subscribe((data:any)=>{
      var res = data;
      this.subscriptionPurchaseList = res.data;
    })
  }
  getAppSubscriptionPurchase(){
    this.api.getAppSubscriptionPurchase().subscribe((data:any)=>{
      var res = data;
      this.appSubPurchaseList = res.data;
    })
  }

  validateData(ob:any){
    this.errorOrg = {
      first_name: "",
      last_name: "",
      phone: "",
      occupation: ""
    };
    var status = true;
    if(ob.first_name == "" || ob.first_name.length == 0)
    {
      this.errorOrg.first_name = "First Name is required.";
      status = false;
    }
    if(ob.last_name == "" || ob.last_name.length == 0)
    {
      this.errorOrg.last_name = "Last Name is required.";
      status = false;
    }
    if(ob.phone == null)
    {
      this.errorOrg.phone = "Please enter valid phone no.";
      status = false;
    }
    else if(ob.phone == "" || ob.phone.length == 0)
    {
      this.errorOrg.phone = "Phone is required.";
      status = false;
    }

    if(ob.occupation == "" || ob.occupation.length == 0)
    {
      this.errorOrg.occupation = "Please Select Occupation.";
      status = false;
    }

    return status;
  }

  editProfile(){
    $("#updateProfileModal").modal('show');
    this.userProfile.first_name = this.capitalizeFirstLetter(this.userData.first_name);
    this.userProfile.last_name = this.capitalizeFirstLetter(this.userData.last_name);
    this.userProfile.phone = this.userData.phone;
    this.userProfile.occupation = this.userData.occupation;
    this.getOccupationList();
  }
  getOccupationList(){
    this.api.getOccupation().subscribe((data:any)=>{
      var res = data;
      this.occupationList = res.data;
    })
  }
  saveProfile(){
    var validation = this.validateData(this.userProfile);
    if(validation == true)
    {
      this.api.updateProfile(this.userProfile).subscribe((data:any)=>{
		  
		    this.global.setUserNameee(data.data.name);
          var res = data;
		 // console.log(this.auth.token); 
          this.toastr.success('Success', res.message);
		
          window.location.reload();
        },(res:any)=>{
          if(res.error.data != undefined)
          {
            var temp = res.error.data;
            for (const [key, value] of Object.entries(temp)) {
              //this.error[key] = value[0];
            }
          }
          this.toastr.error(res.error.message,'Error');
        })
    }


  }

  handleFileInput(files: FileList) {
    if(confirm("Please confirm if want to update profile picture..")){
      this.fileToUpload = files.item(0);
      this.api.profileImage(this.fileToUpload).subscribe((data:any)=>{

        this.userData.image = data.data.filename;
        this.global.setUserImage(data.data.filename);
        window.location.reload();
      },(res:any)=>{

        var temp = res.error.data;
        for (const [key, value] of Object.entries(temp)) {
          this.errorOrg[key] = value[0];
        }
        this.toastr.error(res.error.data.file[0],'Error');
      });
    }
  }

  capitalizeFirstLetter(str:string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

}
