<div class="row" *ngIf="result != undefined">
    <div class="col-md-12">
        <h3>Questionnaire Results for {{result.user.first_name}} {{result.user.last_name}}</h3>
    </div>
</div>

<div class="row" *ngIf="result != undefined">
    <div class="col-md-12">
        <div class="card" style="padding: 10px;" >
            <table class="table table-dark">
                <tbody>
                    <tr>
                        <th>Email</th>
                        <td>{{result.user.email}}</td>
                    </tr>
                    <tr>
                        <th>Title</th>
                        <td>{{result.user.title}}</td>
                    </tr>
                    <tr>
                        <th>Phone</th>
                        <td>{{result.user.phone}}</td>
                    </tr>
                    <tr>
                        <th valign="top">Address</th>
                        <td>
                            {{result.user.address_line_1}}<br>
                            <br>
                            {{result.user.address_line_2}}<br>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card" style="padding: 10px;" >
            <table class="table table-dark">
                <tbody>
                    <tr>
                        <th>Name</th>
                        <th>
                            <div style="float: left">Significant<br>Disagreement</div>
                            <div style="float: right">Significant<br>Agreement</div>
                        </th>                        
                        <th>Score</th>                        
                    </tr>                
                    <tr *ngFor="let item of result.user_list">
                        <td width="200">{{item.name}}</td>
                        <td width="300">
                            <div style="width:300px; background-image: url(assets/images/score.jpg); margin: 0px;">
                                <img src="assets/images/dot.gif" style="position:relative;" [style.left]="(item.score * 3)+'px'">    
                            </div>
                        </td>                        
                        <td>{{item.score}}</td>                        
                    </tr>                                                                   
                </tbody>
            </table>
        </div>
        <div class="card" style="padding: 10px;">
        <h3>Significant Agreement</h3>
		<p>
			Significant means that you and the candidate are showing a systematic similarity in  the way
			you interpret and respond to questions.
        </p>    
	    <h3>Significant Disagreement</h3>
		<p>
			Significant means that you and the candidate are showing a systematic dissimilarity in  the way
			you interpret and respond to questions.			
        </p>
        <h3>Break down of scores by section</h3>
        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th>Name</th>
                    <th>Section</th>
                    <th>Score</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of result.section_list">
                    <td>{{item.name}}</td>
                    <td>{{item.section_name}}</td>
                    <td>{{item.score}}</td>
                </tr>
            </tbody>
        </table>

        </div>
    </div>
</div>
