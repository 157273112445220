
<section id="slider">
	<div id="main_slider" class="carousel slide">
		<div class="container">
			<div class="carousel-inner" *ngIf="sliderData.length > 0" role="listbox">
				<div class="carousel-item" [ngClass]="{active: i == 0}" *ngFor="let item of sliderData;let i = index;">
					<div class="row">
						<div class="col-md-6 col-sm-12 align-self-center">
							<div class="carousel-caption">
								<ul class="bull_points">
									<li>Compatible Colleagues Perform Best</li>
									<li>TeamPlayer<sup>HR</sup> is all about optimal performance </li>
									<li>Create Teams That Perform</li>
								</ul>
								<div class="slider_btns">
									<a href="/signup" class="singup">{{item.btn_text}}</a>
									<!-- <a data-fancybox data-type="iframe" href="{{item.video_url}}" class="vid_btn" data-animation="animated bounceInUp">{{item.video_text}}</a> -->
                  <a class="vid_btn" data-fancybox  href="{{contents.section4.video_url}}">{{item.video_text}}</a>
								</div>
							</div>
						</div>
						<div class="col-md-6 align-self-center d-none d-sm-block carousel-img">
							<img src="{{item.feature_image}}" class="img1 img-fluid" data-animation="animated flipInY" />
						</div>
					</div>
				</div><!-- /.item -->
			</div>
			<div class="carousel-inner" *ngIf="sliderData.length == 0" role="listbox">
				<div class="carousel-item active">
					<div class="row">
						<div class="col-md-6 col-sm-12 align-self-center">
							<div class="carousel-caption">
							
							    <ul class="bull_points">
									<li>Compatible Colleagues Perform Best</li>
									<li>TeamPlayer<sup>HR</sup> is all about optimal performance </li>
									<li>Create Teams That Perform</li>
								</ul>
								
								<div class="slider_btns">
									<a href="/signup" class="singup">Sign Up</a>
									<a href="javascript:void(0);" data-toggle="modal" data-target="#videoModal1" data-theVideo="https://www.youtube.com/embed/kZVzD7jb1Hg" class="vid_btn">How We Work</a>
								</div>
							</div>
						</div>
						<div class="col-md-6 align-self-center d-none d-sm-block carousel-img">
							<img src="assets/images/slide02.png" class="img1 img-fluid" data-animation="animated flipInY" />
						</div>
					</div>
				</div><!-- /.item -->
			</div>
		</div>
		<a class="carousel-control-prev" href="javascript:;" data-target="#main_slider" data-slide="prev">
			<span class="flaticon-left-arrow"></span>
		</a>
		<a class="carousel-control-next" href="javascript:;" data-target="#main_slider" data-slide="next">
			<span class="flaticon-right-arrow-angle"></span>
		</a>
	</div>
</section>

<section id="how_it_work">
	<div class="container upr text-center">
		<h2 [innerHTML]="contents.section1.title">How Teamplayer<sup>HR</sup> Works For You</h2>
		<div class="row">
			<div class="col-lg-4 col-md-12 col-sm-12">
				<div class="hiw_box position-relative">
					<div class="icon position-relative">
						<i class="{{contents.section1.items[0].icon}}"></i>
						<span>01</span>
					</div>
					<h3 [innerHTML]="contents.section1.items[0].title">Invite Participants</h3>
					<p [innerHTML]="contents.section1.items[0].detail">Invite the relevant individuals and participants to each complete the 15 minute online confidential questionnaire.</p>
				</div>
			</div>
			<div class="col-lg-4 col-md-12 col-sm-12">
				<div class="hiw_box position-relative">
					<div class="icon position-relative">
						<i class="{{contents.section1.items[1].icon}}"></i>
						<span>02</span>
					</div>
					<h3 [innerHTML]="contents.section1.items[1].title">Manage Processs</h3>
					<p [innerHTML]="contents.section1.items[1].detail">Managing the process is easy. A nominated administrator can simply login online and track the progress of all participants and compare compatibility intrinsic matrix result.</p>
				</div>
			</div>
			<div class="col-lg-4 col-md-12 col-sm-12">
				<div class="hiw_box position-relative">
					<div class="icon position-relative">
						<i class="{{contents.section1.items[2].icon}}"></i>
						<span>03</span>
					</div>
					<h3 [innerHTML]="contents.section1.items[2].title">Select Participants</h3>
					<p [innerHTML]="contents.section1.items[2].detail">Once all the participants have completed their questionnaires, the administrator can retrieve instant reports illustrating the overall degree of compatibility between selected participants one to one, one to many or many to one.</p>
				</div>
			</div>
		</div>
	</div>
</section>


<section id="benefits">
	<div class="container">
		<div class="subhdng " [innerHTML]="contents.section2.title">Why wouldn't you want to save money, time and get the right cultural fit</div>
		<h2 class="mb-4 " [innerHTML]="contents.section2.heading">What are the benefits !</h2>
		<div class="row">
			<div class="col-lg-7 col-md-12 col-sm-12 benefits_points align-self-center order-2 order-md-1">
				<h5 [innerHTML]="contents.section2.subheading">How?</h5>
        <div class="benefits_points_list">
          <div class="points">
            <h4>Employee Productivity</h4>
            <span>Decrease the 20% to 30% lost productivity that companies suffer due to unhappy and poorly engaged employees</span>
          </div>
          <div class="points">
            <h4>Team Assignments</h4>
            <span>Savings of up to 20% when better aligned teams are operating on agile initiatives</span>
          </div>
          <div class="points">
            <h4>Management Focus</h4>
            <span>Management Focus - A decrease of up to 20% of manager time focused on HR issues</span>
          </div>
          <div class="points">
            <h4>Staff Hiring</h4>
            <span>Building Teams - An increase of up to 20% in employee productivity</span>
            <span>Augmenting Teams - Up to 30% reduction in the number and cost of bad hires</span>
          </div>
        </div>
				<!-- <ul >
					<li *ngFor="let item of contents.section2.items">{{item}}</li>
				</ul> -->
			</div>
			<div class="col-lg-5 col-md-12 col-sm-12 align-self-center text-left order-1 order-md-2 mb-4 mb-lg-0">
				<img src="{{baseUrl+'storage/app/public/'+contents.section2.image}}" class="img-fluid" />
			</div>
		</div>
	</div>
</section>




<section id="video_cont">
	<div class="container text-center">
		<h2 [innerHTML]="contents.section3.subheading">Drives high performance team building for employers</h2>
		<div class="btns">
			<a class="play_video readmore" data-fancybox href="{{contents.section4.video_url}}" data-animation="animated bounceInUp"><i class="flaticon-play"></i> show video</a>
			<a href="javascript:void(0);" class="readmore white" type="button" (click)="openDemoModal()">request a demo</a>
		</div>
	</div>
</section>

<section id="calculator">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 col-md-12">
				<div class="subhdng" [innerHTML]="contents.section5.title">Our specialities</div>
				<h2 class="mb-4" [innerHTML]="contents.section5.heading">TeamPlayer<sup>HR</sup> Value Calculator introduction</h2>
				<div [innerHTML]="contents.section5.content1"> Companies suffer a productivity loss of between 20% to 30% due to unhappy and poorly engaged employees and cases where up to 31% of people quit a job within the first six months of employment or fail to last beyond their probationary period.</div>
			</div>
			<div class="col-lg-6 col-md-12 cal_points" [innerHTML]="contents.section5.content2">
				<p>The value delivered by TeamPlayer<sup>HR</sup> extends across four main areas:</p>
				<ul class="two_part">
					<li>Employee Productivity</li>
					<li>Team Assignments</li>
					<li>Management Focus</li>
					<li>Staff Hiring</li>
				</ul>
				<p><strong>To see how TeamPlayer<sup>HR</sup> could benefit your organisation, please use the Value Calculator.</strong></p>
				<ul class="single_line mb-0">
					<li><strong>Building Teams</strong> - <span>An increase of up to 20% in employee productivity</span>
					</li>
					<li><strong>Building Teams</strong> - <span>A decrease of up to 20% of manager time focused on HR issues</span>
					</li>
					<li><strong>Agile Teams</strong> - <span>Savings of up to 20% when better aligned teams are operating on agile initiatives</span>
					</li>
					<li><strong>Augmenting Teams</strong> - <span>Up to 30% reduction in the number and cost of bad hires</span>
					</li>
				</ul>
        <div class="puzzle_img">
          <img src="assets/images/puzzle.png" data-animation="animated flipInY" />
        </div>
			</div>
			<div class="col-lg-12 value_calculator_heading mt-5 mb-3">
				<h3 [innerHTML]="contents.section6.title">Value Calculator</h3>
			</div>
			<div class="col-lg-12 mt-4">


					<div class="row">
						<div class="col-lg-6 inputSide inputContainer">
							<div class="defaultInput" id="divDefault">
								<div class="formGroup">
									<div class="lbs">
										<label for="">Number of Employees</label> <h3 id="noEmp">{{no_of_empoyees}}</h3>
									</div>

									<ng5-slider [(value)]="no_of_empoyees" (valueChange)="PerformCalculation()" [options]="noOfEmployeesOptions"></ng5-slider>
									<div class="showPattern">

										<span class="pattrn"></span>

									</div>
								</div>
								<div class="formGroup">
									<div class="lbs">
										<label for="">Typical team size</label> <h3 id="tmsiz">{{typical_team_size}}</h3>
									</div>
									<ng5-slider [(value)]="typical_team_size" (valueChange)="PerformCalculation()" [options]="typical_team_sizeOptions"></ng5-slider>
									<div class="showPattern">

										<span class="pattrn"></span>

									</div>
								</div>
								<div class="formGroup" style="margin-bottom: 30px;" id="avgSalary"s>
									<label for="">Average Salary per employee (£, $)</label>
									<button value="20000" [ngClass]="{'selected':avg_salary_per_employee == 20000}" (click)="setAverageSalary(20000)">20,000</button>
									<button [ngClass]="{'selected':avg_salary_per_employee == 24000}" (click)="setAverageSalary(24000)" value="24000">24,000</button>
									<button value="28000" [ngClass]="{'selected':avg_salary_per_employee == 28000}" (click)="setAverageSalary(28000)">28,000</button>
									<input type="text" name="averageSalary" (change)="employeSalaryChange()" [(ngModel)]="tmp_avg_salary_per_employee"  id="averageSalary" placeholder="Enter yourself">
									<input type="hidden" id="avgSalaryHid" [(ngModel)]="avg_salary_per_employee" value="24000" name="avgSalaryhid">
								</div>
								<div class="formGroup" style="margin-bottom: 30px;" id="avgMngSalary">
									<label for="">Average Management Salary per year (£, $)</label>
                  <button [ngClass]="{'selected':avg_mgment_salary_per_year == 40000}" value="40000" (click)="setAverageManagementSalary(40000)">40,000</button>
                  <button [ngClass]="{'selected':avg_mgment_salary_per_year == 50000}" value="50000" (click)="setAverageManagementSalary(50000)"> 50,000</button>
                  <button [ngClass]="{'selected':avg_mgment_salary_per_year == 60000}" value="60000" (click)="setAverageManagementSalary(60000)"> 60,000</button>
                  <input type="text" (change)="managementSalaryChange()" name="averageManagerSalary" [(ngModel)]="tmp_avg_mgment_salary_per_year" id="averageSalary2" placeholder="Enter yourself">
									<input type="hidden" [(ngModel)]="avg_mgment_salary_per_year"  id="avgMgrSalaryHid" value="50000" name="avgMgrSalaryhid">

								</div>

								<div class="advncFields" >
									<div class="formGroup">
										<div class="lbs">
											<label for="">Percentage Team Assignments where TPHR used</label> <span>%</span><h3 id="perasst">{{percentage_assignment}}</h3>
										</div>
										<ng5-slider [(value)]="percentage_assignment" (valueChange)="PerformCalculation()" [options]="percentage_assignmentOptions"></ng5-slider>
										<div class="showPattern">

											<span class="pattrn"></span>

										</div>
									</div>

									<div class="formGroup" style="margin-bottom: 20px; clear: both; overflow: hidden;">
										<div class="halfWidth">
											<label for="">Team assignments frequency (Yearly)</label>
											<input type="text" value="2" (change)="PerformCalculation()" [(ngModel)]="team_assignment_frequency_yearly" name="frequency_team_assigment" id="frequency_team_assigment">
										</div>
										<div class="halfWidth">
											<label for="">Fully Loaded Cost factor</label>
											<input type="text" value="1.5" (change)="PerformCalculation()" [(ngModel)]="fullly_loaded_cost_factor" name="loaded_cost_factor" id="loaded_cost_factor">
										</div>
									</div>

									<div class="formGroup">
										<div class="lbs">
											<label for="">Recruitment cost as % of starting salary</label> <span>%</span><h3 id="rectry">{{recrutmnt_cost_starting_salary}}</h3>
										</div>
										<ng5-slider [(value)]="recrutmnt_cost_starting_salary" (valueChange)="PerformCalculation()" [options]="recrutmnt_cost_starting_salaryOptions"></ng5-slider>
										<div class="showPattern">

											<span class="pattrn"></span>

										</div>
									</div>

									<div class="formGroup">
										<div class="lbs">
											<label for="">Number of Recruits per year (growth or churn)</label> <span>%</span><h3 id="rectnumbers">{{no_recruiters_per_year}}</h3>
										</div>
										<ng5-slider [(value)]="no_recruiters_per_year" (valueChange)="PerformCalculation()" [options]="no_recruiters_per_yearOptions"></ng5-slider>
										<div class="showPattern">

											<span class="pattrn"></span>

										</div>
									</div>

									<div class="formGroup">
										<div class="lbs">
											<label for="">Percentage of Management time on HR issues</label> <span>%</span><h3 id="issuemangepct">{{percntge_on_mangment_time_hr_issues}}</h3>
										</div>
										<ng5-slider [(value)]="percntge_on_mangment_time_hr_issues" (valueChange)="PerformCalculation()" [options]="percntge_on_mangment_time_hr_issuesOptions"></ng5-slider>
										<div class="showPattern">

											<span class="pattrn"></span>

										</div>
									</div>

									<div class="formGroup" style="margin-bottom: 30px;">
										<label for="">Typical Pay in Liieu of Notice for Leavers (months)</label>
										<input style="width: 100%;" type="text" (change)="PerformCalculation()" [(ngModel)]="lieu_notice_of_leaves" value="1" name="lieue_notice" id="lieue_notice">
									</div>

								</div>

							</div>

							<div class="sendEmail" id="divValueCalculatorEmail">
                <form method="post" (ngSubmit)="sendValueEmail()">
                  <div class="formGroup" style="margin-bottom: 30px;">
                    <label for="">Client Name</label>
                    <input type="text" [(ngModel)]="emailData.client_name" name="client_name" placeholder="Enter Name" required/>
                    <span class="error" *ngIf="errorContact.client_name.length > 0">
                      {{errorContact.client_name}}
                    </span>
                  </div>
                  <div class="formGroup" style="margin-bottom: 30px;">
                    <label for="">Client Company</label>
                    <input type="text" [(ngModel)]="emailData.client_company" name="client_company" placeholder="Client Company" />
                    <span class="error" *ngIf="errorContact.client_company.length > 0">
                      {{errorContact.client_company}}
                    </span>
                  </div>
                  <div class="formGroup" style="margin-bottom: 30px;">
                    <label for="">Email Address</label>
                    <input type="email" [(ngModel)]="emailData.client_email" name="client_email" placeholder="Email Address" required/>
                    <span class="error" *ngIf="errorContact.client_email.length > 0">
                      {{errorContact.client_email}}
                    </span>
                  </div>
                  <div class="formGroup" style="margin-bottom: 30px;">
                    <label for="">Phone number</label>
                    <input type="number" class="form-control" [(ngModel)]="emailData.client_phone" name="client_phone" placeholder="Phone number" required/>
                    <span class="error" *ngIf="errorContact.client_phone.length > 0">
                      {{errorContact.client_phone}}
                    </span>
                  </div>
                  <div class="formGroup" style="margin-bottom: 30px;">
                    <label for="">
                      Schedule Time
                    </label>
                    <input class="form-control" [min]="min" name="selected_date" [(ngModel)]="selected_date" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Select Date Time">
                    <owl-date-time #dt1></owl-date-time>
                  </div>
                  <button type="submit" class="btn primary" [disabled]="submitting">Value Calculator Enquiry</button>
                </form>
							</div>

							<div class="buttons_panel">
								<!-- <a id="advance" class="btn primary">Advance</a> -->
								<a id="proceed"  class="btn seconday">Proceed</a>
							</div>

						</div>
						<div class="col-lg-6 results" id="valueResultDiv">
							<div class="calculator_generate">
							<div class="labels" style="margin-top: 0;">
                <p></p>
                <p style="text-align: center; margin-top: 0;">
									Without
									<span style="display: block;">
										TeamPlayer
										<sup>HR</sup>
									</span>
								</p>
								<p style="text-align: center; margin-top: 0;">
									<b>With
									<span style="display: block;">TeamPlayer
										<sup>HR</sup>
									</span></b>
								</p>

							</div>

							<div class="reslt">
                <h4>Projected HR Cost</h4>
                <p id="projected_without_tphr">3,860,400</p>
								<input type="hidden" id="projected_without_tphrHid" value="3860400" name="projected_HR_cost_without_tphr">
								<p id="projected_with_tphr">2,996,280</p>
								<input type="hidden" id="projected_with_tphrHid" value="2996280" name="projected_HR_cost_with_tphr">
              </div>

							<div class="reslt">
                <h4>Projected TeamPlayer<sup>HR</sup>  Cost per year</h4>
                <p>&nbsp;</p>
								<p id="idTPHRCostPerYear">24,000</p>
								<input type="hidden" id="tphr_cost_per_year" value="24000" name="tphr_cost_per_year">

							</div>
							<div class="reslt">
                <h4>Projected annual savings from TPHR</h4>
                <p>&nbsp;</p>
								<p id="idAnualSavingsTPHR">864,120</p>
								<input type="hidden" id="tphr_anual_savings" value="24000" name="tphr_anual_savings">

							</div>

							<div class="graphTop">
								<span>
								<input type="checkbox" [(ngModel)]="agile_check" (change)="PerformCalculation()" id="switch" name="agile" /><label class="tottleswi" for="switch">Agile</label>
                <span *ngIf="agile_check == true">Agile</span>
                <span *ngIf="agile_check == false">Non-Agile</span>
								</span>
								<h4>Projected HR costs</h4>
							</div>

							<div class="graphWrapper">

								<svg class="graph" viewBox="0 0 555 200">
									<g>
										<path id="svg_1" d="m10,190c1,0 313,24 532,-180" stroke-width="3.5" stroke="#008cb2" fill="none"/>
										<path id="svg_2" d="m10,190c1,0 313,24 532,-190" stroke-width="3.5" stroke="#d93f36" fill="none"/>
									</g>
								</svg>
								<span id="projectedWITHTPHR">2996K</span>
								<span id="projectedWITHOUTTPHR">3996K</span>

							</div>
							</div>
						</div>
					</div>


			</div>
		</div>
	</div>
</section>

<section id="vision_technology">
	<div class="vision_section">
		<div class="vision">
			<h2 class="mb-4" [innerHTML]="contents.section7.title1">Our Vision</h2>
			<p [innerHTML]="contents.section7.content1">We want to make people happy. They spend most of their waking hours in their place of work; those should be good hours. Compatibility among teams produces happier workers, higher productivity, and a stronger bottom line for employers. The real bottom line is that everyone wins with TeamPlayer<sup>HR</sup>.</p>
		</div>
	</div>
	<div class="technology_section">
		<div class="technology">
			<h2 class="mb-4" [innerHTML]="contents.section7.title7">Our Technology</h2>
			<p [innerHTML]="contents.section7.content2">Scientifically developed, consistent results designed by behavioural psychologists, the TeamPlayer<sup>HR</sup> solution statistically records how each participant perceives, interprets and approaches common workplace situations. TeamPlayer<sup>HR</sup> adds scientific objectivity to the team assignment and selection process because all participants are rated and compared by the same standardised measurements.</p>
		</div>
	</div>
</section>

<section id="difference">
	<div class="container">
		<div class="row">
			<div class="col-lg-7 col-md-12 text-left align-self-center mb-4 mb-lg-0">
				<div class="subhdng mb-0" [innerHTML]="contents.section8.title">OUR APPROACH</div>
				<h2 class="mb-0" [innerHTML]="contents.section8.heading">How are we different?</h2>
				<p class="lgbl" [innerHTML]="contents.section8.subheading"><strong>TeamPlayer<sup>HR</sup> is not a psychometric test.</strong></p>
				<figure><img src="{{baseUrl+'storage/app/public/'+contents.section8.image}}" class="img-fluid" /></figure>
			</div>
			<div class="col-lg-5 col-md-12 align-self-center" [innerHTML]="contents.section8.content">
				<p>TeamPlayer<sup>HR</sup> is not like other personnel management software packages, which typically focus on identifying individuals’ behavioural preferences.</p>
				<p>TeamPlayer<sup>HR</sup> quickly assesses how individuals will interact with each other and tells its users whether individuals will work together effectively by assessing their cultural compatibility so they can create high performing teams.</p>
				<p>TeamPlayer<sup>HR</sup> quickly assesses how individuals will interact with each other and tells its users whether individuals will work together effectively by assessing their cultural compatibility so they can create high performing teams.</p>
			</div>
		</div>
	</div>
</section>

<section class="screenshots-section">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-12 col-sm-12 order-2 order-lg-1">
				<div class="app-info">
					<h2>How Does This App Work?</h2>
					<div class="app_detail">
						<div class="app_dtl_bx">
							<span><i class="fa fa-desktop"></i></span>
							<div class="app_dtl_info">
								<h4>Download Free APP and Sign UP</h4>
								<p>Sign Up to take a brief questionnaire.</p>
							</div>
						</div>
						<div class="app_dtl_bx">
							<span><i class="fa fa-cloud-download"></i></span>
							<div class="app_dtl_info">
								<h4>Take APP Questionnaire</h4>
								<p>Your questionnaire results will establish Your “IM” Intrinsic Matrix Profile, which will allow you to compare your compatibility with others.</p>
							</div>
						</div>
						<div class="app_dtl_bx">
							<span><i class="fa fa-smile-o"></i></span>
							<div class="app_dtl_info">
								<h4>Compare Compatibility</h4>
								<p>Based on your IM, your compatibility with others (Whether individuals, multiple participants or work teams) is computed and ranked.</p>
							</div>
						</div>
					</div>
					<div class="app_btns">
						<a class="ios_btn" target="_blank" href="https://apps.apple.com/us/app/teamplayerhr/id1634560675"><span>Download on</span> App Store</a>
						<a class="android_btn" target="_blank" href="https://play.google.com/store/apps/details?id=com.cts.teamplayer"><span>Download on</span> Google Play</a>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 carousel-screenshot">
				<div class="carousel-container">
					<div class="carousel-outer">
						<!--Carousel-->
						<div class="screenshot-carousel owl-carousel owl-theme">
							<!--Slide-->
							<div class="slide"><figure class="image"><img src="assets/images/app_screenshots/1.jpg" alt=""></figure></div>
							<!--Slide-->
							<div class="slide"><figure class="image"><img src="assets/images/app_screenshots/2.jpg" alt=""></figure></div>
							<!--Slide-->
							<div class="slide"><figure class="image"><img src="assets/images/app_screenshots/7.jpg" alt=""></figure></div>
							<!--Slide-->
							<div class="slide"><figure class="image"><img src="assets/images/app_screenshots/9.png" alt=""></figure></div>
							<!--Slide-->
							<div class="slide"><figure class="image"><img src="assets/images/app_screenshots/10.png" alt=""></figure></div>
						</div>
						<!--Mockup Layer-->
						<div class="mockup-layer"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section id="psychometric_test">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-6 mb-4 mb-md-0">
				<div class="psychometric-test-slider owl-carousel">
					<div class="item">
						<figure><img src="assets/images/psychometric_test/01.jpg"/></figure>
						<h5>Teamplayer<sup>HR</sup> approach: Compatibility  in Context</h5>
					</div>
					<div class="item">
						<figure><img src="assets/images/psychometric_test/02.jpg"/></figure>
						<h5>Teamplayer<sup>HR</sup> in Action: Simple Steps to Superior Insights</h5>
					</div>
					<div class="item">
						<figure><img src="assets/images/psychometric_test/03.jpg"/></figure>
						<h5>Sign Up Page</h5>
					</div>
					<div class="item">
						<figure><img src="assets/images/psychometric_test/04.jpg"/></figure>
						<h5>Admin Tool Web Page</h5>
					</div>
					<div class="item">
						<figure><img src="assets/images/psychometric_test/05.jpg"/></figure>
						<h5>identifying the best fit</h5>
					</div>
					<div class="item">
						<figure><img src="assets/images/psychometric_test/06.jpg"/></figure>
						<h5>clear and objective results</h5>
					</div>
					<div class="item">
						<figure><img src="assets/images/psychometric_test/07.jpg"/></figure>
						<h5>confirming compatibility</h5>
					</div>
					<div class="item">
						<figure><img src="assets/images/psychometric_test/08.jpg"/></figure>
						<h5>clear and objective results</h5>
					</div>
					<div class="item">
						<figure><img src="assets/images/psychometric_test/09.jpg"/></figure>
						<h5>Reap the benefits of best fits</h5>
					</div>
					<div class="item">
						<figure><img src="assets/images/psychometric_test/10.jpg"/></figure>
						<h5>the best resource for best fits</h5>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-md-6 mb-4 mb-md-0">
				<div class="psychometric_info">
					<h3>TeamPlayer<sup>HR</sup> is not a Psychometric Test</h3>
					<p>There are no rights or wrong answers. They are your answers,</p>
					<p>“Your - IM” Intrinsic Matrix,  One cannot pass or fail and it is not a TEST.</p>
				</div>
			</div>
		</div>
	</div>
</section>

<section id="aboutus" class="aboutus">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 col-md-12 col-sm-12 align-self-center order-2 order-md-1">
				<div class="about_cnt">
					<div class="subhdng" [innerHTML]="contents.section3.title">Who we are</div>
					<h1 class="mb-3" [innerHTML]="contents.section3.heading">About Us</h1>
					<span><a [routerLink]="['/home']">Home</a> -> About Us</span>
					<p [innerHTML]="contents.section3.content1"><strong>TeamPlayer<sup>HR</sup> was the brainchild of James ‘Jim’ Lanas, a management and recruiting professional who saw first-hand the challenges of building, deploying and retaining a skilled workforce in the medical arena.</strong></p>
					<p [innerHTML]="contents.section3.content2">He recognized that there was a huge disconnect in the workplace between “paper” credentials – the expertise, experience, and skillset that appear on a resume – and "intrinsic" credentials – the way employees react, respond, and appear in real-time in the work environment. He commissioned a U.K. Cambridge-educated behavioural psychologist to devise a questionnaire that would help identify this intrinsic matrix, or "IM," in each person.</p>
					<p [innerHTML]="contents.section3.content3">TeamPlayer<sup>HR</sup>, the solution that he created, is so revolutionary that it has been awarded patents. Top HR, personnel development and recruiting professionals in the UK and the United States are now part of the team.</p>
				</div>
			</div>
			<div class="col-lg-6 col-md-12 col-sm-12 align-self-center order-1 order-md-2">
				<div class="about_imgs_cont position-relative">
					<figure class="img1"><img src="{{baseUrl+'storage/app/public/'+contents.section3.image1}}" /></figure>
					<figure class="img2"><img src="{{baseUrl+'storage/app/public/'+contents.section3.image2}}" /></figure>
					<figure class="img3"><img src="{{baseUrl+'storage/app/public/'+contents.section3.image3}}" /></figure>
					<div class="clearfix"></div>
				</div>
			</div>
		</div>
	</div>
</section>
<section id="testimonial">
	<div class="container">
		<div class="row">
			<div class="col-lg-5 mb-4 mb-lg-0">
				<div class="subhdng" [innerHTML]="contents.section9.title">our testimonial</div>
				<h2 class="mb-4" [innerHTML]="contents.section9.heading">What People Say About <span>TeamPlayer<sup>HR</sup></span></h2>
				<p [innerHTML]="contents.section9.content">Don’t just take our word for it. Our happy customers also say good things about TeamPlayer<sup>HR</sup></p>
			</div>
			<div class="col-lg-7">
				<div id="testimonial-slider" class="owl-carousel owl-theme" *ngIf="testimonialData.length > 0">
					<div class="item" *ngFor="let item of testimonialData">
					  <div class="testimonial_bx">
						<figure><img src="{{baseUrl+'storage/app/public/'+item.image}}" /></figure>
						<p><span class="upr">{{item.message}}</span></p>
						<div class="designatiion">
							{{item.name}} - <span>{{item.post}}</span>
						</div>
					  </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section id="new-trading">
	<div class="container upr">
		<div class="h1 text-center mb-5">TeamPlayerUK doing business as <span>TeamPlayer<sup>HR</sup></span></div>
	</div>
</section>

<footer id="footer" class="pb-0">
	<div class="container">
		<div class="logo_continer">
			<div id="logo-slider" class="owl-carousel owl-theme" *ngIf="partnerData.length > 0">
				<div class="item" *ngFor="let item of partnerData">
				  <figure><img src="{{baseUrl+'storage/app/public/'+item.image}}" /></figure>
				</div>
			</div>
			<div class="clearfix"></div>
		</div>
	</div>
</footer>
<div class="modal fade custom_modal" id="videoModal1" tabindex="-1" role="dialog" aria-labelledby="videoModal" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-body">
				<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
				<div class="video_frame">
					<iframe width="100%" height="450" src="" allowfullscreen></iframe>
				</div>
			</div>
		</div>
	</div>
</div>
