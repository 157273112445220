<!--<ngx-braintree *ngIf="amount != null"
  [clientTokenURL]="baseUrl+'api/braintree/getclienttoken'"
  [createPurchaseURL]="baseUrl+'api/braintree/createpurchase'"
  [chargeAmount]="amount"
  (paymentStatus)="onPaymentStatus($event)"
  [allowChoose]="true"
  [showCardholderName]="true"
  [enablePaypalCheckout] = "true"
  [currency]="'GBP'"
  >
</ngx-braintree>-->
<h6>Pay £{{amountwithvat}} (Inclusive {{vatpricef}}% VAT)</h6>
<ngx-paypal [config]="payPalConfig"></ngx-paypal>
