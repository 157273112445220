<div id="mainContainer">
<div class="row">
<div class="col-md-12"><div class="price-detail"  [innerHtml]="CompanySubscriptiondata"></div></div>
  <div class="col-md-12">
      <p>Enter number of employees and click Buy Now. After your purchase is complete you will be assigned Questionnaire to allow others to take the questionnaire.</p>
      <p>
          <b>Subscription:</b> TeamPlayerHR
      </p>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="formGroup">
      <label>Number Of Employee</label>
      <input [(ngModel)]="number_employee" (change)="refreshButton()" type="number" min="1" class="form-control" />
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
      <table class="table table-border">
          <thead>
          <tr>
              <th>Plan</th>
          </tr>
          </thead>

          <tbody>
              <tr>
                  <td>{{data[0].title}} - £{{data[0].amount}}/Employee</td>
              </tr>
              <tr>
                <td>
                  <app-paypal-button [numberOfEmployees]="number_employee" *ngIf="showButton"></app-paypal-button>
                </td>
            </tr>
          </tbody>
      </table>
  </div>
</div>
</div>


<div class="payment" id="paymentSuccess" style="display: none;">
  <div class="payment_header">
     <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
  </div>
  <div class="content">
     <h1>Payment Success !</h1>
     <p>Subscription purchase successful. </p>
  </div>

</div>
<div class="payment" id="paymentError" style="display: none;">
  <div class="payment_header">
     <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
  </div>
  <div class="content">
     <h1>Payment Failed !</h1>
     <p>Subscription purchase failed, please try again. </p>
  </div>

</div>
