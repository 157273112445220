import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule}   from '@angular/forms';
//import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { Ng2TelInputModule} from 'ng2-tel-input';
import { HttpClientModule } from '@angular/common/http';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxPayPalModule } from 'ngx-paypal';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelect2Module } from 'ng-select2';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NgxBraintreeModule } from 'ngx-braintree';

import { Globals } from './global';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { UserComponent } from './user/user.component';
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { PurchaseQuestionnaireComponent } from './user/purchase-questionnaire/purchase-questionnaire.component';
import { ManageQuestionnaireComponent } from './user/manage-questionnaire/manage-questionnaire.component';
import { SurveyGroupComponent } from './user/survey-group/survey-group.component';
import { QuestionnaireResultComponent } from './user/questionnaire-result/questionnaire-result.component';
import { FilterPipe } from './filter.pipe';
import { SurveyResultTeamComponent } from './user/survey-result-team/survey-result-team.component';
import { StartTestComponent } from './user/start-test/start-test.component';
import { SectionIntroComponent } from './user/section-intro/section-intro.component';
import { QuestionComponent } from './user/question/question.component';
import { TimerPipe } from './timer.pipe';
import { DemoComponent } from './user/demo/demo.component';
import { DemoStartTestComponent } from './user/demo-start-test/demo-start-test.component';
import { DemoQuestionComponent } from './user/demo-question/demo-question.component';
import { ProfileComponent } from './user/profile/profile.component';
import { PartnerComponent } from './partner/partner.component';
import { NewsComponent } from './news/news.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { ValueCalculatorComponent } from './user/value-calculator/value-calculator.component';
import { PurchaseSubscriptionComponent } from './user/purchase-subscription/purchase-subscription.component';
import { PaypalButtonComponent } from './shared/paypal-button/paypal-button.component';
import { DemoSurveyResultTeamComponent } from './user/demo-survey-result-team/demo-survey-result-team.component';
import { PurchaseDemoQuestionnaireComponent } from './user/purchase-demo-questionnaire/purchase-demo-questionnaire.component';
import { DemoPaypalButtonComponent } from './shared/demo-paypal-button/demo-paypal-button.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { WhatsYourImComponent } from './whats-your-im/whats-your-im.component';
import { FullPaymentButtonComponent } from './shared/full-payment-button/full-payment-button.component';
import { RenewalPaymentButtonComponent } from './shared/renewal-payment-button/renewal-payment-button.component';
import { AppSurveyResultTeamComponent } from './app-survey-result-team/app-survey-result-team.component';
import { MobileValueCalculatorComponent } from './mobile-value-calculator/mobile-value-calculator.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { AppsubPaymentButtonComponent } from './shared/appsub-payment-button/appsub-payment-button.component';
import { PpcPaymentButtonComponent } from './shared/ppc-payment-button/ppc-payment-button.component';
import { CompanyUserComponent } from './user/company-user/company-user.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    FaqComponent,
    HowItWorksComponent,
    LoginComponent,
    SignupComponent,
    UserComponent,
    DashboardComponent,
    LogoutComponent,
    PurchaseQuestionnaireComponent,
    ManageQuestionnaireComponent,
    SurveyGroupComponent,
    QuestionnaireResultComponent,
    FilterPipe,
    SurveyResultTeamComponent,
    StartTestComponent,
    SectionIntroComponent,
    QuestionComponent,
    TimerPipe,
    DemoComponent,
    DemoStartTestComponent,
    DemoQuestionComponent,
    ProfileComponent,
    PartnerComponent,
    NewsComponent,
    NewsDetailComponent,
    ValueCalculatorComponent,
    PurchaseSubscriptionComponent,
    PaypalButtonComponent,
    DemoSurveyResultTeamComponent,
    PurchaseDemoQuestionnaireComponent,
    DemoPaypalButtonComponent,
    TestimonialsComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    WhatsYourImComponent,
    FullPaymentButtonComponent,
    RenewalPaymentButtonComponent,
    AppsubPaymentButtonComponent,
    AppSurveyResultTeamComponent,
    PpcPaymentButtonComponent,
    MobileValueCalculatorComponent,
    PurchaseComponent,
    CompanyUserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    NgxPayPalModule,
    Ng5SliderModule,
    NgxSpinnerModule,
    NgSelect2Module,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxBraintreeModule,
    Ng2TelInputModule,
    GooglePlaceModule,
    //NgxIntlTelInputModule,
    ToastrModule.forRoot(),
    RecaptchaV3Module,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthGuard,
    Globals,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LcHg5ApAAAAAPT4sbSh93gmJDauNEfs0BVvQx2h" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
