<div id="mainContainer">
<div class="row">
<div class="col-md-12"><div class="price-detail"  [innerHtml]="AppQuestionnairedata"></div></div>
  <div class="col-md-12">
      <p>Enter number of questionnaire and click Buy Now. After your purchase is complete you will be assigned Questionnaire to allow others to take the questionnaire.</p>
      <p>
          <b>App Questionnaire:</b> TeamPlyerHR
      </p>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="formGroup">
      <label>Number Of Questionnaire</label>
      <input [(ngModel)]="number_employee" (load)="refreshButton()"  (change)="refreshButton()" type="number" min="100" class="form-control" />
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
      <table class="table table-border paypal_demo_button">
          <thead>
          <tr>
              <th colspan="2" >Plan</th>
          </tr>
          </thead>

          <tbody>
              <tr>
                  <td>{{data[0].name}} - £{{data[0].amount}}/Questionnaire</td>
              </tr>
              <tr>
                  <td>
                    <app-demo-paypal-button [numberOfEmployees]="number_employee" *ngIf="showButton"></app-demo-paypal-button>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>
</div>
</div>
<div class="payment" id="paymentSuccess" style="display: none;">
  <div class="payment_header">
     <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
  </div>
  <div class="content">
     <h1>Payment Success !</h1>
     <p>App questionnaire purchase successful. </p>
  </div>

</div>
<div class="payment" id="paymentError" style="display: none;">
  <div class="payment_header">
     <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
  </div>
  <div class="content">
     <h1>Payment Failed !</h1>
     <p>App questionnaire purchase failed, please try again. </p>
  </div>

</div>
