
<section id="user_login">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 col-sm-12 questionaire_cnt">
				<div class="menus_left" >
					<nav>
						<button id="menu_toggle_login"><i class="fa fa-align-justify"></i></button>
						<ul class="mcd-menu" *ngIf="auth.role == 1 || auth.role == 3">
							<li>
								<a [routerLink]="['manage-group']" routerLinkActive="active" ><strong>Manage Group</strong></a>
							</li>
							<li>
								<a [routerLink]="['profile']" routerLinkActive="active" ><strong>Profile</strong></a>
							</li>
							<li>
								<div class="dropdown">
									<a class="dropdown-toggle" href="#" role="button" id="purchases-dropdown-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										Purchases
									</a>
									<div class="dropdown-menu dropdown-menu-lg" aria-labelledby="purchases-dropdown-link">
										<a [routerLink]="['purchase-questionnaire']" routerLinkActive="active" class="text-nowrap" ><strong>Full Questionnaire</strong></a>
										<a [routerLink]="['subscription']" routerLinkActive="active" class="text-nowrap" ><strong>Organization/ Company Subscription</strong></a>
										<a [routerLink]="['app-questionnaire']" routerLinkActive="active" class="text-nowrap" ><strong>App Admin</strong></a>
										<a [routerLink]="['purchase-app-questionnaire']" routerLinkActive="active" class="text-nowrap" ><strong>APP-based Questionnaire</strong></a>
									</div>
								</div>
							</li>

							<!-- <li>
								<a href="javascript:void(0);"><strong>Webinar Admin</strong></a>
								<ul>
									<li><a href="javascript:void(0);">Manage Webinars</a></li>
								</ul>
							</li> -->
							<!-- <li>
								<a href="javascript:void(0);"><strong>My Referrals</strong></a>
								<ul>
									<li><a href="javascript:void(0);">Referrals</a></li>
								</ul>
							</li> -->
              <li *ngIf="auth.role == 1 ">
								<a [routerLink]="['value-calculator']" routerLinkActive="active" ><strong>Value Calculator</strong></a>
							</li>
							<li *ngIf="auth.role == 1 ">
								<a [routerLink]="['company-user']" routerLinkActive="active" ><strong>Company Users</strong></a>
							</li>
							<li><a [routerLink]="['/logout']" routerLinkActive="router-link-active"><strong>Log Out</strong></a></li>
						</ul>
						<ul class="mcd-menu" *ngIf="auth.role == 2">
							<li>
								<a [routerLink]="['app-questionnaire']" routerLinkActive="active" ><strong>App Admin</strong></a>
							</li>
              <li>
								<a [routerLink]="['purchase-app-questionnaire']" routerLinkActive="active" ><strong>Purchase App Questionnaire</strong></a>
							</li>
              <li>
								<a [routerLink]="['manage-group']" routerLinkActive="active" ><strong>Manage Group</strong></a>
							</li>
							<li>
								<a [routerLink]="['profile']" routerLinkActive="active" ><strong>Profile</strong></a>
							</li>
							<li><a [routerLink]="['/logout']" routerLinkActive="router-link-active"><strong>Log Out</strong></a></li>
						</ul>
					</nav>
				</div>
				<div class="clearfix"></div>
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</section>


