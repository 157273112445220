<ngx-spinner>
  <p style="font-size: 20px; color: white">Generating PDF...</p>
</ngx-spinner>
<style>
.bar-img{background:url(assets/images/score.jpg) no-repeat center center / 100% auto;width:300px; margin: 0px;}
</style>
<div class="row" *ngIf="result != undefined">
  <div class="col-md-12 mb-4">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 mb-4 mb-md-0 text-left">
        <a href="javascript:void(0)" [routerLink]="['/user/manage-group']" class="btn btn-sm btn-info">Back</a> &nbsp;
        
        <button (click)="captureScreen()" class="btn btn-sm btn-info">Download Complete Report</button> &nbsp;
        <!-- <button (click)="sendFullReport()" class="btn btn-sm btn-info">Email Complete Report</button> &nbsp; -->
        <button (click)="captureSummary()" class="btn btn-sm btn-info">Download Summary</button>&nbsp;
        <!-- <button (click)="sendSummary()" class="btn btn-sm btn-info">Email Summary</button> -->
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 mb-4 mb-md-0 text-right">
        <span class="cstm_rdo">
          <input type="radio" value="false" [(ngModel)]="showIm" name="radio_name" />
          <label>Name</label>
        </span>
        <span class="cstm_rdo">
          <input type="radio" value="true"  name="radio_name" [(ngModel)]="showIm"/>
          <label>IM ID</label>
        </span>
      </div>
    </div>
      <!-- <h3>{{result.team.name}} Questionnaire Results for {{result.user.first_name}} {{result.user.last_name}}</h3> -->

  </div>
</div>
<div class="row" *ngIf="result != undefined" id="pageData">
    <div class="col-md-12 divPdf" id="coverPage">
        <div class="card">
            <span class="pdf_logo">TeamPlayer <sup >Hr</sup></span>
            <div class="row">
                <div class="col-md-12 text-center divCoverText">
                    <h3>Compatibility Report</h3>
                    <p>For : {{result.user.first_name}} {{result.user.last_name}}</p>
                    <span>Date : {{ currentDate() }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 divPdf" id="contentPage">
      <div class="card" >
          <h2>All Participants – Summary</h2>
          <h3 *ngIf="this.data.user_type=='benchmark'">Benchmark Person: {{result.user.first_name}} {{result.user.last_name}}</h3>
          <h3 *ngIf="this.data.user_type=='participant'">Participant Person: {{result.user.first_name}} {{result.user.last_name}}</h3>
          <p> Here are the results of the Compatibility Alignment between the benchmark person and participants.</p>
          <!-- <hr class="clhr"/>
          <div class="row divide_bx">
            <div class="col-md-6">
              <p>
                Overall results indicate a very <b>low level</b> of compatibility between the participant and the benchmark person
              </p>
            </div>
            <div class="col-md-6">
              <p>
                Overall results indicate a very <b>high level</b> of compatibility between the participant and the benchmark person
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">&nbsp;</div>
            <div class="col-md-4">
              <div style="width:300px; background-image: url(assets/images/score.jpg); margin: 0px;">
                <img src="assets/images/dot.png" style="position:relative;" [style.left]="((288  * result.avg_all_participent)/106)+'px'">
              </div>
            </div>
            <div class="col-md-4">&nbsp;</div>
          </div> -->
          <div class="scrol_tbl">
            <table class="table">
              <thead class="thead-dark">
                <tr>
                    <th *ngIf="this.data.user_type=='benchmark'">Participants</th>
                    <th *ngIf="this.data.user_type=='participant'">Benchmarks</th>
                    <th class="progress-hdng">
                        <div>Significant<br>Disagreement</div>
                        <div>Significant<br>Agreement</div>
                    </th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of result.user_list">
                      <td>
                        <span *ngIf="showIm  == 'false'">{{item.name}}</span>
                        <span *ngIf="showIm  == 'true'">{{item.im}}</span>
                        <span *ngIf="item.survey_progress != 1">(Not completed)</span>
                      </td>
                      <td >
                         <!--<div class="bar-img" *ngIf="(item.score > item.max_score)">
                              <img *ngIf="(100) > 11" src="assets/images/dot.png" style="position:relative;margin-left:-12px;" [style.left]="(100)+'%'">
                              <img *ngIf="(100) <= 11" src="assets/images/dot.png" style="position:relative;margin-left:12px;" [style.left]="(100)+'%'">
                          </div>-->
						  <div class="bar-img">
                              
                              <img src="assets/images/dot.png" style="position:relative;margin-left:12px;" [style.left]="(100 * (item.score/item.max_score))+'%'">
                          </div>
						 
                      </td>
                  </tr>
              </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="col-md-12 divPdf" id="sectionPage{{i}}" *ngFor="let item of result.section_list;let i = index;">
      <div class="card" >
          <h2>All Participants – Breakdown by Questionnaire Section</h2>
          <h3 *ngIf="this.data.user_type=='benchmark'">Benchmark Person: {{result.user.first_name}} {{result.user.last_name}}</h3>
          <h3 *ngIf="this.data.user_type=='participant'">Participant Person: {{result.user.first_name}} {{result.user.last_name}}</h3>
          <h3>Section: {{item[0].section_name}}</h3>
          <p>Here are the results of the assessment of compatibility Alignment between the benchmark person and each participant for <b>{{item[0].section_name}}</b>. Goals, views on work and preferred models of professional practice</p>
          <!-- <hr class="clhr" />
          <div class="row divide_bx">
            <div class="col-md-6">
              <p>
                Overall results indicate a very <b>low level</b> of compatibility between the participant and the benchmark person
              </p>
            </div>
            <div class="col-md-6">
              <p>
                Overall results indicate a very <b>high level</b> of compatibility between the participant and the benchmark person
              </p>
            </div>
          </div> -->
          <div class="scrol_tbl">
            <table class="table">
                <thead class="thead-dark">
                  <tr>
                      <th *ngIf="this.data.user_type=='benchmark'">Participants</th>
                      <th *ngIf="this.data.user_type=='participant'">Benchmarks</th>
                      <th class="progress-hdng">
                        <div>Significant<br>Disagreement</div>
                        <div>Significant<br>Agreement</div>
                      </th>

                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let it of item">
                        <td>
                          <span *ngIf="showIm  == 'false'">{{it.name}}</span>
                          <span *ngIf="showIm  == 'true'">{{it.im}}</span>
                        </td>
                        <td>
                           
							<div class="bar-img" >
                                
                                <img src="assets/images/dot.png" style="position:relative;margin-left:12px;" [style.left]="(100 * (it.score/it.max_score))+'%'">
                            </div>
                        </td>
                   </tr>
                </tbody>
            </table>
          </div>
      </div>
    </div>

    <div class="col-md-12 divPdf" id="indivdualPage{{i}}" *ngFor="let item of result.user_list;let i = index;">
      <div class="card">

          <h2>Individual Participant Report</h2>
          <h3 *ngIf="this.data.user_type=='benchmark'">Benchmark Person: {{result.user.first_name}} {{result.user.last_name}}</h3>
          <h3 *ngIf="this.data.user_type=='participant'">Participant Person: {{result.user.first_name}} {{result.user.last_name}}</h3>
		  <div *ngIf="this.data.user_type=='benchmark'">
          <h3 *ngIf="showIm  == 'false'">Participant : {{item.name}}</h3>
          <h3 *ngIf="showIm  == 'true'">Participant : {{item.im}}</h3>
		  </div>
		   <div *ngIf="this.data.user_type=='participant'">
          <h3 *ngIf="showIm  == 'false'">Benchmark : {{item.name}}</h3>
          <h3 *ngIf="showIm  == 'true'">Benchmark : {{item.im}}</h3>
		  </div>
          <p>Here are the results of the assessment of compatibility Alignment between the benchmark person and the participants.</p>
          <!-- <hr class="clhr" />
          <div class="row divide_bx">
            <div class="col-md-6">
              <p>
                Overall results indicate a very <b>low level</b> of compatibility between the participant and the benchmark person
              </p>
            </div>
            <div class="col-md-6">
              <p>
                Overall results indicate a very <b>high level</b> of compatibility between the participant and the benchmark person
              </p>
            </div>
          </div> -->
          <div class="scrol_tbl">
            <table class="table">
                <thead class="thead-dark">
                  <tr>
                      <th>Questionnaire Section</th>
                      <th class="progress-hdng">
                        <div>Significant<br>Disagreement</div>
                        <div>Significant<br>Agreement</div>
                      </th>
                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let it of item.section_result">
                        <td>{{it.section_name}}</td>
                        <td>
                           
							 <div  class="bar-img">
                                <img src="assets/images/dot.png" style="position:relative;margin-left:12px;" [style.left]="(100 * (it.score/it.max_score))+'%'">
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                  <tr>
                      <td>Overall</td>
                      <td>
                          
						   <div  class="bar-img">
                             
                              <img src="assets/images/dot.png" style="position:relative;margin-left:12px;" [style.left]="((100 * item.score) / item.max_score)+'%'">
                          </div>
                      </td>
                   </tr>
                </tfoot>
            </table>
          </div>
      </div>
    </div>


</div>
