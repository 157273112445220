import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '../../global';
import { DemoapiService } from '../../demoapi.service';
import { ToastrService } from 'ngx-toastr';
import { DemoPaypalButtonComponent } from '../../shared/demo-paypal-button/demo-paypal-button.component';

@Component({
  selector: 'app-purchase-demo-questionnaire',
  templateUrl: './purchase-demo-questionnaire.component.html',
  styleUrls: ['./purchase-demo-questionnaire.component.css']
})
export class PurchaseDemoQuestionnaireComponent implements OnInit {
  AppQuestionnairedata:any;
  paymentDetail:any;
  paypal:any;
  number_employee:number = 100;
  showButton:boolean;
  data:any = [
    {"id":1,"name":"1 Survey","number_survay":"1","amount":"0.99"}];
  constructor(private global: Globals,private route:ActivatedRoute,private api: DemoapiService,private toastr: ToastrService) {

   }

   ngOnInit(): void {
	   this.api.getDemoMeta('app-based-questionnaire').subscribe((data: any[])=>{
      var res:any = data;
      this.AppQuestionnairedata = res.data.content;
      
    });
    this.api.getPlan().subscribe((data: any[])=>{
      let res:any = data;
      this.data = res.data;
      this.refreshButton();
    })
  }

  refreshButton(){
    this.showButton = false;
    setTimeout(()=>{
      this.showButton = true;
    }, 10);
  }

}
