
<footer id="footer" class="pb-0">
	<div class="container">


		<div class="row">
			<div class="col-lg-4 col-md-5 col-sm-12 mb-5 mb-lg-0">
				<h4 class="mb-4">Useful Links</h4>
				<ul class="mnus">
					<li><a [routerLink]="['/how-it-works']" routerLinkActive="router-link-active">How it works</a></li>
					<li><a [routerLink]="['/whats-your-im']" routerLinkActive="router-link-active">What's your IM</a></li>
					<li><a [routerLink]="['/news']" routerLinkActive="router-link-active">News</a></li>
					<li><a [routerLink]="['/partners']" routerLinkActive="router-link-active">Partners</a></li>
					<!-- <li><a >Subscription</a></li> -->
					<li><a [routerLink]="['/contact']" routerLinkActive="router-link-active">Contact us</a></li>
				</ul>
			</div>
			<div class="col-lg-8 text-center col-md-7 col-sm-12">
				<!-- <h4 class="mb-4">uk contact detail</h4> -->
				<div class="foter_map">
					<img src="assets/images/footer_map.png" />
					<a class="map_location uk" href="#"><i class="flaticon-location"></i></a>

					<div class="map_dtl uk">
						<h4>UK Address</h4>
						<ul>
							<li>Unit 29, Enterprise Industrial Estate,Bolina Road London SE16 3LF United Kingdom</li>
							<li>+44 (0)203 239 2186</li>
							<li>info@TeamPlayerHR.com</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="copyright">
		<!-- <p>TeamPlayerUK Ltd. is trading as TeamPlayerHR</p> -->
			<p>Copyright © {{currentYear}}. All rights reserved</p>
		</div>
	</div>
</footer>


<div class="scroll-top-wrapper"><span class="scroll-top-inner"><i class="fa fa-angle-up"></i></span></div>
