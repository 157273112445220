import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { UserComponent } from './user/user.component';

import { AuthGuard } from './auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { PurchaseQuestionnaireComponent } from './user/purchase-questionnaire/purchase-questionnaire.component';
import { ManageQuestionnaireComponent } from './user/manage-questionnaire/manage-questionnaire.component';
import { SurveyGroupComponent } from './user/survey-group/survey-group.component';
import { QuestionnaireResultComponent } from './user/questionnaire-result/questionnaire-result.component';
import { SurveyResultTeamComponent } from './user/survey-result-team/survey-result-team.component';
import { StartTestComponent } from './user/start-test/start-test.component';
import { QuestionComponent } from './user/question/question.component';
import { DemoComponent } from './user/demo/demo.component';
import { DemoStartTestComponent } from './user/demo-start-test/demo-start-test.component';
import { DemoQuestionComponent } from './user/demo-question/demo-question.component';
import { ProfileComponent } from './user/profile/profile.component';
import { PartnerComponent } from './partner/partner.component';
import { NewsComponent } from './news/news.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { ValueCalculatorComponent } from './user/value-calculator/value-calculator.component';
import { PurchaseSubscriptionComponent } from './user/purchase-subscription/purchase-subscription.component';
import { DemoSurveyResultTeamComponent } from './user/demo-survey-result-team/demo-survey-result-team.component';
import { PurchaseDemoQuestionnaireComponent } from './user/purchase-demo-questionnaire/purchase-demo-questionnaire.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { WhatsYourImComponent } from './whats-your-im/whats-your-im.component';
import { AppSurveyResultTeamComponent } from './app-survey-result-team/app-survey-result-team.component';
import { MobileValueCalculatorComponent } from './mobile-value-calculator/mobile-value-calculator.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { CompanyUserComponent } from './user/company-user/company-user.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'partners', component: PartnerComponent },
  { path: 'testimonials', component: TestimonialsComponent },
  { path: 'news', component: NewsComponent },
  { path: 'news/:slug', component: NewsDetailComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'whats-your-im', component: WhatsYourImComponent },
  { path: 'mobile-value-calculator', component: MobileValueCalculatorComponent},
  { path: 'app-survey-result-team', component: AppSurveyResultTeamComponent },
  { path: 'purchase', component: PurchaseComponent },
  { path: 'user', component: UserComponent, canActivate: [AuthGuard], children:[
    { path: '', component: DashboardComponent },
    { path: 'purchase-questionnaire', component: PurchaseQuestionnaireComponent },
    { path: 'subscription', component: PurchaseSubscriptionComponent },
    { path: 'manage-group', component: ManageQuestionnaireComponent },
    { path: 'survey-group/:id', component: SurveyGroupComponent },
    { path : 'survey-result', component: QuestionnaireResultComponent},
    { path : 'survey-result-team', component: SurveyResultTeamComponent},
    { path : 'value-calculator', component: ValueCalculatorComponent},
    { path : 'company-user', component: CompanyUserComponent},
    { path : 'start-test', component: StartTestComponent},
    { path : 'question', component: QuestionComponent},
    { path : 'app-questionnaire', component: DemoComponent},
    { path : 'app-questionnaire/start-test', component: DemoStartTestComponent},
    { path : 'app-questionnaire/question', component: DemoQuestionComponent},
    { path : 'app-questionnaire/survey-result-team', component: DemoSurveyResultTeamComponent},
    { path: 'purchase-app-questionnaire', component: PurchaseDemoQuestionnaireComponent },
    { path : 'profile', component: ProfileComponent},
  ] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
