import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {FormControl, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../global';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any ;
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  auth:any;
  constructor(private global: Globals,private route:ActivatedRoute,private api: ApiService, private router: Router,private toastr: ToastrService) { }
  
ngAfterViewInit(): void {
    $('#menu_toggle_login').on('click', function(){
      $(this).parent().toggleClass('show-menu')
    })
  }
  ngOnInit(): void {
    this.auth = this.global.getUser();
  }

}
