import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Globals } from '../global';
import { ApiService } from '../api.service';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  title = 'TeamPlayerHR : Contact';
  metadata:any;
  selected_date:any= "";
  public min = new Date();
  errorCT = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message_text: "",
    selected_date: ""
  };
  ctForm = this.fb.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    phone: ['', Validators.email],
    subject: ['', Validators.required],
    message_text: ['', Validators.required],
    selected_date: ['', Validators.required]
  });
  constructor(private titleService: Title,private metaTagService: Meta,private global: Globals, private api: ApiService,private fb: FormBuilder, private toastr: ToastrService,private recaptchaV3Service: ReCaptchaV3Service,) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.setMeta();
  }
  setMeta(){
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Our goal is to help you quickly find employees who will meet your exact needs and stay with your organization in the long run. How can we do this? The answer is simple: The Career Matching Assessment Tool (Team Player Hr) and its proprietary technology 2' }
    );
    this.api.getMeta('contact').subscribe((data: any[])=>{

      var res:any = data;
      this.metadata = res.data;
      this.titleService.setTitle(this.metadata.title);
      this.metaTagService.updateTag(
        { name: 'description', content: this.metadata.description }
      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: this.metadata.keyword }
      );
    });
  }

  validateData(ob:any){
    this.errorCT = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message_text: "",
    selected_date:""
    };
    var status = true;
    if(ob.name == "" || ob.name.length == 0)
    {
      this.errorCT.name = "Name is required.";
      status = false;
    }

    if(ob.phone == null)
    {
      this.errorCT.phone = "Please enter valid phone no.";
      status = false;
    }
    else if(ob.phone == "" || ob.phone.length == 0)
    {
      this.errorCT.phone = "Phone is required.";
      status = false;
    }

    if(ob.email == "" || ob.email.length == 0)
    {
      this.errorCT.email = "Email is required.";
      status = false;
    }
    else if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(ob.email) == false)
    {
      this.errorCT.email = "Please enter valid email.";
      status = false;
    }

    if(ob.subject == "" || ob.subject.length == 0)
    {
      this.errorCT.subject = "Subject is required.";
      status = false;
    }
    if(ob.message_text == "" || ob.message_text.length == 0)
    {
      this.errorCT.message_text = "Message is required.";
      status = false;
    }
    if(ob.selected_date == "" || ob.selected_date.length == 0)
    {
      this.errorCT.selected_date = "Contact date is required.";
      status = false;
    }

    return status;
  }

  onSubmit() {
    this.recaptchaV3Service.execute('submit')
      .subscribe((token) => console.log('recaptcha executed'));

    var validation = this.validateData(this.ctForm.value);
    var frmData1 = this.ctForm.value;
    frmData1.selected_date = this.formatDate(this.selected_date);
    if(validation == true)
    {

      this.api.contactRequest(frmData1).subscribe((data:any)=>{

        var res = data;
        this.toastr.success('', res.message);
        this.ctForm = this.fb.group({
          name: ['', Validators.required],
          email: ['', Validators.required],
          phone: ['', Validators.email],
          subject: ['', Validators.required],
          message_text: ['', Validators.required],
          selected_date: ['', Validators.required]
        });

      },(res:any)=>{

        var temp = res.error.data;
        for (const [key, value] of Object.entries(temp)) {
          this.errorCT[key] = value[0];
        }
        this.toastr.error(res.error.message,'Error');
      })
    }
  }
  formatDate(date:any) {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();
    var hour = d.getHours().toString();
    var minute = d.getMinutes().toString();

    if (month.length < 2)
    {
      month = '0' + month;
    }
    if (day.length < 2)
    {
      day = '0' + day;
    }
    if (hour.length < 2)
    {
      hour = '0' + hour;
    }
    if (minute.length < 2)
    {
      minute = '0' + minute;
    }

    var result = [year, month, day].join('-') +' '+ [hour, minute].join(':');;
    return result;
}

}
