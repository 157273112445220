
<section id="slider" class="inner_bnr" style="background:url(assets/images/faq_bnr.jpg) no-repeat center center / cover;">
	<h2 class="upr">If You're Seeking Opportunities</h2>
	<span class="upr">Frequently Asked Questions</span>
</section>

<section id="faq">
	<div class="container">
		<div id="accordion">
		  <div class="card" *ngFor="let item of questionData; let i = index;">
			<div class="card-header" id="heading{{i}}" (click)="toggleAccordian(i)">
			  <h5 class="mb-0">
				<button class="btn btn-link collapsed" [ngClass]="{collapsed: activeAccordian != i}" data-toggle="collapse" >{{item.question}}</button>
			  </h5>
			</div>

			<div id="collapse{{i}}" class="collapse" [ngClass]="{show: activeAccordian == i}" aria-labelledby="headingOne" data-parent="#accordion">
			  <div class="card-body" [innerHTML]="item.answer">

			  </div>
			</div>
		  </div>
		</div>
	</div>
</section>

<section id="intrested">
	<div class="container">
		<div class="row">
			<div class="col-lg-9 col-md-8 align-self-center">
				<h3>Interested in finding out more? Why not see it in action...</h3>
			</div>
			<div class="col-lg-3 col-md-4 align-self-center text-right mt-4 mt-md-0">
				<a class="readmore white" data-toggle="modal" data-target="#demo_form_modal" href="javascript:void(0);">Request a demo</a>
			</div>
		</div>
	</div>
</section>
