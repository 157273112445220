
<section id="slider" class="inner_bnr" style="background:url(assets/images/partners_bnr.jpg) no-repeat center center / cover;">
	<h2 class="upr">Testimonials</h2>
</section>

<section id="partner_cont">
	<div class="container">
		<div class="row">
		   <div class="col-lg-12">
				<div class="partner_bx">



<p><b><i>“It is my conclusion that TeamPlayerHR offers a proactive solution to retaining employees.  The questions were well thought out and I appreciate how quickly I can see the results.  I can see how TeamPlayerHR can be an effective tool in any hiring and retention program, saving money in the long run, as mobility is a great challenge in our organizations.”</i></b> — Dr. Alfredo Cuellar, Coordinator, CSU Fresno
</p><p><b><i>“It is always difficult to know for sure how a new employee will fit in with me and my staff.  Before you had to just go with your feelings, I really like how TeamPlayerHR offers a way to match new hires with our current working personalities.  It offers a more factual approach in the process.  We will have a TeamPlayerHR link on our website as a resource for our clients to use."</i></b> — Dr. Fred Mora, PsyD, President / CEO, The Human Resource Consortium Inc., Fresno, CA
</p><p><b><i>“Your program certainly has merit, and I'll keep it in mind as I work with clients as it might be a good retention tool for them.”</i></b> — Julie Sherriff, President Sherriff &amp; Associates, Inc., Kansas City, KS
</p><p><b><i>“The quiz was very interesting. I can see how it will match a candidate with an employer according to their work ethics and philosophical feelings.  Overall, a good way to match how candidates and employers approach problems and situations”</i></b> — David A. Van Cleave, Senior Writer-Editor, MITRE, Bedford, Mass
</p><p><b><i>“Overall, I think the tool could be a very helpful and cost-effective choice”</i></b> — Catherine Drogin, President, Silver Consumer Products Corporation, Brooklyn, NY


			</p>



				</div>
		   </div>
		</div>
	</div>
</section>
