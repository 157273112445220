
<section id="slider" class="inner_bnr" style="background:url(assets/images/contact_bnr.jpg) no-repeat center center / cover;">
	<h2 class="upr">News & Awards</h2>
</section>

<section id="news_cont">
	<div class="container">
		<div class="row">
		   <div class="col-lg-4 col-md-6" *ngFor="let item of news">
				<div class="news_bx">
					<figure>
						<img src="{{baseUrl+'storage/app/public/'+item.feature_image}}" />
					</figure>
					<div class="news_cont">
						<h4>{{item.title}}</h4>
						<!-- <p>{{item.description}}...</p> -->
						<a class="readmore mt-3" [routerLink]="['/news',item.slug]" routerLinkActive="router-link-active" >know more <i class="flaticon-right-arrow"></i><span></span></a>
					</div>
				</div>
		   </div>

		</div>
	</div>
</section>
