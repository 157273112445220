import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Globals } from '../global';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css']
})
export class HowItWorksComponent implements OnInit {
  title = 'TeamPlayerHR : How It Works';
  metadata:any;
  featureImage:any = 'assets/images/contact_bnr.jpg';
  content:any="";
  constructor(private titleService: Title,private metaTagService: Meta,private global: Globals, private api: ApiService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.setMeta();
    this.solution('22:22:21', '22:22:23');
  }
  setMeta(){
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Our goal is to help you quickly find employees who will meet your exact needs and stay with your organization in the long run. How can we do this? The answer is simple: The Career Matching Assessment Tool (Team Player Hr) and its proprietary technology 2' }
    );
    this.api.getMeta('how-it-works').subscribe((data: any[])=>{
      var res:any = data;
      this.metadata = res.data;
      this.featureImage = this.metadata.feature_image;
      this.content = this.metadata.content;
      this.titleService.setTitle(this.metadata.title);
      this.metaTagService.updateTag(
        { name: 'description', content: this.metadata.description }
      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: this.metadata.keyword }
      );
    });
  }

  solution(S:string, T:string) {
    var start = S.split(':');
    var end = T.split(':');

    var startSec = parseInt(start[2]);
    var startMin = parseInt(start[1]);
    var startHr = parseInt(start[0]);
    var endSec = parseInt(end[2]);
    var endMin = parseInt(end[1]);
    var endHr = parseInt(end[0]);

    var resSec = "";
    var resMin = "";
    var resHr = "";

    var arrData = [];
    var temp = "";
    var status = true;
    while(status){

      if(startSec == 60)
      {
        startSec = 0;
        startMin = startMin + 1;
        if(startMin == 60)
        {
          startMin = 0;
          startHr = startHr + 1;
          if(startHr == 24)
          {
            startHr = 0;
          }
        }
      }
      if(startSec < 10)
      {
        resSec = "0"+startSec.toString();
      }
      else
      {
        resSec = startSec.toString();
      }
      if(startMin < 10)
      {
        resMin = "0"+startMin.toString();
      }
      else
      {
        resMin = startMin.toString();
      }
      if(startHr < 10)
      {
        resHr = "0"+startHr.toString();
      }
      else
      {
        resHr = startHr.toString();
      }
      var res = resHr+":"+resMin+":"+resSec;
      var countArr = [0,0,0,0,0,0,0,0,0,0];
      var totalDigits = 0;

      for(var i = 0; i < res.length; i++)
      {
        if(i != 2 && i != 5)
        {
          let val = parseInt(res[i]);
          countArr[val]++;
        }
      }

      for(let j =0; j < countArr.length; j++)
      {
        if(countArr[j] > 0)
        {
          totalDigits++;
        }
      }

      if(res > T)
      {
        status = false;
      }
      else
      {
        if(totalDigits == 2 || totalDigits == 1)
        {
          arrData.push(res);
        }
      }
      startSec = startSec + 1;
    }
    console.log(arrData);

  }

}
