import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../global';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-start-test',
  templateUrl: './start-test.component.html',
  styleUrls: ['./start-test.component.css']
})
export class StartTestComponent implements OnInit { 
  data:any;
  constructor(private global: Globals,private route:ActivatedRoute, private router: Router,private api: ApiService,private toastr: ToastrService) { }
questionList:any = [];
sections:any;
  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('test_param'));
    //console.log(this.data);
	 this.api.getQuestion().subscribe((data: any[])=>{
      let res:any = data;
      this.questionList = res.data.questions;
      this.sections = res.data.sections;
      this.setScore();
    });
	
  }
  
  setScore()
  {
    var test_data = JSON.parse(localStorage.getItem('test_param'));
    //console.log(test_data);

    var param = {test:this.sections[0].questionnaire,group_id:test_data.group_id};
    this.api.setScore(param).subscribe((data: any[])=>{
      var res:any = data;
      //console.log(res.data);
      // setTimeout(function () {
          // window.location.replace('/');
      // }, 3000);
    });
  }

}
