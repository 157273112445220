import { Component, OnInit, Input ,ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../global';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
declare var $: any;
declare var braintree:any;
declare var require: any;
@Component({
  selector: 'app-appsub-payment-button',
  templateUrl: './appsub-payment-button.component.html',
  styleUrls: ['./appsub-payment-button.component.css']
})
export class AppsubPaymentButtonComponent implements OnInit {
  @Input() plan:any;
  @Input() groupID:any;

  client = require('braintree-web/client');
  hostedFields = require('braintree-web/hosted-fields');
  baseUrl:string = "https://admin.teamplayerhr.com/";
  paymentDetail:any;
  paypal:any;
  amount:any;
  plan_id:any;
  vdata:any= {"id":1,"vat":""};
  vatpricef:any;
  amountwithvat:any;
  taxprice:any;
  showPButton=false; 
  data:any = [{"id":1,"title":"Full Questioneir","number_survay":1,"amount":"240.00"}];
  public payPalConfig?: IPayPalConfig;
    constructor(private global: Globals,private route:ActivatedRoute,private api: ApiService,private toastr: ToastrService,private router: Router) {
      this.baseUrl = this.global.APIURL;
    }

  ngOnInit(): void {
    this.amount = this.plan.amount;
    this.plan_id = this.plan.id;
	this.api.getVATPrice().subscribe((vdata: any[])=>{
	  let vres:any = vdata;
      this.vdata = vres.data;
	this.initConfig(this.vdata.vat);
	})
  }

  onPaymentStatus(res:any)
  {
    //console.log('--Payment Status--');
    //console.log(res);
    if(res.status != undefined && res.status == "COMPLETED")
    {
      let param = {id:this.plan.id,data:JSON.stringify(res)};
      this.api.updateAppSubPayment(param).subscribe((data: any[])=>{
        let res:any = data;
        this.toastr.success(res.message);
        $("#mainContainer").hide();
        $("#paymentSuccess").show();
		setTimeout(()=>{
          this.router.navigateByUrl('user/app-questionnaire');
        }, 3000);
      })
    }
    else
    {
      $("#mainContainer").hide();
      $("#paymentError").show();
    }

  }
  
    private initConfig(vatprice): void {
	  //console.log(plandata);
	  this.showPButton=true;
	  this.vatpricef = vatprice;
	  this.taxprice= parseFloat(this.amount)*parseInt(vatprice)/100;
    var gamountwithvat = parseFloat(this.amount)+ parseFloat(this.taxprice);
    this.amountwithvat = gamountwithvat.toFixed(2);
      this.payPalConfig = {
      currency: 'EUR',
      clientId: this.global.paypalConfig.env=='production' ? this.global.paypalConfig.client.production : this.global.paypalConfig.client.sandbox,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: this.amountwithvat,
              breakdown: {
                item_total: {
                  currency_code: 'EUR',
                  value: this.amount
                },
				tax_total: {
                  currency_code: 'EUR',
                  value: this.taxprice
                }
              }
            },
            items: [
              {
                name: 'Subscription to access app features',
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'EUR',
                  value: this.amount,
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        //this.showSuccess = true;
		this.onPaymentStatus(data);
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        console.log('OnError', err);
		$("#mainContainer").hide();
      $("#paymentError").show();
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };
    }

}
