import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '../global';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.css']
})
export class PurchaseComponent implements OnInit {

  showButton:boolean = false;
  user:any;
  paymentDetail:any;
  FullQuestionnairedata:any;
  CompanySubscriptiondata:any;
  AppQuestionnairedata:any;
  CompanyAppSubscriptionsdata:any;
  mobilemsg:5;
  paypal:any;
  plan:any;
  data:any = [{"id":1,"name":"1 Survey","number_survay":"1","amount":"240.00","created_at":"2020-09-16T18:57:40.000000Z","created_by":"1","updated_at":"2020-09-16T18:57:40.000000Z","updated_by":"1"},{"id":2,"name":"5 Survey","number_survay":"5","amount":"1200.00","created_at":"2020-09-16T18:57:40.000000Z","created_by":"1","updated_at":"2020-09-16T18:57:40.000000Z","updated_by":"1"},{"id":3,"name":"15 Survey","number_survay":"15","amount":"3600.00","created_at":"2020-09-16T18:57:40.000000Z","created_by":"1","updated_at":"2020-09-16T18:57:40.000000Z","updated_by":"1"},{"id":4,"name":"25 Survey","number_survay":"25","amount":"6000.00","created_at":"2020-09-16T18:57:40.000000Z","created_by":"1","updated_at":"2020-09-16T18:57:40.000000Z","updated_by":"1"}];
  dataApp:any = [];
  dataAppSub:any = [];
  constructor(private global: Globals,private route:ActivatedRoute,private api: ApiService,private toastr: ToastrService) {
    //console.log(this.global.user);
    this.user = this.global.user;
   }

  showLoginMsg(){
    this.toastr.error('Please login to purchase questionnaire.','')
  }
  showMobleMsg(){
    this.toastr.error('Please purchase from Mobile App.','')
  }

  ngOnInit(): void {
	  this.mobilemsg;
	  this.api.getMeta('full-questionnaire').subscribe((data: any[])=>{
      var res:any = data;
      this.FullQuestionnairedata = res.data.content;
      
    });
	this.api.getMeta('company-subscription').subscribe((data: any[])=>{
      var res:any = data;
      this.CompanySubscriptiondata = res.data.content;
      
    });
	this.api.getMeta('app-based-questionnaire').subscribe((data: any[])=>{
      var res:any = data;
      this.AppQuestionnairedata = res.data.content;
      
    });
	this.api.getMeta('company-based-app-subscriptions').subscribe((data: any[])=>{
      var res:any = data;
      this.CompanyAppSubscriptionsdata = res.data.content;
      
    });

    this.api.getPlans().subscribe((data: any[])=>{
      let res:any = data;
      this.data = res.data;
    });
    this.api.getAppPlan().subscribe((data: any[])=>{
      let res1:any = data;
      this.dataApp = res1.data;
    });
    this.api.getAppSubscriptionPlan().subscribe((data: any[])=>{
		
      let res2:any = data;
      this.dataAppSub = res2.data;
    });
    this.api.getAppPPCPlan().subscribe((data: any[])=>{
      let res3:any = data;
      this.dataAppSub = res3.data;
    });
  }
  
  showgetAppSubscription(){
    this.api.getAppSubscriptionPlan().subscribe((data: any[])=>{
		
      let res2:any = data;
      this.dataAppSub = res2.data;
    });
  }

  renderButton(plan:any)
  {
    this.showButton = false;
    setTimeout(()=>{
      this.plan = plan;
      this.showButton = true;
    }, 10);
  }

  updatePayment(id:any,res:any){
    let param = {id:id,data:JSON.stringify(res)};

    this.api.updatePayment(param).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success(res.message);
      $("#mainContainer").hide();
      $("#paymentSuccess").show();
    })
  }

}
