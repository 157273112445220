import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../global';
import { DemoapiService } from '../../demoapi.service';
import { ToastrService } from 'ngx-toastr';
import { TimerPipe } from '../../timer.pipe';
import { interval } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";

declare var $: any;
@Component({
  selector: 'app-demo-question',
  templateUrl: './demo-question.component.html',
  styleUrls: ['./demo-question.component.css']
})
export class DemoQuestionComponent implements OnInit {
  constructor(private global: Globals,private route:ActivatedRoute, private router: Router,private api: DemoapiService,private toastr: ToastrService,private spinner: NgxSpinnerService) { }
  auth:any;
  showSectionIntro:boolean = false;
  showCompleted:boolean = false;
  activeSection:any = {name:"",detail:""};
  sections:any;
  showQuestion:boolean = false;
  questionList:any = [];
  answerList:any = [];
  activeQuestion:any = {
    id: '',
    question: "",
    minanswers: "",
    maxanswers: "",
    timelimit: "",
    employerquestion: "",
    savenegativeanswers: "",
    answers: [{
      answer_id: "",
      questionid: "",
      answer: "YES",
      sortorder: 0,
      image: "",
    }],
    answer_given:""
  }
  timeRemaining:any = 0;
  message:any="";


  activeQuestionIndex:any = 0;

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.auth = this.global.getUser();

    this.api.getQuestion().subscribe((data: any[])=>{
      let res:any = data;
      this.questionList = res.data.questions;

      this.sections = res.data.sections;
      if(this.questionList.length > 0)
      {
        if(this.questionList[0].answer_given == "")
        {
          this.showSectionIntro = true;
          this.showQuestion = false;
          this.activeSection = res.data.sections[0];
        }
        else
        {
          var i = 0;
          for(i = 0; i < this.questionList.length; i++)
          {
            if(this.questionList[i].answer_saved == false)
            {
              this.showSectionIntro = false;
              this.showQuestion = true;
              this.activeQuestionIndex = i;
              for(var j = 0; j < this.sections.length; j++)
              {
                if(this.questionList[i].sectionid == this.sections[j].id)
                {
                  this.activeSection = this.sections[j];
                  break;
                }
              }
              this.startQuestion();
              break;
            }
          }

          if(i == this.questionList.length)
          {
            this.showSectionIntro = true;
            this.showCompleted = true;
            this.showQuestion = false;
            this.activeSection.name = "Completed";
            this.activeSection.detail = "You have completed questionare.";
            this.setScore();
          }

        }
      }

      // for(var i = 0; i < this.questionList.length; i++)
      // {
      //   if(this.questionList[i].maxanswers == 1)
      //   {
      //     this.questionList[i].answer_given = this.questionList[i].answer_given+"";
      //   }
      // }
    });
  }

  setScore()
  {
    var test_data = JSON.parse(localStorage.getItem('demo_test_param'));
    console.log(test_data);

    var param = { test:this.sections[0].questionnaire, group_id:test_data.group_id, completed: true };
    this.api.setScore(param).subscribe((data: any[])=>{
      var res:any = data;
      console.log(res.data);
    });
  }

  startQuestion()
  {
    if(this.activeSection.id == this.questionList[this.activeQuestionIndex].sectionid)
    {
      this.showSectionIntro = false;
      this.showQuestion = true;
      this.activeQuestion = this.questionList[this.activeQuestionIndex];
      this.timeRemaining = this.activeQuestion.timelimit;
        interval(1000).subscribe(x => {
          if(this.timeRemaining == 0)
          {
            this.nextQuestion('timeup');
          }
          else
          {
            this.timeRemaining--;
          }
        });
    }

  }

  saveAnswer(param:any)
  {
    this.spinner.show();
    this.api.saveAnswer(param).subscribe((data: any[])=>{
      let res:any = data;
      //console.log(res);
      this.spinner.hide();
      window.location.reload();
    });
  }

  nextQuestion(qtype:any){
    var previousQuestion =  this.questionList[this.activeQuestionIndex];

    if(qtype == 'save')
    {
      var answer_given = previousQuestion.answer_given;
      if(previousQuestion.minanswers > 1)
      {
        var answer_count = 0;
        for(var i=0; i < answer_given.length; i++)
        {
          if(answer_given[i].status == true)
          {
            answer_count++;
          }
        }

        if(answer_count < previousQuestion.minanswers)
        {
          this.toastr.show("Please select minimum "+previousQuestion.minanswers+" options");
          return;
        }
        if(answer_count > previousQuestion.maxanswers)
        {
          this.toastr.show("You can select maximum "+previousQuestion.maxanswers+" options");
          return;
        }
      }
      else if(previousQuestion.minanswers == 1 && (answer_given == '' || answer_given == null))
      {
        this.toastr.show("Please select answer");
      }
    }
    this.activeQuestionIndex++;

    if(this.activeQuestionIndex == this.questionList.length)
    {
      this.activeSection.name = "Completed";
      this.activeSection.detail = "Congratulations, you have completed your survey.";
      this.showSectionIntro = true;
      this.showCompleted = true;
      this.showQuestion = false;

      var dataAnswer = {question_id:previousQuestion.id,answer_given:previousQuestion.answer_given,maxanswers:previousQuestion.maxanswers};
      this.saveAnswer(dataAnswer);

    }
    else
    {
      if(this.questionList[this.activeQuestionIndex] != undefined)
      {
        if(this.activeSection.id != this.questionList[this.activeQuestionIndex].sectionid)
        {
              this.showSectionIntro = true;
              this.showQuestion = false;
              for(var i = 0; i < this.sections.length; i++)
              {
                if(this.questionList[this.activeQuestionIndex].sectionid == this.sections[i].id)
                {
                  this.activeSection = this.sections[i];
                  break;
                }
              }
        }

        var dataAnswer = {question_id:previousQuestion.id,answer_given:previousQuestion.answer_given,maxanswers:previousQuestion.maxanswers};

          if(previousQuestion.answer_given != "")
          {
            this.saveAnswer(dataAnswer);
          }

          //this.activeQuestion = this.questionList[this.activeQuestionIndex];
          //this.timeRemaining = this.activeQuestion.timelimit;

      }
    }
    //console.log(this.activeQuestionIndex,this.activeQuestion.answer_given,this.activeQuestion);
  }

  previousQuestion(){
    this.activeQuestionIndex--;
    if(this.questionList[this.activeQuestionIndex] != undefined)
    {
      this.activeQuestion = this.questionList[this.activeQuestionIndex];
    }
  }

}
