
<section id="slider" class="inner_bnr" style="background:url(assets/images/signup_bnr.jpg) no-repeat center center / cover;">
	<h2 class="upr">Sign UP</h2>
</section>

<section id="signup_cont">
	<div class="container">
		<div class="row">
			<div class="col-lg-8 offset-lg-2 col-md-12">
				<div class="signup_form">
					<div class="signup_form">
						<nav>
						<div class="nav nav-tabs" id="nav-tab" role="tablist">
						<a class="nav-item nav-link" [ngClass]="{'active': invitetype=='individual'}" id="job_seekers-tab" data-toggle="tab" href="#job_seekers" role="tab" aria-controls="job_seekers" aria-selected="true">Individual</a>
						<a class="nav-item nav-link" [ngClass]="{'active': invitetype=='organization'}" id="organization-tab" data-toggle="tab" href="#organization" role="tab" aria-controls="organization" aria-selected="false">Organization</a>
						</div>
						</nav>
						<div class="tab-content" id="nav-tabContent">
						<div class="tab-pane fade" [ngClass]="{'active show': invitetype=='individual'}" id="job_seekers" role="tabpanel" aria-labelledby="job_seekers-tab">
							<form [formGroup]="signupForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
								<div class="form-group">
									<label>First Name <span class="astik">*</span></label>
									<input type="text" formControlName="first_name"  class="form-control"  />
									<span class="error" *ngIf="error.first_name.length > 0">
                    {{error.first_name}}
                  </span>
								</div>
								<div class="form-group">
									<label>Last Name <span class="astik">*</span></label>
                  <input type="text" formControlName="last_name"  class="form-control"  />
                  <span class="error" *ngIf="error.last_name.length > 0">
                    {{error.last_name}}
                  </span>
								</div>
								<div class="form-group">
									<label>Title</label>
									<input type="text" formControlName="title"  class="form-control" />
								</div>
								<div class="form-group">
									<label>Phone <span class="astik">*</span></label>
									<br>
									 <!--<ngx-intl-tel-input formControlName="phone" class="form-control"></ngx-intl-tel-input>-->
                 <input type="text" ng2TelInput [ng2TelInputOptions]="{initialCountry: 'gb'}"
                  (hasError)="hasError($event)"
                  (ng2TelOutput)="getNumber($event)"
                  (intlTelInputObject)="telInputObject($event)"
                  (countryChange)="onCountryChange($event)" formControlName="phone"  class="form-control"  />
                  <span class="error" *ngIf="error.phone.length > 0">
                    {{error.phone}}
                  </span>
								</div>

								<div class="form-group">
									<label>Country <span class="astik">*</span></label>
                  <ng-select2 (ngModelChange)="getStateList()" formControlName="country" style="width: 100%;" [data]="countryList" [placeholder]="'Select Country'">
                  </ng-select2>

                  <span class="error" *ngIf="error.country.length > 0">
                    {{error.country}}
                  </span>
                </div>
                <div class="form-group">
                  <label>Address Line 1 <span class="astik">*</span></label>
                  <input ngx-google-places-autocomplete class="form-control" formControlName="address_line_1"  #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>
                  
                  <span class="error" *ngIf="error.address_line_1.length > 0">
                    {{error.address_line_1}}
                  </span>
								</div>
								<div class="form-group">
									<label>Address Line 2</label>
									<input type="text" formControlName="address_line_2"  class="form-control" />
								</div>
								<div class="form-group">
									<label >State <span class="astik">*</span></label>
								<!--	<label *ngIf="signupForm.value.country==232">State/City <span class="astik">*</span></label>
                  <ng-select2 (ngModelChange)="getCityList()" formControlName="state" style="width: 100%;" [data]="stateList" [placeholder]="'Select State'">
                  </ng-select2>-->
                  <input type="text" formControlName="state"  class="form-control"  />
                  <span class="error" *ngIf="error.state.length > 0">
                    {{error.state}}
                  </span>
								</div>
								<div class="form-group" >
                  <label >City <span class="astik"></span></label>
                  <input type="text" formControlName="city"  class="form-control"  />
                  <!--<ng-select2 formControlName="city" style="width: 100%;" [data]="cityList" [placeholder]="'Select City'">
                  </ng-select2>-->

								</div>

								<div class="form-group">
									<label>ZIP / POST CODE <span class="astik">*</span></label>
                  <input type="text" formControlName="zip"  class="form-control"  />
                  <span class="error" *ngIf="error.zip.length > 0">
                    {{error.zip}}
                  </span>
								</div>
                <div class="form-group">
									<label>Resume / CV <span class="astik">*</span></label>
									<input type="hidden" formControlName="cv" [(ngModel)]="fileName"  value="" class="form-control"  />
                  <input class="form-control" type="file" id="file" (change)="handleFileInput($event.target.files)" />
                  <span class="error" *ngIf="error.cv.length > 0">
                    {{error.cv}}
                  </span>
                </div>
                <div class="form-group">
									<label>Sector <span class="astik">*</span></label>
                  <ng-select2 formControlName="sector" style="width: 100%;" [data]="sectorList" [placeholder]="'Select Sector'">
                  </ng-select2>

                  <span class="error" *ngIf="error.sector.length > 0">
                    {{error.sector}}
                  </span>
                </div>
                <div class="form-group">
									<label>Occupation <span class="astik">*</span></label>
                  <ng-select2 formControlName="occupation" style="width: 100%;" [data]="occupationList" [placeholder]="'Select Occupation'">
                  </ng-select2>

                  <span class="error" *ngIf="error.occupation.length > 0">
                    {{error.occupation}}
                  </span>
								</div>

								<div class="form-group">
									<label>Login Email <span class="astik">*</span></label>
                  <input type="text" formControlName="email" [ngModel]='email' class="form-control"  />
                  <span class="error" *ngIf="error.email.length > 0">
                    {{error.email}}
                  </span>
								</div>
								<div class="form-group">
									<label>Password <span class="astik">*</span></label>
                  <input type="password" formControlName="password"  class="form-control"  />
                  <span class="error" *ngIf="error.password.length > 0">
                    {{error.password}}
                  </span>
								</div>
								<div class="form-group">
									<label>Verify Password <span class="astik">*</span></label>
									<input type="password" formControlName="confirm_password"  class="form-control"  />
                  <span class="error" *ngIf="error.confirm_password.length > 0">
                    {{error.confirm_password}}
                  </span>
                </div>
								<div class="form-group">
									<label class="cstm_chk">Agree to <a [routerLink]="['/terms-and-conditions']" routerLinkActive="router-link-active"  href="terms-and-conditions" target="_blank">Terms & Conditions</a>
										<input type="checkbox" formControlName="agreeTerms" />
										<span class="checkmark"></span>
									</label>
                  <span class="error" *ngIf="error.agreeTerms.length > 0">
                    {{error.agreeTerms}}
                  </span>
								</div>
								<div class="form-group">
									<label class="cstm_chk">Agree to <a [routerLink]="['/privacy-policy']" routerLinkActive="router-link-active"  href="privacy-policy" target="_blank">Privacy Policy</a>
										<input type="checkbox" formControlName="agreePrivacy" />
										<span class="checkmark"></span>
									</label>
                  <span class="error" *ngIf="error.agreePrivacy.length > 0">
                    {{error.agreePrivacy}}
                  </span>
								</div>
								<div class="clearfix"></div>
								<div class="form-group subm_btn mt-3">
									<button type="submit" class="theme-btn" [disabled]="submitting">Submit</button>
								</div>
              </form>
            </div>


						<div class="tab-pane fade" [ngClass]="{'active show': invitetype=='organization'}" id="organization" role="tabpanel" aria-labelledby="organization-tab">
							<form [formGroup]="signupOrgForm" (ngSubmit)="onSubmitOrg()">
								<div class="form-group">
									<label>First Name <span class="astik">*</span></label>
									<input type="text" formControlName="first_name"  class="form-control"  />
									<span class="error" *ngIf="errorOrg.first_name.length > 0">
                    {{errorOrg.first_name}}
                  </span>
								</div>
								<div class="form-group">
									<label>Last Name <span class="astik">*</span></label>
                  <input type="text" formControlName="last_name"  class="form-control"  />
                  <span class="error" *ngIf="errorOrg.last_name.length > 0">
                    {{errorOrg.last_name}}
                  </span>
								</div>
								<div class="form-group">
									<label>Title</label>
									<input type="text" formControlName="title"  class="form-control" />
								</div>
								<div class="form-group">
									<label>Phone <span class="astik">*</span></label>
                  <input type="text" ng2TelInput [ng2TelInputOptions]="{initialCountry: 'gb'}"
  (hasError)="hasError($event)"
  (ng2TelOutput)="getOrgNumber($event)"
  (intlTelInputObject)="telInputObject($event)"
  (countryChange)="onCountryChange($event)" formControlName="phone"  class="form-control"  />
                  <span class="error" *ngIf="errorOrg.phone.length > 0">
                    {{errorOrg.phone}}
                  </span>
                </div>
                <div class="form-group">
									<label>Sector <span class="astik">*</span></label>
									<ng-select2 formControlName="sector" style="width: 100%;" [data]="sectorList" [placeholder]="'Select Sector'">
                  </ng-select2>
                  <span class="error" *ngIf="errorOrg.sector.length > 0">
                    {{errorOrg.sector}}
                  </span>
                </div>

								
								<div class="form-group">
									<label>Organization Name <span class="astik">*</span></label>
                  <input type="text" formControlName="organization_name"  class="form-control" />
                  <span class="error" *ngIf="errorOrg.organization_name.length > 0">
                    {{errorOrg.organization_name}}
                  </span>
								</div>
                <div class="form-group">
									<label>Your Role <span class="astik">*</span></label>
                  <select formControlName="user_role" class="form-control">
                    <option value="1">CEO</option>
                    <option value="2">HR Manager</option>
                    <option value="3">HR Consultant</option>
                    <option value="4">HR Generalist</option>
                    <option value="5">Leader</option>
                    <option value="6">Other</option>
                  </select>
                  <span class="error" *ngIf="errorOrg.user_role.length > 0">
                    {{errorOrg.user_role}}
                  </span>
								</div>
                <div class="form-group">
									<label>Number Of Employees <span class="astik">*</span></label>
                  <select formControlName="no_of_employees" class="form-control">
                    <option value="1-20">1-20</option>
                    <option value="21-80">21-80</option>
                    <option value="81-500">81-500</option>
                    <option value="+500">+500</option>
                  </select>
                  <span class="error" *ngIf="errorOrg.no_of_employees.length > 0">
                    {{errorOrg.no_of_employees}}
                  </span>
								</div>

								<div class="form-group">
									<label>Country <span class="astik">*</span></label>
                  <ng-select2 (ngModelChange)="getStateList2()" formControlName="country" style="width: 100%;" [data]="countryList" [placeholder]="'Select Country'">
                  </ng-select2>

                  <span class="error" *ngIf="errorOrg.country.length > 0">
                    {{errorOrg.country}}
                  </span>
                </div>
                <div class="form-group">
                  <label>Address Line 1 <span class="astik">*</span></label>
                  <input ngx-google-places-autocomplete class="form-control" formControlName="address_line_1"  #placesRef="ngx-places" (onAddressChange)="handleAddressOrgChange($event)"/>
                  
                  
                  <span class="error" *ngIf="errorOrg.address_line_1.length > 0">
                    {{errorOrg.address_line_1}}
                  </span>
								</div>
								<div class="form-group">
									<label>Address Line 2</label>
									<input type="text" formControlName="address_line_2"  class="form-control" />
								</div>
								<div class="form-group">
                  
									<label >State <span class="astik"></span></label>
									<!--<label *ngIf="signupOrgForm.value.country==232">State / City <span class="astik"></span></label>
                  <ng-select2 (ngModelChange)="getCityList2()" formControlName="state" style="width: 100%;" [data]="stateORGList" [placeholder]="'Select State'">
                  </ng-select2>-->
                  <input type="text" formControlName="state"  class="form-control"  />
                  <span class="error" *ngIf="errorOrg.state.length > 0">
                    {{errorOrg.state}}
                  </span>
								</div>
								<div class="form-group" >
									<label>City <span class="astik"></span></label>
                  <!--<ng-select2 formControlName="city" style="width: 100%;" [data]="cityORGList" [placeholder]="'Select City'">
                  </ng-select2>-->
                  <input type="text" formControlName="city"  class="form-control"  />
                  <span class="error" *ngIf="errorOrg.city.length > 0">
                    {{errorOrg.city}}
                  </span>
								</div>

								<div class="form-group">
									<label>ZIP / POST CODE <span class="astik">*</span></label>
                  <input type="text" formControlName="zip"  class="form-control"  />
                  <span class="error" *ngIf="errorOrg.zip.length > 0">
                    {{errorOrg.zip}}
                  </span>
								</div>
								<div class="form-group">
									<label>Login Email <span class="astik">*</span></label>
                  <input type="text" formControlName="email"  class="form-control"  />
                  <span class="error" *ngIf="errorOrg.email.length > 0">
                    {{errorOrg.email}}
                  </span>
								</div>
								<div class="form-group">
									<label>Password <span class="astik">*</span></label>
                  <input type="password" formControlName="password"  class="form-control"  />
                  <span class="error" *ngIf="errorOrg.password.length > 0">
                    {{errorOrg.password}}
                  </span>
								</div>
								<div class="form-group">
									<label>Verify Password <span class="astik">*</span></label>
                  <input type="password" formControlName="confirm_password"  class="form-control"  />
                  <span class="error" *ngIf="errorOrg.confirm_password.length > 0">
                    {{errorOrg.confirm_password}}
                  </span>
								</div>
								<div class="form-group">
									<label class="cstm_chk">Agree to <a [routerLink]="['/terms-and-conditions']" routerLinkActive="router-link-active"  href="terms-and-conditions" target="_blank">Terms & Conditions</a>
										<input type="checkbox" formControlName="agreeTerms" />
										<span class="checkmark"></span>
                  </label>
                  <span class="error" *ngIf="errorOrg.agreeTerms.length > 0">
                    {{errorOrg.agreeTerms}}
                  </span>
								</div>
								<div class="form-group">
									<label class="cstm_chk">Agree to <a [routerLink]="['/privacy-policy']" routerLinkActive="router-link-active"  href="privacy-policy" target="_blank">Privacy Policy</a>
										<input type="checkbox" formControlName="agreePrivacy" />
										<span class="checkmark"></span>
                  </label>
                  <span class="error" *ngIf="errorOrg.agreePrivacy.length > 0">
                    {{errorOrg.agreePrivacy}}
                  </span>
								</div>
								<div class="clearfix"></div>
								<div class="form-group subm_btn mt-3">
									<button type="submit" class="theme-btn" [disabled]="submitting">Submit</button>
									<ngx-spinner>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
								</div>
							  </form>
						</div>
						</div>
						</div>

				</div>
			</div>
		</div>
	</div>
</section>
