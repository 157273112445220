<ngx-spinner>
  <p style="font-size: 20px; color: white">Saving answer...</p>
</ngx-spinner>
<div class="row">
    <div class="col-md-12">
      <section *ngIf="showSectionIntro == true">
        <h4>{{activeSection.name}}</h4>
        <p>{{activeSection.detail}}</p>
        <a *ngIf="showCompleted == false" (click)="startQuestion()" href="javascript:;" class="btn btn-xs btn-info">Begin Questionaire</a>
      </section>
      <section id="signup_cont" *ngIf="showQuestion == true">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-12">
              <div class="quizContainer container-fluid well well-lg">
                <div id="quiz1">
                  <h4 id='timer'>{{timeRemaining | timer}}</h4>
                </div>
                <h6>Section : {{activeQuestion.section_detail.name}}</h6>
                <h6>Question No : {{activeQuestion.subpart}}</h6>
                <div class="question mb-20" *ngIf="auth.role == 1 || auth.role == 3" [innerHtml]="activeQuestion.employerquestion"></div>
                <div class="question mb-20" *ngIf="auth.role == 2" [innerHtml]="activeQuestion.question"></div>
                <div class="clearfix"></div>
                <!-- <div class="quizMessage">{{message}}</div> -->
                <div class="clearfix"></div>
                <ul class="choiceList" *ngIf="activeQuestion.maxanswers == 1">
                  <li class="cstm_rdo" *ngFor="let item of activeQuestion.answers; let i = index;">
                    <input type="radio" [(ngModel)]="activeQuestion.answer_given" class="radio-inline" value="{{item.answer_id}}" name="dynradio">
                    {{item.answer}}
                    <span class="checkmark"></span>
                  </li>
                </ul>
                <ul class="choiceList" *ngIf="activeQuestion.maxanswers > 1">
                  <li class="cstm_rdo" *ngFor="let item of activeQuestion.answers; let i = index;">
                    <input type="checkbox" [(ngModel)]="activeQuestion.answer_given[i].status" class="radio-inline" value="{{item.answer}}" name="dynradio[]">
                    {{item.answer}}
                    <img *ngIf="item.image != ''" src="https://teamplayerhr.com/admin/assets/images/{{item.image}}" />
                    <span class="checkboxmark"></span>

                  </li>
                </ul>
                <!-- <button (click)="previousQuestion()" *ngIf="activeQuestionIndex > 0" class="preButton mr-2">Previous Question</button> -->
                <button (click)="nextQuestion('save')" *ngIf="activeQuestionIndex < ( questionList.length -1 )" class="nextButton">Next Question</button>
                <button (click)="nextQuestion('save')" *ngIf="activeQuestionIndex == ( questionList.length -1 )" class="nextButton">Save</button>
                <div class="clearfix"></div>
                <div class="result"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
