<div class="card" style="padding: 10px;" >
    <h4>Company Users</h4>
   <div class="row mb-3">
      <div class="col-md-12">
        <input type="text" [(ngModel)]="searchName" name="searchName" class="form-control" placeholder="search name"/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="table_scroll">
          <table class="table table-responsive table-striped table-bordered table-hover w-100" style="display: inline-table;">
            <thead class="thead-dark">
                <tr>
                    <th>Name</th>
                    <th>Company Name</th>
                    <th>Email</th>
                    <th>No. of Questionnaire</th>
                    <th>No. of Invitee</th>
                    <th>Option</th>
                </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of userlist | filter:{key:'name',value:searchName}">
                  <td>
                      <!-- <a *ngIf="item.survey_progress == 1" (click)="setResultParams(item)" href="javascript:;" >{{item.user_name}}</a> -->
                      {{item.name}}
                    </td>
                    <td>
                     {{item.organization_name}}
                    </td>
                    <td>
                      <a href="mailto:{{item.email}}">{{item.email}}</a>

                    </td>
                 <td>
                      {{item.totalpartcipant}}
                    </td>
                    <td>
                      {{item.totalinvitee}}
                    </td>
                  <td>
                    <button type="button" (click)="assignFreeModal(item)" class="btn btn-info btn-sm" >Assign Free trial</button>
                    </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>


<!-- Participent List Modal -->
<div class="modal fade" id="assignModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-md" style="width: 100%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Assign Free Trial</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form action="">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label>No. of Questionnaire</label>
                <input [(ngModel)]="no_of_participant" name="no_of_participant" min="0" class="form-control" type="number">
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <label>No. of Invitee</label>
                <input [(ngModel)]="no_of_invitee" name="no_of_invitee" min="0" class="form-control" type="number">
              </div>
            </div>
            <div class="col-lg-12">
              <button type="button" class="btn btn-sm" (click)="assignFreetrial(currentCuser)">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-spinner>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
