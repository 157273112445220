
<section id="slider" class="inner_bnr" style="background:url(assets/images/partners_bnr.jpg) no-repeat center center / cover;">
	<h2 class="upr">Terms & Conditions</h2>
</section>

<section id="partner_cont">
	<div class="container">
		<div class="row">
		   <div class="col-lg-12">
				<div class="partner_bx">
					<div class="row">
                  <div class="col-md-12">
           <p>
  In order to use the TeamPlayerHR.com Site (Hereafter "Site"), you must register as a
  member of the Site and agree to be bound by these Terms &amp; Conditions / Privacy
  Policy (Hereafter "Agreement") stated below. By taking the questionnaire or otherwise
  using or accepting the Services, you agree to be bound by the terms, conditions
  and privacy policy and acknowledge that you have read and understand this
  Agreement.

  </p><p>
  As a registered member you agree that you shall only use TeamPlayerHR Services and
  Content in a manner that complies with all applicable laws in the jurisdictions
  in which you use the Services and Content, including, but not limited to,
  applicable restrictions concerning copyright and other intellectual property
  rights. Except as specifically authorized herein, you may not: (i) permit other
  individuals to use the Content and Services; (ii) modify, translate, distribute
  or create derivative works of the Content or the Services; (iii) copy or
  redistribute the Content; (iv) rent, lease or otherwise transfer rights to the
  Content or the Services; (v) remove any proprietary notices or labels on the
  Content or Service; and (vi) add to, alter, delete from, or otherwise modify the
  Content.

  </p><p>
  Career Matching Services, Inc. “CMSI” dba: HCRC.net (Hereafter CMSI) values and
  respects an individual's right to keep certain personal information private. The
  very nature of CMSI’s business dictates we collect and use personal information,
  which will enable CMSI to effectively deliver our Products and Services to our
  corporate customers. "Personal Information" may consist of demographic and/or
  "profile information" which may identify a specific individual with a minimal
  degree of effort. Demographic information includes name, address, city and other
  similar information. "profile information" refers to the output from the questionnaire
  conducted by CMSI which is composed of information relating to the way a person
  answers the questionnaire questions.

  </p><p>
  The privacy practices of this Agreement apply to our services available through
  the domain and sub-domains of www.TeamPlayerHR.com. By visiting the website and
  accepting these terms you agree to be bound by the terms and conditions of this
  Agreement. If you do not agree, you may not use or access our Site.

  </p><p>
  This Agreement describes the information which, as part of the normal operation
  of our Services, we collect from you and what may happen to the information. We
  have prepared a detailed policy because we believe you should know as much as
  possible about CMSI's collection, storage and use of your personal information
  so that you can make an informed decision regarding the use of our site.

  </p><p>
  Children: (persons under the age of 18) are not eligible to use the Site without
  supervision, and children are not permitted to submit any personal information.
  If you are under the age of 18, you may only use this Site in conjunction with
  and under the supervision of your parents or guardians. By using this Site, you
  represent and warrant that you have the right, authority, and capacity to enter
  into this Agreement and to abide by all of the Terms &amp; Conditions / Privacy
  Policy of this Agreement. Children under 13 are not permitted to use this
  website.

  </p><p>
  Non Commercial Use by Members: The Site is for Members only and may not be used
  in connection with any Illegal and/or unauthorized uses of the Site, including
  collecting usernames and/or email addresses of members by electronic or other
  means for the purpose of sending unsolicited email and unauthorized framing of
  or linking to the Site will be investigated, and appropriate legal action will
  be taken, including without limitation, civil, criminal, and injunctive redress.

  </p><p>
  Modifications to Service: This Agreement was last modified on September 1, 2007.
  At any time, and without prior notice, CMSI shall have the right, in its sole
  discretion, to modify, add or remove terms of this Agreement, without notifying
  our customers or Members of such modifications, additions or removals, and all
  such changes shall be effective immediately. You agree that CMSI shall not be
  liable to you or to any third party for any modification, suspension or
  discontinuance of the service.

  </p><p>
  Advertiser Links: This website contains links to Internet websites maintained by
  third parties. CMSI does not operate or control in any respect any information,
  products, or services on such third-party sites. Third-party links do not
  constitute any endorsement by CMSI and/or its affiliates. User assumes sole
  responsibility for use of these third-party links. The disclaimers set forth
  herein are equally applicable to such third-party sites.

  </p><p>
  Collection of Personal Information: Demographic Information will be collected
  from the forms contained on this website. All information not part of the
  "TeamPlayerHR” provided on this website will be recorded and stored together unless
  the question specifically states otherwise. The information will be stored in an
  online database, as from its collection point (an internet form). The
  information will be given to employers for the reasons that we discussed. The
  information may include, but is not limited to: Name (First &amp; Last), Postal
  Address, Email Address, and professional profile.

  </p><p>
  "TeamPlayerHR" Information refers to all information that is related to the questionnaire. It is
  broken down into four quadrants and scored for percentage.

  </p><p>
  Cookies: CMSI uses data collection devices such as "cookies" on certain pages of
  the Site to help analyze our web page flow, measure promotional effectiveness,
  and promote trust and safety. "Cookies" are small files placed on your hard
  drive that assist us in providing our Services. We offer certain features that
  are only available through the use of a "cookie". We also use cookies to allow
  you to enter your password less frequently during a session. Cookies can also
  help us provide information that is targeted to your interests. Most cookies are
  "session cookies," meaning that they are automatically deleted from your hard
  drive at the end of a session. You are always free to decline our cookies if
  your browser permits, although in that case you may not be able to use certain
  features on the Site and you may be required to reenter your password more
  frequently during a session.

  </p><p>
  Personal Information may be used for market research and the information may be
  distributed to outside entities as raw data. The results of any market studies
  may be available to outside entities.

  </p><p>
  Use of Personal Information: The information that you provided to this website
  will be used to analyze matching trends by studying the shifts in retention
  rates or to markets by a population identified by questionnaire. This will be done by
  combining the percent scores of the individuals that make up a match, based on
  questionnaire and profile information. The classification of a match or segment within
  a match will be based on the identification acquired. This will be done by
  combining the aggregate scores of the individuals that makeup a match, based on
  demographic and questionnaire information. CMSI may develop special sites in
  cooperation with other companies or work with affiliates to gain registrations
  for their sites. We may share your registration information (such as name and
  email address) with these companies.

  </p><p>
  The information will be used internally in order to calibrate and validate the
  internal hypothesis as related to matching process. Viewing data collected from
  other online questionnaires, and comparing them against the questionnaire information from the
  CMSI assessment can ascertain the internal validity of the assessment
  instrument.

  </p><p>
  The parties that may be exposed to the information are, but not limited to:
  -Statistical analysts -Marketing research companies -Direct Marketing Companies
  1:1 marketers, List management companies , Professional, Retail &amp; wholesale
  companies

  </p><p>
  This personal information and the questionnaire information may be sold or disclosed to
  outside entities for the purpose of sending marketing information.

  </p><p>
  Disclaimers of Warranties: The products, offerings, content and materials
  (including, without limitation, the fee-based products) on this website are
  provided "as is" and without warranties of any kind, either express or implied.
  CMSI disclaims all warranties, express or implied, including but not limited to
  warranties of title or implied warranties of merchantability, fitness for a
  particular purpose, title, security, accuracy or non-infringement. CMSI does not
  conduct criminal background checks on its members.

  </p><p>
  Neither CMSI, any of our affiliates, nor any of our or their respective
  licensors, licensees, service providers or suppliers warrant that this website
  or any function contained in this website will be uninterrupted or error-free,
  that defects will be corrected, or that this website or the servers that make
  this website available are free of viruses or other harmful components.
  Ultimately, you are solely responsible for maintaining the secrecy of your
  passwords and/or any account information. Please be careful and responsible
  whenever you're online. The few CMSI employees who have access to personal data
  are trained to comply with the above privacy policy and made aware of any
  updates or modifications.

  </p><p>
  Neither CMSI, any of our affiliates, nor any of our or their respective
  licensors, licensees, service providers or suppliers warrant or make any
  representations regarding the use or the results of the use of the products,
  offerings, content and materials (including, without limitation, the fee-based
  products) in this website in terms of their correctness, accuracy, reliability,
  or otherwise. Further, please note that no advice or information obtained by you
  from our personnel or through this website shall create any warranty not
  expressly provided for in this agreement.

  </p><p>
  Limitation of Liability: You expressly understand and agree that CMSI and our
  affiliates shall not be liable for any direct, indirect, incidental, special,
  consequential, exemplary or punitive damages, or any other damages whatsoever,
  including but not limited to, damages for loss of profits, goodwill, use, data
  or other intangible losses (even if we have been advised of the possibility of
  such damages), arising out of, or resulting from, (a) the use of or the
  inability to use this website (including, without limitation, the fee-based
  products); (b) the use of any content or other material (including, without
  limitation, the fee-based products) on this website or any website or websites
  linked to this website, (c) the cost of procurement of substitute goods and
  services resulting from any goods, data, information or services purchased or
  obtained or messages received or transactions entered into through or from this
  website (including, without limitation, the fee-based products); (d)
  unauthorized access to or alteration of your transmissions or data; (e)
  statements or conduct of any third party on our website (including, without
  limitation, the fee-based products); or (f) any other matter relating to this
  website. In no event shall our total liability to you for all damages, losses,
  and causes of action (whether in contract, tort (including, but not limited to,
  negligence), or otherwise) exceed the amount paid by you, if any, for accessing
  this website. If you are dissatisfied with any portion of our website, or with
  any of provision of this agreement, your sole and exclusive remedy is the
  discontinuation of your use of this website.

  </p><p>
  Indemnity: You agree to indemnify and hold CMSI, its subsidiaries, affiliates,
  officers, agents, and other partners and employees, harmless from any loss,
  liability, claim, or demand, including reasonable attorney's fees, made by any
  third party due to or arising out of your use of the Service in violation of
  this Agreement and/or arising from a breach of these Terms &amp; Conditions and/or
  any breach of your representations and warranties set forth above.

  </p><p>
  All questions relating to this website's validity, interpretations, performance
  and enforcement, including the Terms &amp; Conditions, shall be governed by and
  construed in accordance with the laws of the State of California.  Any action
  relating to this website's validity, interpretations, performance and
  enforcement, including the Terms &amp; Conditions, must be brought in the federal or
  state courts located in Fresno County, CA, and you irrevocably consent to the
  jurisdiction of such courts. Our failure to enforce your strict performance of
  any provision of these Terms &amp; Conditions will not constitute a waiver of our
  right to subsequently enforce such provision or any other provision of this
  policy. Any waiver, amendment or other modification of any provision of these
  Terms &amp; Conditions will be effective only if in writing and signed by an
  authorized representative of CMSI. CMSI will not be responsible or liable to you
  for any delay or failure to perform under this Agreement if such delay or
  failure results from fire, explosion, labor dispute, earthquake, casualty or
  accident, lack or failure of transportation facilities and/or services, lack or
  failure of telecommunications facilities and/or services including Internet
  services, epidemic, flood, drought, or by reason of war, civil commotion,
  blockade or embargo, act of God, any inability to obtain any requisite license,
  permit or authorization, or by reason of any law, proclamation, regulation,
  ordinance, demand or requirement of any government or by reason of any other
  cause whatsoever, whether similar or dissimilar to those enumerated, beyond the
  reasonable control of CMSI. Special Admonitions for International Use

  </p><p>
  Recognizing the global nature of the Internet, you agree to comply with all
  local rules regarding online conduct and acceptable content. Specifically, you
  agree to comply with all applicable laws regarding the transmission of technical
  data exported from the United States or the country in which you reside.

  </p><p>
  Miscellaneous Terms: All information presented by CMSI is for educational and
  matching purposes. The material is not intended to be a substitute for
  professional counseling. CMSI and the creators, producers, and distributors of
  the content disclaim any liability in connection with the advice,
  recommendations or products advertised on this site.

  </p><p>
  CMSI may assign our rights and obligations under this Agreement. This Agreement
  will inure to the benefit of our successors, assigns and licensees. The failure
  of either party to insist upon or enforce the strict performance of the other
  party with respect to any provision of this Agreement, or to exercise any right
  under this Agreement, will not be construed as a waiver or relinquishment to any
  extent of such party's right to assert or rely upon any such provision or right
  in that or any other instance; rather, the same will be and remain in full force
  and effect.

  </p><p>
  Entire Agreement: This Agreement represents the complete and entire agreement
  between CMSI and you and supersedes all prior and contemporaneous agreements of
  the parties relating to the subject matter hereof. If any provision of this
  Agreement is held invalid, the remainder of this Agreement shall continue in
  full force and effect. You acknowledge that you have read this Agreement and
  understand and agree to all of it in its entirety. You have independently
  evaluated the desirability of using TeamPlayerHR, and are not relying on any
  representation, guarantee, or statement other than as set forth in this
  Agreement.

  </p><p>
  We sincerely hope you enjoy your use of the TeamPlayerHR site. Please understand that
  these rules help make it possible to offer our users these Services.

  </p><p>
  Official correspondence must be sent via postal mail to: CMSI. Inc, Attn:
  Customer Service -2117 Selma St, Selma, CA 93662

  </p><p>
  IMPORTANT: These Terms &amp; Conditions / Privacy Policy of Service for CMSI
  Services ("Agreement") are a legal agreement between you and CMSI, Inc. By
  taking the questionnaire or otherwise using or accepting the Services,
  you agree to be bound by the terms of this Agreement. If you do not agree to the
  Terms of this Agreement, do not take the questionnaire or use these Services. You agree
  that your taking the questionnaire or use of the services acknowledges that you have
  read this Agreement, understand it, and agree to be bound by its Terms and
  Conditions.





        </p>
      </div>
					</div>
				</div>
		   </div>
		</div>
	</div>
</section>
