import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '../../global';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-survey-group',
  templateUrl: './survey-group.component.html',
  styleUrls: ['./survey-group.component.css']
})
export class SurveyGroupComponent implements OnInit {
  id:any;
  data:any;
  constructor(private global: Globals,private route:ActivatedRoute,private api: ApiService,private toastr: ToastrService) {
    
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id)
  }

}
