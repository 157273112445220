<div class="row">
    <div class="col-md-10">
        <h3>App Questionnaire</h3>
        <button *ngIf="showAddGroupBtn" data-target="#addGroupModal" data-toggle="modal" class="btn btn-info btn-sm"><i class="fa fa-plus"> Add Group</i></button>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <ul *ngIf="showAddGroupBtn == false">
			<li *ngFor="let item of data; let i = index">
                <a (click)="getDetails(item.id)" href="javascript:;" >({{i+1}}) Manage {{item.name}}</a>
            </li>
        </ul>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="card" style="padding: 10px;" *ngIf="dataDetail.survey_group != undefined">
            <h4>Questionnaire {{dataDetail.survey_group.name}}</h4>
            <h6>{{dataDetail.survey_group.max_size + 1 - dataDetail.survey_participants.length - dataDetail.survey_group.total_renewals }} questionnaires remaining</h6>
            <div class="input-group mb-3">
              <input type="hidden" [(ngModel)]="obInvite.group_id"/>
              <input type="text" class="form-control" [(ngModel)]="obInvite.email" placeholder="Invite Participant by Email or IM">
              <div class="input-group-append">
                <button class="btn btn-info btn-sm" (click)="sendInvite()" type="button">Invite</button>
              </div>
            </div>
            <div class="input-group mb-3">
              <div class="input-group-append">
                <button class="btn btn-info btn-sm" (click)="showInvite()" type="button">Show Invitees</button>&nbsp;
                <button class="btn btn-info btn-sm" (click)="openMultipleInvite()" type="button">Send Multiple Invitees</button>
              </div>
            </div>
        </div>
        <div class="card" style="padding: 10px;" *ngIf="dataDetail.is_admin == true">
            <h4>Manage Team</h4>
            <div class="row">
                <div class="col-md-12">
                    <input type="hidden" [(ngModel)]="obTeam.group_id"/>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" [(ngModel)]="obTeam.name" placeholder="Team Name">
                        <div class="input-group-append">
                          <button class="btn btn-info btn-sm" (click)="createTeam()" type="button">Create Team</button>
                        </div>
                    </div>
                    <div class="table_scroll">
                      <table class="table table-striped table-bordered table-hover">
                        <thead class="thead-dark">
                          <tr>
                            <th>Team Name</th>
                            <th>No Of Participants</th>
                            <th>Option</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of teamList">
                            <td>{{item.name}}</td>
                            <td>{{item.user_list.length}}</td>
                            <td><button (click)="showParticipent(item)" class="btn  btn-info btn-sm">Show</button></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                </div>
            </div>
        </div>
        <div class="card" style="padding: 10px;" *ngIf="dataDetail.survey_group != undefined">
            <h4>Participants</h4>
            <h6>There are {{dataDetail.survey_participants.length}} participants in this questionnaire group.</h6>
            <h6>Click a participant's name to view their questionnaire results. </h6>
            <div class="row">
              <div class="col-md-12">
                <input type="text" [(ngModel)]="searchName" name="searchName" class="form-control" placeholder="search name"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 tt">
                <div class="table_scrol table-responsive">
                  <table class="table table-striped table-bordered table-hover  w-100" style="display: inline-table;">
                    <thead class="thead-dark">
                        <tr>
                            <th>Name</th>
                            <th>Joining Date</th>
                            <th>Status</th>
                            <th>Option</th>
                        </tr>
                    </thead>
                    <tbody>
                       <tr *ngFor="let item of dataDetail.survey_participants | filter:{key:'user_name',value:searchName}">
                           <td>
                              {{item.user_name}}
                            </td>
                            <td>
                              {{item.joining_date}}
                            </td>
                           <td>
                            <span *ngIf="item.survey_progress == 1">Complete</span>
                            <span *ngIf="item.survey_progress == 0">Not Complete</span>
                           </td>
                           <td>
                            <button type="button" (click)="sendReminder(item)" class="btn  btn-info btn-sm" *ngIf="item.survey_progress == 0 && dataDetail.is_admin == true && item.is_expire == false">Send Remainder</button>
                            &nbsp;<button type="button" (click)="showAddTeam(item)" class="btn  btn-info btn-sm" *ngIf="item.survey_progress == 1 && dataDetail.is_admin == true && item.is_expire == false">Add to Team</button>
                            &nbsp;<button type="button" (click)="deleteInGroup(item)" class="btn  btn-info btn-sm" *ngIf="item.survey_progress == 0">Delete</button>
                            &nbsp;<button type="button" (click)="renew(item)" *ngIf="item.is_expire == true" class="btn btn-sm btn-info">Renew</button>
                           </td>
                       </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>


        </div>
        <div class="card" style="padding: 10px;" *ngIf="dataDetail.survey_group != undefined">
          <h4>Deleted Participants</h4>
          <div class="row">
            <div class="col-md-12">
              <input type="text" [(ngModel)]="searchName2" name="searchName2" class="form-control" placeholder="search name"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 tt">
              <div class="table_scrol table-responsive">
                <table class="table table-striped table-bordered table-hover  w-100" style="display: inline-table;">
                  <thead class="thead-dark">
                      <tr>
                          <th>Name</th>
                          <th>Joining Date</th>
                          <th>Status</th>
                          <th>Option</th>
                      </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item of dataDetail.deleted_survey_participants | filter:{key:'user_name',value:searchName2}">
                         <td>
                            {{item.user_name}}
                          </td>
                          <td>
                            {{item.joining_date}}
                          </td>
                         <td>
                          <span *ngIf="item.survey_progress == 1">Complete</span>
                          <span *ngIf="item.survey_progress == 0">Not Complete</span>
                         </td>
                         <td>
                          <button type="button" (click)="unDeleteInGroup(item)" class="btn  btn-info btn-sm">Un Delete</button>
                          </td>
                     </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>


      </div>
    </div>
</div>
<!-- Multiple Invite Modal -->
<div class="modal fade" id="multipleInvitee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="width: 100%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Send Multiple Invitation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 mb-4 mb-md-0">
				<div class="">
          <form class="form" >
            <div class="form-group">
              <label>Enter Emails</label>
              <!-- <input type="text" class="form-control" [(ngModel)]="multipleInvites.emails" class="form-control" name="name"/> -->
              <article>
                <input class='customLook' id="emails" name="emails[]" [(ngModel)]="multipleInvites.emails" value=''>
              </article>
            </div>

          </form>


				</div>
			</div>
		</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-info"  (click)="sendMultipleInvite()">Save</button>
      </div>
    </div>
  </div>
</div>

<!-- PPC Payment Modal -->
<div class="modal fade" id="PPCPaymentModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="width: 100%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Please pay to generate report</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 mb-4 mb-md-0">
				<div class="bx_brd" *ngIf="showPPCPayment">

          <app-ppc-payment-button [plan]="ppcPlan[0]" [total]="totalTeamMember" [subGroupID]="subGroupID" (ppc_payment)="showTeamReport($event)"   ></app-ppc-payment-button>


          <div class="payment" id="paymentPPCSuccess" style="display: none;">
            <div class="payment_header">
               <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
            </div>
            <div class="content">
               <h1>Payment Success !</h1>
               <p>Invitation purchase successful. </p>
            </div>

          </div>
          <div class="payment" id="paymentPPCError" style="display: none;">
            <div class="payment_header">
               <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
            </div>
            <div class="content">
               <h1>Payment Failed !</h1>
               <p>Invitation purchase failed, please try again. </p>
            </div>

          </div>
				</div>
			</div>
		</div>
      </div>
    </div>
  </div>
</div>
<!-- Subscription Payment Modal -->
<div class="modal fade" id="SubscriptionPaymentModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="width: 100%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Purchase subscription to access app features</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 mb-4 mb-md-0">
				<div class="bx_brd">

          <app-appsub-payment-button [plan]="subPlan[0]" [groupID]="obInvite.group_id" *ngIf="showPayment"  ></app-appsub-payment-button>


          <div class="payment" id="paymentSuccess" style="display: none;">
            <div class="payment_header">
               <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
            </div>
            <div class="content">
               <h1>Payment Success !</h1>
               <p>Invitation purchase successful. </p>
            </div>

          </div>
          <div class="payment" id="paymentError" style="display: none;">
            <div class="payment_header">
               <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
            </div>
            <div class="content">
               <h1>Payment Failed !</h1>
               <p>Invitation purchase failed, please try again. </p>
            </div>

          </div>
				</div>
			</div>
		</div>
      </div>
    </div>
  </div>
</div>

<!-- Add Team Modal -->
<div class="modal" id="addTeamModal" >
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Add {{obTeamUser.user_name}} To Team</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form class="form" *ngIf="obTeamUser.user_name != undefined">
          <div class="form-group">
            <label>Select Team</label>
            <select [(ngModel)]="obTeamUser.subgroup_id" class="form-control" name="subTeam" id="subTeam">
              <option value="">Select Team</option>
              <option *ngFor="let item of teamList" value="{{item.id}}">{{item.name}}</option>
            </select>
          </div>
        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-info"  (click)="saveUserTeam()">Save</button>
      </div>

    </div>
  </div>
</div>
<!-- Participent List Modal -->
<div class="modal fade" id="participentList" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" style="width: 100%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Manage Team</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
		<div class="row">
			<div class="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0">
				<div class="bx_brd">
					<h4>Group List</h4>
					<div class="srchoption">
						<input type="text" [(ngModel)]="searchName1" name="searchName1" placeholder="Search" />
						<i class="fa fa-search"></i>
          </div>
          <div class="table_scroll">
            <table class="table table-striped table-bordered table-hover grp-tble">
              <thead class="thead-dark">
                <tr>
                  <th>Name</th>
                  <th>Benchmark</th>
                  <th>Participant</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of teamUsers | filter:{key:'user_name',value:searchName2}; let i = index">
                  <td>{{item.user_name}}</td>
                  <td style="text-align: right;">
                    <input type="radio" (change)="changeUsertype(i)" name="usertype{{i}}" [(ngModel)]="teamUsers[i].user_type" value="benchmark" class="form-control"/>
                  </td>
                  <td style="text-align: right;">
                    <input type="radio" (change)="changeUsertype(i)" name="usertype{{i}}" [(ngModel)]="teamUsers[i].user_type" value="participant" class="form-control"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
				</div>
			</div>
			<div class="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0">
				<div class="bx_brd">
					<h4>Benchmark List</h4>
					<div class="srchoption">
						<input type="text" [(ngModel)]="searchName2" name="searchName2" placeholder="Search" />
						<i class="fa fa-search"></i>
					</div>
          <div class="table_scroll">
            <table class="table table-striped table-bordered table-hover bnchmrk-tbl">
              <thead class="thead-dark">
                <tr>
                  <th>Name</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of benchmarkUsers | filter:{key:'user_name',value:searchName2}; let i = index">
                  <td><a href="javascript:;" (click)="setTeamResultParams(item)">{{item.user_name}}</a></td>
                  <td style="text-align: right;">
                    <button (click)="removeBenchmark(i)" class="btn btn-info"><i class="fa fa-trash"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
				</div>
			</div>
			<div class="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0">
				<div class="bx_brd">
					<h4>Participant List</h4>
					<div class="srchoption">
						<input type="text" [(ngModel)]="searchName3" name="searchName3" placeholder="Search" />
						<i class="fa fa-search"></i>
					</div>
          <div class="table_scroll">
            <table class="table table-striped table-bordered table-hover prticpnt-tbl">
              <thead class="thead-dark">
                <tr>
                  <th>Name</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of participentUsers | filter:{key:'user_name',value:searchName3}; let i = index">
                  <td><a href="javascript:;" (click)="setTeamResultParams(item)">{{item.user_name}}</a> </td>
                  <td style="text-align: right;">
                    <button (click)="removeParticipent(i)" class="btn btn-info"><i class="fa fa-trash"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
				</div>
			</div>
		</div>
      </div>
    </div>
  </div>
</div>

<!-- Add Group Modal -->
<div class="modal" id="addGroupModal" >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title"> Add Group </h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <form class="form" >
            <div class="form-group">
              <label>Group Name</label>
              <input type="text" class="form-control" [(ngModel)]="obGroup.name" class="form-control" name="name"/>
            </div>
            <div class="form-group">
                <label>Passcode</label>
                <input type="text" class="form-control" [(ngModel)]="obGroup.code" class="form-control" name="code"/>
              </div>
          </form>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-info"  (click)="createGroup()">Save</button>
        </div>

      </div>
    </div>
  </div>

<!-- Invitee List Modal -->
<div class="modal fade" id="inviteeList" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="width: 100%;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Invitees List</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12 mb-4 mb-md-0">
				<div class="bx_brd">

          <table class="table table-responsive table-striped table-bordered table-hover">
            <thead class="thead-dark">
              <tr>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of inviteeList">
                <td>{{item.email}}</td>
                <td>
                  <button (click)="resendInvite(item)" class="btn btn-info btn-sm">Resend Invite</button> &nbsp;
                  <button (click)="deleteInvite(item)" class="btn btn-danger btn-sm">Delete Invite</button>
                </td>
              </tr>
            </tbody>
          </table>

				</div>
			</div>
		</div>
      </div>
    </div>
  </div>
</div>
