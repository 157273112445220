import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { Globals } from '../global';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  auth:any;
  user:any = {email:"",password:""};
  res:any;
  loginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  });
  constructor(private fb: FormBuilder,private api: ApiService, private global: Globals,private router: Router,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.auth = this.global.getUser();
    if(this.auth != undefined)
    {
      this.global.unsetUser();
      window.location.replace('login');
    }
  }

  onSubmit() {
    this.api.login(this.loginForm.value).subscribe((data:any)=>{
      this.res = data;
      this.global.setUser(this.res.data.name,this.res.data.email,this.res.data.token,this.res.data.role,this.res.data.image);
      this.toastr.success('Success', this.res.message);
      this.router.navigateByUrl('user');
    })
  }

}
