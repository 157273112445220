
<section id="slider" class="inner_bnr" style="background:url(assets/images/partners_bnr.jpg) no-repeat center center / cover;">
	<h2 class="upr">Partners</h2>
</section>

<section id="partner_cont">
	<div class="container">
		<div class="row">
		   <div class="col-lg-12">
				<div class="partner_bx">
					<div class="row">
						<div class="col-lg-4 col-md-6">
							<figure>
								<img src="assets/images/partner06.jpg" />
							</figure>
						</div>
						<div class="col-lg-8 col-md-6">
							<div class="partner_info">
								<h4>RevenueTEK</h4>
								<p>RevenueTEK is a specialist sales intelligence and analytics company.  RevenueTEK helps organisations significantly and sustainably improve their revenues and profitability by simplifying the insane complexity of the marketing and sales technology, processes and data they are forced to work with every day.  Using a potent and unique combination of advanced AI-based analytics coupled with 15 years of accumulated consulting IP global and market research embodied in a methodology called Marginal Gain Theory, RevenueTEK provides organisations with the intelligence and insight to continually analyse and measure the performance of their pipeline systems, isolate and prioritise issues and opportunities, and respond optimally.</p>
							</div>
						</div>
					</div>
				</div>
		   </div>
		</div>
	</div>
</section>
