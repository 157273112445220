<section id="slider" class="inner_bnr" [style.background]="'url(' + featureImage + ') '">
	<h2 class="upr">How It Works?</h2>
</section>

<section id="aboutus">
	<div class="container">
		<div class="row">
			<div class="col-lg-7 col-md-12 col-sm-12 order-2 order-lg-1">
				<div class="about_cnt">
					<div class="subhdng">Skill and Knowledge</div>
					<h1 class="mb-3">How It Works</h1>
          <p>TeamPlayerHR is a seamless application based on cloud technology that is accessible from any device with Internet access. This makes it available to candidates/participants and business representatives anywhere in the world at any time. The system is also secure and confidential, and only authorized individuals will see the results. A central data collection point allows flexibility in matching combinations.</p>
          <p>Designed by behavioral psychologists, TeamPlayerHR is not a psychometric test. Psychometric tests (such as Myers Briggs) were designed by occupational psychologists and have been in use for more than seven decades. Psychometric tests assess a person’s placement within various psychosocial attribute scales. Their value has largely been focused on gauging how a person’s tendency to express certain personality traits differs from others. Psychometric tests have their virtues, to be sure, but by-and-large they aren’t designed to answer the question of likely “Cultural fit” within the workplace.</p>


				</div>
			</div>
			<div class="col-lg-5 col-md-12 col-sm-12 align-self-center order-1 order-lg-2 mb-4 mb-lg-0">
				<div class="work_img">
					<figure><img src="assets/images/how_it_works.png" class="img-fluid"></figure>
				</div>
			</div>
      <div class="col-lg-12 col-md-12 col-sm-12 order-3">
				<div class="about_cnt">

          <p>In contrast, TeamPlayerHR captures how participants perceive, interpret and approach common workplace situations. TeamPlayerHR adds scientific objectivity to the candidate selection process because participants are assessed and compared using the same standardized measures. Its proprietary questionnaire explores key intrinsic attributes within the context of practical workplace expectations, including work environment and culture, conflict resolution, problem-solving, workplace interaction, work/life balance, work habits and career goals. </p>
          <p>TeamPlayerHR’s cloud-based diagnostic questionnaire is designed to provide an “IM” (Intrinsic Matrix) profile for prospective candidates and employers. IM profiles are summary indicators of a participant’s congruence with characteristics known to affect job performance and job satisfaction.</p>
          <p>Profiles of candidates and employers are compared to one another to identify which combinations of individuals and groups share the ‘best fit.’ In this way, TeamPlayerHR refines the selection process for both participant and employer, especially when used in conjunction with other recruitment and selection methods.</p>
          <p>Other methods of recruitment practice fall short in assessing for these qualities. TeamPlayerHR introduces an extra dimension from which candidates are assessed for compatibility.</p>
          <h4>TeamPlayerHR Compatibility Results</h4>
          <p>TeamPlayerHR looks at four key points when it assesses the suitability of a participant/candidate to a particular team role. These are as follows:</p>
          <ul>
          <li>Compatibility –TeamPlayerHR is a situational behavioural questionnaire, not a psychological test</li>
          <li>Similarities -- The tool evaluates the fit between potential new hires and existing employees </li>
          <li>Statistical analysis -- This provides a method of quantifying the degree of compatibility. Because the questionnaire is data-based, it ceases to be a game of chance</li>
          <li>Outcome -- This is unique, because no other tool measures true compatibility</li>
          </ul>

          <p>Compatibility in this context is a measure of the degree to which individuals are similar in views, opinions, feelings and attitudes towards the following:</p>
          <ul>
          <li>Their general wants and needs</li>
          <li>The working environment</li>
          <li>Social and interpersonal skills</li>
          <li>Problem solving skills and styles</li>
          </ul>
          <p>A higher statistical significance suggests more compatibility, which is interpreted to mean better professional relationships, a better working environment, improved employee retention, and a more engaged workforce. A ranking algorithm orders the candidates in terms of Good Fit to Bad Fit, on a sliding scale, with a given individual such as a line manager or fellow team members.</p>
          <p>In conclusion, deficiencies in the current recruitment processes are real. Introducing another perspective by using TeamPlayerHR means new hires are more likely to fit into existing teams more easily and are likely to stay with the company longer. This ultimately reduces costs related to recruitment. Recruitment agencies will see increased client satisfaction as they match hires more closely with the recruiting company's employees and culture. Compatibility in the workplace really does matter.</p>

				</div>
			</div>
		</div>
	</div>
</section>
<section id="intrested">
	<div class="container">
		<div class="row">
			<div class="col-lg-9 col-md-8 align-self-center">
				<h3>Interested in finding out more? Why not see it in action...</h3>
			</div>
			<div class="col-lg-3 col-md-4 align-self-center text-right mt-4 mt-md-0">
				<a class="readmore white" data-toggle="modal" data-target="#demo_form_modal" href="javascript:void(0);">Request a demo</a>
			</div>
		</div>
	</div>
</section>
<section id="difference">
	<div class="container">
		<div class="row">
			<div class="col-lg-5 col-md-12 text-center align-self-center mb-4 mb-lg-0">
				<figure><img src="assets/images/why_you_need.png" class="img-fluid"></figure>
			</div>
			<div class="col-lg-7 col-md-12 align-self-center">
				<div class="subhdng">Your Approach</div>
				<h2 class="mb-4">Why you need TeamPlayer<sup>HR</sup></h2>
        <p>To be successful, your business must focus on finding employees who meet your needs and are likely to stay with your organization for years. The TeamPlayer<sup>HR</sup> can help you refine your selection process, both at the beginning and at the end. It can be used as the first step in your hiring selection, facilitating the screening process in a way that does not impose extra demands on your organization. This means that, before you even interview, you already have an objective means of identifying the best people for you.</p><p>The TeamPlayer<sup>HR</sup> can be as equally helpful at the final phases of hiring. Suppose you have three or four applicants who you feel are well suited. The TeamPlayer<sup>HR</sup> can support you to select the most appropriate candidate for your organization, in a manner that doesn’t rely on subjective impressions or personalities.</p>
			</div>
		</div>
	</div>
</section>

<!--[innerHTML]="content"-->
<!-- <div [innerHTML]="content">

</div> -->
