<header id="web-header">
	<div class="top_header">
		<div class="container">
			<div class="row">
				<div class="col-lg-6 col-md-6 col-sm-6 col-6">
					<ul class="social float-left">
						<li><a href="https://www.facebook.com/teamplayerhr.IM" target="_blank"><i class="flaticon-facebook"></i></a></li>
						<!--<li><a href="javascript:void(0);"><i class="flaticon-twitter"></i></a></li>-->
						<li><a href="https://www.linkedin.com/company/teamplayerhr/" target="_blank"><i class="flaticon-linkedin"></i></a></li>
					</ul>
				</div>
				<div class="col-lg-6 col-md-6 col-sm-6 col-6">
					<ul class="top_menu float-right" *ngIf="auth  == undefined">
						<li><a [routerLink]="['login']" routerLinkActive="router-link-active">Login</a></li>
						<li><a [routerLink]="['signup']" routerLinkActive="router-link-active">Signup</a></li>
					</ul>
					<ul class="top_menu float-right" *ngIf="auth  != undefined">
						<li>
              <a [routerLink]="['user']" routerLinkActive="router-link-active">
                <span>
                  <img *ngIf="auth.image != '' && auth.image != null" src="{{baseURL}}storage/app/public/{{auth.image}}" />
                  <img *ngIf="auth.image == '' || auth.image == null" src="../assets/images/profile-user.png" />

                </span> {{auth.user_name}}</a>
              </li>
						<li><a [routerLink]="['logout']" routerLinkActive="router-link-active">Logout</a></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="main_header">
		<div class="logo_cont">
			<div class="container">
				<div class="logo">
					<a [routerLink]="['/']" routerLinkActive="router-link-active" title="TeamPlaeyerHR"><img style="max-width:125%;width:125%;" src="assets/images/logo.png" class="img-fluid" alt="TeamPlaeyerHR" /></a>
				</div>
				<div class="contact_dtl d-none d-sm-none d-md-block">
					<ul>
						<li>
							<i class="flaticon-email"></i>
							<div class="dtl">
								<span>Contact Us</span>
								<a href="mailto:info@TeamPlayerHR.com">info@TeamPlayerHR.com</a>
							</div>
						</li>
					</ul>
				</div>
				<div class="clearfix"></div>
			</div>
		</div>
		<div class="menu_cont">
			<div class="container">
				<nav class="navbar navbar-expand-lg">
				  <a [routerLink]="['/']" routerLinkActive="router-link-active" class="navbar-brand" title="TeamPlaeyerHR"><img src="assets/images/logo.png" class="img-fluid" alt="TeamPlaeyerHR" /></a>
				  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				  </button>
				  <div class="collapse navbar-collapse" id="navbarsExample04">
					<ul class="navbar-nav mr-auto">
					  <li class="nav-item"><a class="nav-link" [routerLink]="['/']" routerLinkActive="router-link-active">Home</a></li>
					  <li class="nav-item"><a class="nav-link" [routerLink]="['/how-it-works']" routerLinkActive="router-link-active">How it Works</a> </li>
					  <li class="nav-item"><a class="nav-link" [routerLink]="['/testimonials']" routerLinkActive="router-link-active">Testimonials</a></li>
					  <li class="nav-item"><a class="nav-link" [routerLink]="['/news']" routerLinkActive="router-link-active">News</a></li>
					  <li class="nav-item"><a class="nav-link" [routerLink]="['/partners']" routerLinkActive="router-link-active">Partners</a></li>
					  <li class="nav-item"><a class="nav-link" [routerLink]="['/purchase']" routerLinkActive="router-link-active">Purchase</a></li>
					  <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/']" routerLinkActive="router-link-active">Subscribe</a></li> -->
					  <li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="javascript:void(0);" id="dropdown04" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About Us</a>
						<div class="dropdown-menu" aria-labelledby="dropdown04">
						  <a class="dropdown-item" [routerLink]="['/contact']" routerLinkActive="router-link-active">Contact</a>
						  <a class="dropdown-item" [routerLink]="['/faq']" routerLinkActive="router-link-active">FAQ</a>
						  <!-- <a class="dropdown-item" [routerLink]="['/']" routerLinkActive="router-link-active">Investors</a> -->
						</div>
					  </li>
					</ul>
				  </div>
				  <div class="float-right">
					  <a class="readmore arrow demo-btn" type="button" (click)="openDemoModal()" href="javascript:;">Demo <span><i class="flaticon-right-arrow-angle"></i></span></a>
				  </div>
				</nav>
			</div>
		</div>
	</div>
</header>



<div class="modal fade custom_modal" id="demo_form_modal" tabindex="-1" role="dialog" aria-labelledby="demo_form_modal" aria-hidden="true">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Request A Demo</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body demo_form">
				<form [formGroup]="signupOrgForm" (ngSubmit)="onSubmit()">
					<div class="form-group">
            <label>First Name <span class="astik">*</span></label>
						<input type="text" class="form-control" formControlName="first_name" placeholder="First Name" />
            <span class="error" *ngIf="errorOrg.first_name.length > 0">
              {{errorOrg.first_name}}
            </span>
					</div>
					<div class="form-group">
            <label>Last Name <span class="astik">*</span></label>
						<input type="text" class="form-control" formControlName="last_name" placeholder="Last Name" />
            <span class="error" *ngIf="errorOrg.last_name.length > 0">
              {{errorOrg.last_name}}
            </span>
					</div>
					<div class="form-group">
            <label>Company Name <span class="astik">*</span></label>
						<input type="text" class="form-control" formControlName="organization_name" placeholder="Company Name" />
            <span class="error" *ngIf="errorOrg.organization_name.length > 0">
              {{errorOrg.organization_name}}
            </span>
					</div>
					<div class="form-group">
            <label>Phone <span class="astik">*</span></label>
						<input type="number" class="form-control" formControlName="phone" name="phone" placeholder="Phone" />
            <span class="error" *ngIf="errorOrg.phone.length > 0">
              {{errorOrg.phone}}
            </span>
					</div>
					<div class="form-group">
            <label>Email <span class="astik">*</span></label>
						<input type="text" class="form-control" formControlName="email"  placeholder="Email" />
            <span class="error" *ngIf="errorOrg.email.length > 0">
              {{errorOrg.email}}
            </span>
					</div>
          <div class="form-group">
            <label>Your Role <span class="astik">*</span></label>
            <select formControlName="user_role" class="form-control">
              <option value="1">CEO</option>
              <option value="2">HR Manager</option>
              <option value="3">HR Consultant</option>
              <option value="4">HR Generalist</option>
              <option value="5">Leader</option>
              <option value="6">Other</option>
            </select>
            <span class="error" *ngIf="errorOrg.user_role.length > 0">
              {{errorOrg.user_role}}
            </span>
          </div>
          <div class="form-group">
            <label>Number Of Employees <span class="astik">*</span></label>
            <select formControlName="no_of_employees" class="form-control">
              <option value="1-20">1-20</option>
              <option value="21-80">21-80</option>
              <option value="81-500">81-500</option>
              <option value="+500">+500</option>
            </select>
            <span class="error" *ngIf="errorOrg.no_of_employees.length > 0">
              {{errorOrg.no_of_employees}}
            </span>
          </div>
          <div class="form-group">
            <label>Contact Date <span class="astik">*</span></label>
            <input class="form-control" [min]="min" formControlName="selected_date" name="selected_date" [(ngModel)]="selected_date" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Contact Date">
            <owl-date-time #dt1></owl-date-time>
            <span class="error" *ngIf="errorOrg.selected_date.length > 0">
              {{errorOrg.selected_date}}
            </span>
          </div>
					<div class="form-group">
						<label class="chkbx">Agree to <a [routerLink]="['/terms-and-conditions']" routerLinkActive="router-link-active"  href="terms-and-conditions" target="_blank">Terms & Conditions</a>.
						  <input type="checkbox" formControlName="agreeTerms">
						  <span class="checkmark"></span>
						</label>
            <span class="error" *ngIf="errorOrg.agreeTerms.length > 0">
              {{errorOrg.agreeTerms}}
            </span>
						<div class="clearfix"></div>
					</div>
          <div class="form-group">
						<label class="chkbx">Agree to <a [routerLink]="['/privacy-policy']" routerLinkActive="router-link-active"  href="privacy-policy" target="_blank">Privacy Policy</a>.
						  <input type="checkbox" formControlName="agreePrivacy">
						  <span class="checkmark"></span>
						</label>
            <span class="error" *ngIf="errorOrg.agreePrivacy.length > 0">
              {{errorOrg.agreePrivacy}}
            </span>
						<div class="clearfix"></div>
					</div>
					<div class="clearfix"></div>
					<div class="form-group mb-0 mt-1">
						<input type="submit" class="btn btn-primary" name="submit" value="request a demo" [disabled]="submitting">
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

