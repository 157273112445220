import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../global';
import { ApiService } from '../../api.service';
import { DemoapiService } from '../../demoapi.service';
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from '../../filter.pipe';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  constructor(private global: Globals,private route:ActivatedRoute, private router: Router,private api: ApiService,private demoapi: DemoapiService,private toastr: ToastrService,private spinner: NgxSpinnerService) { }
  invitationList:any = [];
  userGroupList:any = [];
  demoInvitationList:any = [];
  userDemoGroupList:any = [];
  group:any = {name:"",code:""};
  groupscore:any = {test:"",group_id:""};
  demoGroup:any = {name:"",code:""};

  ngOnInit(): void {
    this.getUserGroup();

    this.getUserDemoGroup();
  }

  setTestParams(ob:any)
  {
    if(ob.max_size == 0)
    {
      this.toastr.error('Please purchase questionnaire','Error');
    }
    else
    {
      this.global.setTestParams(ob.id,ob.survey_progress);
      this.router.navigate(['user/start-test']);
    }
  }

  getUserGroup(){
    this.api.getUserGroup().subscribe((data: any[])=>{
      let res:any = data;
      this.userGroupList = res.data;
	  var i = 0;
	  for(i = 0; i < this.userGroupList.length; i++)
          {
			  this.setScore(this.userGroupList[i]);
			  
		  }
	  
	  
      this.getInvitationList();
    });
  }
  
  setScore(groupscore:any)
  {
    // var test_data = groupscore;
    //console.log(test_data);

    // var param = {test:test_data.test,group_id:test_data.id};
    // this.api.setScore(param).subscribe((data: any[])=>{
    //   var res:any = data;
    //   //console.log(res.data);
    //   // setTimeout(function () {
    //       // window.location.replace('/');
    //   // }, 3000);
    // });
  }
  
  demosetScore(groupscore:any)
  {
    // var test_data = groupscore;
    //console.log(test_data);

    // var param = {test:test_data.test,group_id:test_data.id};
    // this.demoapi.setScore(param).subscribe((data: any[])=>{
    //   var res:any = data;
    //   //console.log(res.data);
    //   // setTimeout(function () {
    //       // window.location.replace('/');
    //   // }, 3000);
    // });
  }

  getInvitationList(){
    this.invitationList = [];
    this.api.getInvitation().subscribe((data: any[])=>{
      let res:any = data;
      this.invitationList = res.data;
      console.log("this.invitationList");
      console.log(this.invitationList);
      if(this.invitationList.length > 0 && this.userGroupList.lenth == 0)
      {
        if(this.invitationList[0].questionnaire_status == false)
        {
          this.group = this.invitationList[0].group;
          this.spinner.show();
          this.api.joinGroup(this.group).subscribe((data: any[])=>{
            let res:any = data;
            this.spinner.hide();
            if(res.data.status == true)
            {
              this.router.navigate(['user','start-test'])
            }
          });
        }
      }
    });
  }


  showDetails(ob:any){
    //console.log(ob);
    this.group = ob.group;
  }

  joinGroup(){
    console.log(this.group);
    this.api.joinGroup(this.group).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success('Success', res.message);
	 this.getUserGroup();
     this.getInvitationList();
      setTimeout(()=>{
       window.location.reload();
     }, 1500);
      
    });
  }

  //Demo
  getUserDemoGroup(){
    this.demoapi.getUserGroup().subscribe((data: any[])=>{
      let res:any = data;
      this.userDemoGroupList = res.data;
	  
	  var i = 0;
	  for(i = 0; i < this.userDemoGroupList.length; i++)
          {
			  this.demosetScore(this.userDemoGroupList[i]);
			  
		  }
	  
      this.getDemoInvitationList();
    });
  }

  getDemoInvitationList(){
    this.demoapi.getInvitation().subscribe((data: any[])=>{
      let res:any = data;
      this.demoInvitationList = res.data;
    });
  }

  setDemoTestParams(ob:any)
  {
    if(ob.max_size == 0)
    {
      this.toastr.error('Please purchase app questionnaire','Error');
    }
    else
    {
      this.global.setDemoTestParams(ob.id,ob.survey_progress);
      this.router.navigate(['user/app-questionnaire/start-test']);
    }

  }

  showDemoDetails(ob:any){
    console.log(ob);
    this.demoGroup = ob.group;
  }

  joinDemoGroup(){
    console.log(this.demoGroup);
    this.demoapi.joinGroup(this.demoGroup).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success('Success', res.message);
	  this.getUserDemoGroup();
      this.getDemoInvitationList();
      setTimeout(()=>{
        window.location.reload();
      }, 1500);
    });
  }


}
