import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormArray } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { Globals } from '../global';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { ConditionalExpr } from '@angular/compiler';
import { NgxSpinnerService } from "ngx-spinner";
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ReCaptchaV3Service } from 'ng-recaptcha';

declare var $: any;
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  email:any = "";
  invitetype:any='individual';
  sectorList:any=[];
  countryList:any=[];
  stateList:any=[];
  stateORGList:any=[];
  cityList:any=[];
  cityORGList:any=[];
  occupationList:[];
  fileToUpload: File = null;
  fileName:any;
  submitting: boolean;
  error = {
    first_name: "",
    last_name: "",
    email: "",
    title: "",
    phone: "",
    cv: "",
    address_line_1:"",
    address_line_2: "",
    sector: "",
    occupation: "",
    country: "",
    city: "",
    state: "",
    zip: "",
    password: "",
    confirm_password: "",
    agreeTerms: "",
    agreePrivacy: "",
  };
  errorOrg = {
    first_name: "",
    last_name: "",
    email: "",
    title: "",
    phone: "",
    organization_name: "",
    address_line_1:"",
    address_line_2: "",
    sector: "",
    country: "",
    city: "",
    state: "",
    zip: "",
    password: "",
    confirm_password: "",
    agreeTerms: "",
    agreePrivacy: "",
    user_role:"",
    no_of_employees:""
  };
  signupForm = this.fb.group({
    first_name: [''],
    last_name: [''],
    email: [''],
    title: [''],
    phone: [''],
    cv: [''],
    address_line_1: [''],
    address_line_2: [''],
    sector: [''],
    occupation: [''],
    country: [''],
    city: [''],
    state: [''],
    zip: [''],
    password: [''],
    confirm_password: [''],
    agreeTerms: [''],
    agreePrivacy: [''],
  });

  signupOrgForm = this.fb.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', Validators.required],
    title: [''],
    organization_name: [''],
    phone: [''],
    address_line_1: [''],
    address_line_2: [''],
    sector: [''],
    country: [''],
    city: [''],
    state: [''],
    zip: [''],
    password: ['', Validators.required],
    confirm_password: ['', Validators.required],
    agreeTerms: ['', Validators.required],
    agreePrivacy: ['', Validators.required],
    user_role: ['', Validators.required],
    no_of_employees: ['', Validators.required],
  });

  constructor(private fb: FormBuilder,private titleService: Title,private metaTagService: Meta,private global: Globals, private api: ApiService,private toastr: ToastrService,private route:ActivatedRoute, private router: Router,private spinner: NgxSpinnerService, private recaptchaV3Service: ReCaptchaV3Service,) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((data:any)=>{
      if(data.email != undefined)
      {
        this.email = data.email;
      }
      if(data.type != undefined)
      {
        this.invitetype = data.type;
      }
      //console.log(data.email);
    });
    window.scrollTo(0, 0);
    this.getCountryList();
    this.getSectorList();
    this.getOccupationList();
  }

  validateUserSignup(ob:any){
    //console.log(ob);
    this.error = {
      first_name: "",
      last_name: "",
      email: "",
      title: "",
      phone: "",
      cv: "",
      address_line_1:"",
      address_line_2: "",
      sector: "",
      occupation: "",
      country: "",
      city: "",
      state: "",
      zip: "",
      password: "",
      confirm_password: "",
      agreeTerms: "",
      agreePrivacy: "",
    };
    var status = true;
	
	
    if(ob.first_name == "" || ob.first_name.length == 0)
    {
      this.error.first_name = "First Name is required.";
      status = false;
    }
    if(ob.last_name == "" || ob.last_name.length == 0)
    {
      this.error.last_name = "Last Name is required.";
      status = false;
    }
    if(ob.phone == "" || ob.phone.length == 0)
    {
		
      this.error.phone = "Phone is required.";
      status = false;
    }
    if(ob.address_line_1 == "" || ob.address_line_1.length == 0)
    {
      this.error.address_line_1 = "Address Line 1 is required.";
      status = false;
    }
    if(ob.sector == "" || ob.sector.length == 0)
    {
      this.error.sector = "Sector is required.";
      status = false;
    }
    if(ob.occupation == "" || ob.occupation.length == 0)
    {
      this.error.occupation = "Occupation is required.";
      status = false;
    }
    // if(ob.cv == undefined || ob.cv == "" || ob.cv.length == 0)
    // {
    //   this.error.cv = "Resume / CV is required.";
    //   status = false;
    // }
    if(ob.country == "" || ob.country.length == 0)
    {
      this.error.country = "Country is required.";
      status = false;
    }
    if(ob.state == "" || ob.state.length == 0)
    {
      this.error.state = "State is required.";
      status = false;
    }
    if(ob.password == "" || ob.password.length == 0)
    {
      this.error.password = "Password is required.";
      status = false;
    }
    if(ob.confirm_password == "" || ob.confirm_password.length == 0)
    {
      this.error.confirm_password = "Confirm Password is required.";
      status = false;
    }
    if(ob.confirm_password != ob.password)
    {
      this.error.confirm_password = "Confirm Password must match with password.";
      status = false;
    }

    if(ob.agreeTerms == undefined || ob.agreeTerms == "" || ob.agreeTerms.length == 0)
    {
      this.error.agreeTerms = "Check Agree to Terms & Conditions.";
      status = false;
    }
    if(ob.agreePrivacy == undefined || ob.agreePrivacy == "" || ob.agreePrivacy.length == 0)
    {
      this.error.agreePrivacy = "Check Agree to Privacy Policy.";
      status = false;
    }
    return status;
  }

  validateOrgSignup(ob:any){
    this.errorOrg = {
      first_name: "",
      last_name: "",
      email: "",
      title: "",
      phone: "",
      organization_name: "",
      address_line_1:"",
      address_line_2: "",
      sector: "",
      country: "",
      city: "",
      state: "",
      zip: "",
      password: "",
      confirm_password: "",
      agreeTerms: "",
      agreePrivacy: "",
      user_role:"",
      no_of_employees:""
    };
    var status = true;
    if(ob.first_name == "" || ob.first_name.length == 0)
    {
      this.errorOrg.first_name = "First Name is required.";
      status = false;
    }
    if(ob.last_name == "" || ob.last_name.length == 0)
    {
      this.errorOrg.last_name = "Last Name is required.";
      status = false;
    }
    if(ob.phone == "" || ob.phone.length == 0)
    {
      this.errorOrg.phone = "Phone is required.";
      status = false;
    }
    if(ob.address_line_1 == "" || ob.address_line_1.length == 0)
    {
      this.errorOrg.address_line_1 = "Address Line 1 is required.";
      status = false;
    }
    if(ob.sector == "" || ob.sector.length == 0)
    {
      this.errorOrg.sector = "Sector is required.";
      status = false;
    }
    if(ob.organization_name == "" || ob.organization_name.length == 0)
    {
      this.errorOrg.organization_name = "Organization name is required.";
      status = false;
    }
    if(ob.user_role == "" || ob.user_role.length == 0)
    {
      this.errorOrg.user_role = "Role is required.";
      status = false;
    }
    if(ob.no_of_employees == "" || ob.no_of_employees.length == 0)
    {
      this.errorOrg.no_of_employees = "No of employee is required.";
      status = false;
    }
    if(ob.country == "" || ob.country.length == 0)
    {
      this.errorOrg.country = "Country is required.";
      status = false;
    }
    if(ob.state == "" || ob.state.length == 0)
    {
      this.errorOrg.state = "State is required.";
      status = false;
    }
    if(ob.password == "" || ob.password.length == 0)
    {
      this.errorOrg.password = "Password is required.";
      status = false;
    }
    if(ob.confirm_password == "" || ob.confirm_password.length == 0)
    {
      this.errorOrg.confirm_password = "Confirm Password is required.";
      status = false;
    }
    if(ob.confirm_password != ob.password)
    {
      this.errorOrg.confirm_password = "Confirm Password must match with password.";
      status = false;
    }
    if(ob.agreeTerms == "" || ob.agreeTerms.length == 0)
    {
      this.errorOrg.agreeTerms = "Check Agree to Terms & Conditions.";
      status = false;
    }
    if(ob.agreePrivacy == "" || ob.agreePrivacy.length == 0)
    {
      this.errorOrg.agreePrivacy = "Check Agree to Privacy Policy.";
      status = false;
    }
    return status;
  }
  
  getNumber(obj){
	  //console.log(obj);
	  this.signupForm.value.phone=obj;
  }
  getOrgNumber(obj){
	  //console.log(obj);
	  this.signupOrgForm.value.phone=obj;
  }
  hasError(obj){
	  return true;
  }
  telInputObject(obj){
	 
	 return true;
  }
  onCountryChange(obj){
	  
	  return true;
  }

  onSubmit() {
    this.recaptchaV3Service.execute('sign_up_individual')
      .subscribe((token) => console.log('recaptcha executed'));
    var validation = this.validateUserSignup(this.signupForm.value);
    if(validation == true)
    {
      this.submitting = true;
	    this.spinner.show();
      this.api.signup(this.signupForm.value).subscribe((data:any)=>{
        this.submitting = false;
		    this.spinner.hide();
        var res = data;
        this.toastr.success('', res.message);
        this.signupForm = this.fb.group({
          first_name: [''],
          last_name: [''],
          email: [''],
          title: [''],
          phone: [''],
          cv: [''],
          address_line_1: [''],
          address_line_2: [''],
          sector: [''],
          occupation: [''],
          country: [''],
          city: [''],
          state: [''],
          zip: [''],
          password: [''],
          confirm_password: [''],
          agreeTerms: [''],
          agreePrivacy: [''],
        });
		    this.router.navigate(['/login']);
        //window.location.reload();
      },(res:any)=>{
        this.submitting = false;
		    this.spinner.hide();
        if(res.error.data != undefined)
        {
          var temp = res.error.data;
          for (const [key, value] of Object.entries(temp)) {
            this.error[key] = value[0];
          }
        }
        this.toastr.error(res.error.message,'Error');
      })
    }
  }
  onSubmitOrg() {
    this.recaptchaV3Service.execute('sign_up_organization')
      .subscribe((token) => console.log('recaptcha executed'));
    var validation = this.validateOrgSignup(this.signupOrgForm.value);
    if(validation == true)
    {
      this.submitting = true;
		  this.spinner.show();
      this.api.signupOrg(this.signupOrgForm.value).subscribe((data:any)=>{
        this.submitting = false;
		    this.spinner.hide();
        var res = data;
        this.toastr.success('', res.message);
        this.signupOrgForm = this.fb.group({
          first_name: ['', Validators.required],
          last_name: ['', Validators.required],
          email: ['', Validators.required],
          title: [''],
          organization_name: [''],
          phone: [''],
          address_line_1: [''],
          address_line_2: [''],
          sector: [''],
          country: [''],
          city: [''],
          state: [''],
          zip: [''],
          password: ['', Validators.required],
          confirm_password: ['', Validators.required],
          agreeTerms: ['', Validators.required],
          agreePrivacy: ['', Validators.required],
          user_role: ['', Validators.required],
          no_of_employees: ['', Validators.required],
        });
		    this.router.navigate(['/login']);
      },(res:any)=>{
        this.submitting = false;
		    this.spinner.hide();
        //console.log(res.error);
        if(res.error.data != undefined)
        {
          var temp = res.error.data;
          for (const [key, value] of Object.entries(temp)) {
            this.errorOrg[key] = value[0];
          }
        }
        this.toastr.error(res.error.message,'Error');
      })
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.api.postFile(this.fileToUpload).subscribe((data:any)=>{
      //console.log(data.data.filename);
      this.fileName = data.data.filename;
    });
  }

  getOccupationList(){
    this.api.getOccupation().subscribe((data:any)=>{
      var res = data;
      this.occupationList = res.data;
    })
  }

  getSectorList(){
    this.api.getSector().subscribe((data:any)=>{
      var res = data;
      this.sectorList = res.data;
    })
  }
  getCountryList(){
    this.api.getCountry().subscribe((data:any)=>{
      var res = data;
      this.countryList = res.data;
    })
  }
  getStateList(){

    this.signupForm.value.state= '';
    this.signupForm.value.city= '';
    this.stateList = [];
    this.cityList = [];
   
    if(this.signupForm.value.country.length>0){
    this.api.getState(this.signupForm.value.country).subscribe((data:any)=>{
      var res = data;
      this.stateList = res.data;
     
      //console.log(this.stateList);
    })
  }
  }

  getCityList(){
    
    if(this.signupForm.value.state.length>0){
    this.api.getCity(this.signupForm.value.state).subscribe((data:any)=>{
      var res = data;
      this.cityList = res.data;
      //console.log(this.cityList);
    })
  }
  }

  getStateList2(){
    this.signupOrgForm.value.state = "";
    this.signupOrgForm.value.city = "";
    this.stateORGList = [];
    this.cityORGList = [];
    if(this.signupOrgForm.value.country>0){
    this.api.getState(this.signupOrgForm.value.country).subscribe((data:any)=>{
      var res = data;
      this.stateORGList = res.data;
      //console.log(this.stateList);
    })
  }
  }

  getCityList2(){
    if(this.signupOrgForm.value.state>0){
    this.api.getCity(this.signupOrgForm.value.state).subscribe((data:any)=>{
      var res = data;
      this.cityORGList = res.data;
      //console.log(this.cityList);
    })
  }
  }

  public handleAddressChange(address: Address) {
    console.log(address);
    this.signupForm.controls['address_line_1'].setValue(address.formatted_address);
    let postal_code:any = this.getComponentByType(address,"postal_code");
    
    let country:any = this.getComponentByType(address,"country");
    
    let state:any = this.getComponentByType(address,"administrative_area_level_1");
   
    let city:any = this.getComponentByType(address,"administrative_area_level_3");
   
    this.signupForm.controls['zip'].setValue(postal_code?.long_name);
    //this.signupForm.controls['country'].setValue(country?.long_name);
    this.signupForm.controls['state'].setValue(state?.long_name);
    this.signupForm.controls['city'].setValue(city?.long_name);
}

public handleAddressOrgChange(address: Address) {
  console.log(address);
  this.signupOrgForm.controls['address_line_1'].setValue(address.formatted_address);
  let postal_code:any = this.getComponentByType(address,"postal_code");
  
  let country:any = this.getComponentByType(address,"country");
  
  let state:any = this.getComponentByType(address,"administrative_area_level_1");
 
  let city:any = this.getComponentByType(address,"administrative_area_level_3");
 
  this.signupOrgForm.controls['zip'].setValue(postal_code?.long_name);
  //this.signupForm.controls['country'].setValue(country?.long_name);
  this.signupOrgForm.controls['state'].setValue(state?.long_name);
  this.signupOrgForm.controls['city'].setValue(city?.long_name);
}

getComponentByType(address:Address, type:any){
  if(!type)
  return '';

if (!address || !address.address_components || address.address_components.length == 0)
  return '';

type = type.toLowerCase();

for (let comp of address.address_components) {
  if(!comp.types || comp.types.length == 0)
      continue;

  if(comp.types.findIndex(x => x.toLowerCase() == type) > -1)
      return comp;
}

return '';
}
}
