import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../global';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-questionnaire-result',
  templateUrl: './questionnaire-result.component.html',
  styleUrls: ['./questionnaire-result.component.css']
})
export class QuestionnaireResultComponent implements OnInit {
  data:any;
  result:any;
  constructor(private global: Globals,private route:ActivatedRoute, private router: Router,private api: ApiService,private toastr: ToastrService) {

  }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('result_param'));
    console.log(this.data);
    if(this.data.group_id == undefined || this.data.user_id == undefined)
    {
      this.router.navigate(['user/manage-questionnaire']);
    }
    else
    {
      this.api.surveyResult(this.data).subscribe((data: any[])=>{
        //console.log('surveyResult');
        let res:any = data;
        this.result = res.data;
        //console.log(res.data);
      });
    }
  }

}
