import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { Globals } from '../global';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  auth:any;
  res:any;
  loginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  });
  submitting: boolean;

  constructor(private fb: FormBuilder,private titleService: Title,private metaTagService: Meta,private global: Globals, private api: ApiService,private toastr: ToastrService, private router: Router, private recaptchaV3Service: ReCaptchaV3Service,) { }

  errorFP = {
    email: ""
  };
  forgetPasswordForm = this.fb.group({
    email: ['', Validators.required]
  });

  ngOnInit(): void {
    //window.scrollTo(0, 0);
    this.auth = this.global.getUser();

    if(this.auth != undefined)
    {
      if(this.auth.role == 2)
      {
        this.router.navigateByUrl('user');
      }
      else
      {
        this.router.navigateByUrl('user');
        //this.router.navigateByUrl('user/manage-group');
      }
    }
  }

  onSubmit() {
    this.recaptchaV3Service.execute('login')
      .subscribe((token) => console.log('recaptcha executed'));
    this.api.login(this.loginForm.value).subscribe((data:any)=>{
      this.res = data;
      this.global.setUser(this.res.data.name,this.res.data.email,this.res.data.token,this.res.data.role,this.res.data.image);
      this.toastr.success('', this.res.message);

      //this.router.navigateByUrl('user');
      window.location.reload();
    },(error:any)=>{
      var res = error;
      console.log(res);
      this.toastr.error("Invalid credentials","Error");
    })
  }
  onSubmitForgetPassword() {
    this.recaptchaV3Service.execute('forgot_password')
      .subscribe((token) => console.log('recaptcha executed'));
    this.submitting = true;

      this.api.forgetPassword(this.forgetPasswordForm.value).subscribe((data:any)=>{
        this.submitting = false;
        this.res = data;
        this.toastr.success('', this.res.message);

      },(response:any)=>{
        this.submitting = false;
        console.log(response.error);
        this.toastr.error(response.error.data?.email?.[0] || response.error.data,"Error");
      })

  }

}
