<div id="mainContainer" >
<div class="row">
<div class="col-md-12"><div class="price-detail"  [innerHtml]="FullQuestionnairedata"></div></div>
    <div class="col-md-12">
        <p>Select a plan and click Buy Now. After your purchase is complete you will be assigned a Questionnaire Code to allow others to take the questionnaire.</p>
        <p>
            <b>Questionnaire:</b> TeamPlyerHR
        </p>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <table class="table table-border">
            <thead>
            <tr>
                <th colspan="2" >Plan</th>
            </tr>
            </thead>
            <!-- <tbody>
              <tr *ngFor="let item of data">
                <td>{{item.name}} - ${{item.amount}}</td>
                <td>
                    <div #paypal></div>
                </td>
              </tr>
            </tbody> -->
            <tbody>
                <tr *ngFor="let item of data">
                    <td>{{item.name}} - £{{item.amount}}</td>
                    <td>
                        <button class="btn btn-xs btn-info" (click)="renderButton(item)">Pay</button>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                      <app-full-payment-button [plan]="plan" *ngIf="showButton"></app-full-payment-button>
                    </td>
                </tr>
        </tbody>
    </table>
    </div>
</div>
</div>


<div class="payment" id="paymentSuccess" style="display: none;">
  <div class="payment_header">
     <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
  </div>
  <div class="content">
     <h1>Payment Success !</h1>
     <p>Questionnaire purchase successful. </p>
  </div>

</div>
<div class="payment" id="paymentError" style="display: none;">
  <div class="payment_header">
     <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
  </div>
  <div class="content">
     <h1>Payment Failed !</h1>
     <p>Questionnaire purchase failed, please try again. </p>
  </div>

</div>
