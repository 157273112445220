import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from "@angular/router";
import { Globals } from './global';
import { ToastrService } from 'ngx-toastr';



@Injectable()
export class AuthGuard implements CanActivate {
  auth:any;
  constructor(private router: Router,private global: Globals,private toastr: ToastrService) { }

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
    
      this.auth = this.global.getUser();
      if(this.auth != undefined)
      {
        if(this.auth.token != undefined)
        {
          resolve(true);
        }
        else
        {
          this.toastr.error('Please login to access..');
          resolve(false);
        }
        
      }
      else
      {
        this.toastr.error('Please login to access..');
        resolve(false);
      }            
    });
  }
}
