import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '../../global';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { PaypalButtonComponent } from '../../shared/paypal-button/paypal-button.component';
declare var paypal;

@Component({
  selector: 'app-purchase-subscription',
  templateUrl: './purchase-subscription.component.html',
  styleUrls: ['./purchase-subscription.component.css']
})
export class PurchaseSubscriptionComponent implements OnInit {
  CompanySubscriptiondata:any;
  paymentDetail:any;
  paypal:any;
  number_employee:number;
  showButton:boolean;
  data:any = [
    {"id":1,"title":"3 yr contract","number_survay":1,"amount":"240.00"}];
  constructor(private global: Globals,private route:ActivatedRoute,private api: ApiService,private toastr: ToastrService) {

   }

   ngOnInit(): void {
    //this.paypal = paypal;
	this.api.getMeta('company-subscription').subscribe((data: any[])=>{
      var res:any = data;
      this.CompanySubscriptiondata = res.data.content;
      
    });
	
    this.api.getSubscription().subscribe((data: any[])=>{
      let res:any = data;
      this.data = res.data;
    })
  }

  refreshButton(){
    this.showButton = false;
    setTimeout(()=>{
      this.showButton = true;
    }, 10);
  }


}
