import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../global';
import { DemoapiService } from '../../demoapi.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-demo-start-test',
  templateUrl: './demo-start-test.component.html',
  styleUrls: ['./demo-start-test.component.css']
})
export class DemoStartTestComponent implements OnInit {
  data:any;
  constructor(private global: Globals,private route:ActivatedRoute, private router: Router,private api: DemoapiService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('demo_test_param'));
    console.log(this.data);
  }

}
