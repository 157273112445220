import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from './global';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  baseUrl:string = "";
  httpOptions:any
  auth:any;
  constructor(private http: HttpClient,private global: Globals) {
    this.auth = this.global.getUser();
    this.baseUrl = this.global.APIURL;
   }

  getRenewalPlans(){
    return this.http.get(this.baseUrl+"api/user/renewal_plans");
  }

  demoRequest(param:any) {
    return this.http.post(this.baseUrl+"api/user/demo_request", param);
  }
  contactRequest(param:any) {
    return this.http.post(this.baseUrl+"api/user/contact", param);
  }
  login(param:any) {
    return this.http.post(this.baseUrl+"api/user/login", param);
  }
  signup(param:any) {
    return this.http.post(this.baseUrl+"api/user/register", param,);
  }

  signupOrg(param:any) {
    return this.http.post(this.baseUrl+"api/user/register_org", param);
  }
  forgetPassword(param:any) {
    return this.http.post(this.baseUrl+"api/user/forgot_password", param);
  }

  resetpassword(param:any) {
    return this.http.post(this.baseUrl+"api/user/resetpassword",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  getMeta(page:any) {
    return this.http.get(this.baseUrl+"api/meta?page="+page);
  }

  getFaqQuestion() {
    return this.http.get(this.baseUrl+"api/faq");
  }

  getHomeSlider() {
    return this.http.get(this.baseUrl+"api/homeslider");
  }

  getTestimonial() {
    return this.http.get(this.baseUrl+"api/testimonial");
  }

  getPartner() {
    return this.http.get(this.baseUrl+"api/pertner");
  }

  getNews() {
    return this.http.get(this.baseUrl+"api/news");
  }

  getNewsDetail(slug:any) {
    return this.http.get(this.baseUrl+"api/news?slug="+slug);
  }

  getCountry() {
    return this.http.get(this.baseUrl+"api/countries");
  }

  getState(countryID:any) {
    return this.http.get(this.baseUrl+"api/states?country_id="+countryID);
  }

  getCity(state_id:any) {
    return this.http.get(this.baseUrl+"api/cities?state_id="+state_id);
  }

  getOccupation() {
    return this.http.get(this.baseUrl+"api/occupation");
  }
  getSector() {
    return this.http.get(this.baseUrl+"api/sector");
  }
  getAppPlan() {
    return this.http.get(this.baseUrl+"api/demo/plan");
  }

  getAppPPCPlan() {
    return this.http.get(this.baseUrl+"api/app/ppc_plans");
  }

  getAppSubscriptionPlan() {
    return this.http.get(this.baseUrl+"api/app/subscription");
  }
  getPlans() {
    return this.http.get(this.baseUrl+"api/user/plans");
  }
   getVATPrice() {
    return this.http.get(this.baseUrl+"api/user/app/vatprice",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  getFullQuestionnairePurchase() {
    return this.http.get(this.baseUrl+"api/user/fullQuestionnairePurchase",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  getDemoQuestionnairePurchase() {
    return this.http.get(this.baseUrl+"api/user/appQuestionnairePurchase",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  getPPCPurchase() {
    return this.http.get(this.baseUrl+"api/user/appPPCPurchase",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  getRenewalPurchase() {
    return this.http.get(this.baseUrl+"api/user/renewalPurchase",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  getAppSubscriptionPurchase() {
    return this.http.get(this.baseUrl+"api/user/appSubscriptionPurchase",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  getSubscriptionPurchase() {
    return this.http.get(this.baseUrl+"api/user/subscriptionPurchase",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  getSubscription() {
    return this.http.get(this.baseUrl+"api/user/subscription");
  }
  questionnaireList() {
    return this.http.get(this.baseUrl+"api/user/questionnaire",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  questionnaireStatus() {
    return this.http.get(this.baseUrl+"api/user/questionnaire/status",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  questionnaireDetail(id:any) {
    return this.http.get(this.baseUrl+"api/user/questionnaire/detail?id="+id,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  invitee_list(id:any) {
    return this.http.get(this.baseUrl+"api/user/questionnaire/invitee_list?id="+id,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  surveyResult(param:any) {
    return this.http.post(this.baseUrl+"api/user/survey_result",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  surveyResultTeam(param:any) {
    return this.http.post(this.baseUrl+"api/user/survey_result_team",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  updatePayment(param:any) {
    return this.http.post(this.baseUrl+"api/user/updatePayment",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  updateRenewalPayment(param:any) {
    return this.http.post(this.baseUrl+"api/user/updateRenewalPayment",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  updateAppSubPayment(param:any) {
    return this.http.post(this.baseUrl+"api/user/updateAppSubscriptionPayment",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  updateSubscriptionPayment(param:any) {
    return this.http.post(this.baseUrl+"api/user/updateSubscriptionPayment",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }


  postValueEmail(param:any) {
    return this.http.post(this.baseUrl+"api/sendValueEmail",param);
  }

  postFile(fileToUpload: File) {
    console.log(fileToUpload);
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.baseUrl+"api/user/upload",formData);
  }

  postReport(frmData: any) {
    return this.http.post(this.baseUrl+"api/user/uploadReport",frmData,{headers: new HttpHeaders({"Authorization":this.auth.token})});
  }

  profileImage(fileToUpload: File) {
    console.log(fileToUpload);
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.baseUrl+"api/user/profile/image",formData,{headers: new HttpHeaders({"Authorization":this.auth.token})});
  }
  updateProfile(param:any) {
    return this.http.post(this.baseUrl+"api/user/profile/update",param,{headers: new HttpHeaders({"Authorization":this.auth.token})});
  }

  createTeam(param:any) {
    return this.http.post(this.baseUrl+"api/user/add_subgroup",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  addUserTeam(param:any) {
    return this.http.post(this.baseUrl+"api/user/add_user_subgroup",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  getTeams(id:any) {
    var param = {group_id:id};
    return this.http.post(this.baseUrl+"api/user/get_subgroup",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  updateTeamUsertype(param:any) {
    return this.http.put(this.baseUrl+"api/user/team_usertype",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  updateGroupUserRole(param:any) {
    return this.http.put(this.baseUrl+"api/user/group/role",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  sendInvite(param:any) {
    return this.http.post(this.baseUrl+"api/user/send_invite",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  deleteInGroup(param:any) {
    return this.http.post(this.baseUrl+"api/user/delete_in_group",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  unDeleteInGroup(param:any) {
    return this.http.post(this.baseUrl+"api/user/undelete_in_group",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  deleteInvite(param:any) {
    return this.http.post(this.baseUrl+"api/user/delete_invite",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  joinGroup(param:any) {
    return this.http.post(this.baseUrl+"api/user/join_group",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  getInvitation() {
    return this.http.get(this.baseUrl+"api/user/invitation",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  sendReminder(param:any) {
    return this.http.post(this.baseUrl+"api/user/send_reminder",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  getUserGroup() {
    return this.http.get(this.baseUrl+"api/user/group_joined",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  getQuestion(){
    return this.http.get(this.baseUrl+"api/user/questions",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  saveAnswer(param:any){
    return this.http.post(this.baseUrl+"api/user/questions/save_answer",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  setScore(param:any){
    return this.http.post(this.baseUrl+"api/user/questions/set_score",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  createGroup(param:any) {
    return this.http.post(this.baseUrl+"api/user/add_group",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  getProfile(){
    return this.http.get(this.baseUrl+"api/user/profile",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  renewAccount(param:any) {
    return this.http.post(this.baseUrl+"api/user/renewAccount",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  AssignfreeTrial(param:any) {
    return this.http.post(this.baseUrl+"api/user/asignfreetrial",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
  companyUserList() {
    return this.http.get(this.baseUrl+"api/user/companyuserlist",{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }

  sendMultipleInvite(param:any) {
    return this.http.post(this.baseUrl+"api/user/send_multiple_invite",param,{
      headers: new HttpHeaders({"Authorization":this.auth.token})
    });
  }
}
