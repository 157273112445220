import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'team-player-hr';
production = true;
  constructor(
    private metaTagService: Meta,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) { }

  ngOnInit() {
	  if(this.production==true){
	  if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
	  }

    this.metaTagService.addTags([
      { name: 'title' , content:"Team Player Hr"},
      { name: 'keywords', content: "James Lanas,Jim Lanas,Reduce Turnover,Increase Retention,Physician Match,Doctor match,empowering the job seeker,job seeker employer match,best fit,career,matching,employee,compatibility,turnover,time-to-hire,cost-per-hire" },
      { name: 'description' , content:"Our goal is to help you quickly find employees who will meet your exact needs and stay with your organization in the long run. How can we do this? The answer is simple: The Career Matching Assessment Tool (Team Player Hr) and its proprietary technology"}
    ]);
  }
  
}
