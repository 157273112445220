<ngx-spinner>
  <p style="font-size: 20px; color: white">Please Wait..</p>
</ngx-spinner>
<div class="row">
  <div class="col-md-12">
    <h4 *ngIf="userGroupList.length > 0">Questionaire Group Joined</h4>
    <ul>
      <li *ngFor="let item of userGroupList; let i = index">
          <a (click)="setTestParams(item)" routerLinkActive="router-link-active" href="javascript:;" >({{i+1}}) {{item.name}}</a>
      </li>
    </ul>
    <h4>Join a questionaire group</h4>
    <p>Employees or job applicants can join a questionaire group using the questionaire code and password provided by their
      employer. After joining a group you can start the questionaire.</p>
      <h5 *ngIf="invitationList.length > 0">You have pending invitation to join team</h5>
	  <p *ngIf="invitationList.length > 0">Please click on group name and join button. </p>
      <ul *ngIf="invitationList.length > 0 && invitationList[0].group != undefined">
        <li *ngFor="let item of invitationList; let i = index">
            <a (click)="showDetails(item)" href="javascript:;" >({{i+1}}) {{item.group.name}}</a>
        </li>
      </ul>
    <div class="group_from">
      <form (submit)="joinGroup()" onsubmit="return false;" method="post">
        <div class="form-group">
          <input type="text" [(ngModel)]="group.name" name="name" class="form-control" placeholder="Group Code" readonly required/>
        </div>
        <div class="form-group">
          <input type="submit" name="submit" class="btn btn-primary mt-2" value="Join" />
        </div>
      </form>

    </div>
  </div>
</div>
<hr>
<div class="row">
  <div class="col-md-12">
    <h4 *ngIf="userDemoGroupList.length > 0">App Questionaire Group Joined</h4>
    <ul>
      <li *ngFor="let item of userDemoGroupList; let i = index">
          <a (click)="setDemoTestParams(item)" routerLinkActive="router-link-active" href="javascript:;" >({{i+1}}) {{item.name}}</a>
      </li>
    </ul>
    <h4>Join a App questionaire group</h4>
    <p>Employees or job applicants can join a questionaire group using the questionaire code and password provided by their
      employer. After joining a group you can start the questionaire.</p>
      <h5 *ngIf="demoInvitationList.length > 0">You heve pending invitation to join team</h5>
      <ul>
        <li *ngFor="let item of demoInvitationList; let i = index">
            <a (click)="showDemoDetails(item)" href="javascript:;" >({{i+1}}) {{item.group.name}}</a>
        </li>
      </ul>
    <div class="group_from">
      <form (submit)="joinDemoGroup()" onsubmit="return false;" method="post">
        <div class="form-group">
          <input type="text" [(ngModel)]="demoGroup.name" name="name" class="form-control" placeholder="Group Code" readonly required/>
        </div>
        <div class="form-group">
          <input type="submit" name="submit" class="btn btn-primary mt-2" value="Join" />
        </div>
      </form>

    </div>
  </div>
</div>

