import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../global';
import { DemoapiService } from '../../demoapi.service';
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from '../../filter.pipe';
declare var $: any;
declare var Tagify: any;

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.css']
})
export class DemoComponent implements OnInit {
  auth:any;
  data:any=[];
  groupID:any;
  subGroupID:any;
  showAddGroupBtn:boolean = false;
  totalTeamMember:any = 0;
  obTeam:any = {group_id:'',name:''};
  dataDetail :any = {};
  searchName:string = '';
  searchName1:string = '';
  searchName2:string = '';
  searchName3:string = '';
  teamList:any = [];
  teamID:any;
  teamUsers:any = [];
  participentUsers:any = [];
  benchmarkUsers:any = [];
  inviteeList:any = [];
  subPlan:any = [];
  ppcPlan:any = [];
  ppcPland:any;
  showPayment:any = false;
  showPPCPayment:any = false;
  obGroup:any = {name:"",code:""};
  obInvite:any = {group_id:"",email:""};
  obTeamUser:any = {group_id:"",subgroup_id:"",user_id:""};
  resultOB:any = {};
  multipleInvites:any = {group_id:"",emails:[]};
  constructor(private global: Globals,private route:ActivatedRoute, private router: Router,private api: DemoapiService,private toastr: ToastrService) {
    this.auth = this.global.getUser();
  }

  ngOnInit(): void {
    //console.log(this.global.user);
    this.getQuestionareList();
  }

  getQuestionareList(){
    this.api.questionnaireList().subscribe((data: any[])=>{
      let res:any = data;
      this.data = res.data;
      if(res.data[0].subscription == false)
      {
        this.toastr.error(res.message,"");//3761,81
        this.getSubscriptionPlan();
      }
      else
      {
        this.showAddGroupBtn = (this.data.length > 0)?false:true;
      }

    },(error:any)=>{
      var res = error;

    });
  }
  getSubscriptionPlan(){
    this.api.getSubscriptionPlan().subscribe((data: any[])=>{
      let res:any = data;
      this.subPlan = res.data;
      this.showPayment = true;
      $("#SubscriptionPaymentModal").modal('show');
      //console.log(res);
    },(error:any)=>{
      var res = error;
      this.toastr.error(res.error.message,"");
    });
  }
  getPPCPlan(ob){
    this.api.getPPCPlan().subscribe((data: any[])=>{
      let res:any = data;
      this.ppcPlan = res.data;
      //this.ppcPland = res.data[0].status;
      if(res.data[0].status==1){
	  this.showPPCPayment = true;
	  } else {
		this.showTeamReport(ob);  
	  }
    },(error:any)=>{

    });
  }

  createGroup(){
    this.api.createGroup(this.obGroup).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success('Success', res.message);
      this.obGroup = {name:"",code:""};
      this.getQuestionareList();
    });
  }

  sendReminder(ob:any){

    this.api.sendReminder(ob).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success('Success', res.message);

    });
  }

  setResultParams(ob:any)
  {
    this.global.setResultParam(ob.survey_group_id,ob.profile_id);
    this.router.navigate(['user/survey-result']);
  }


  setTeamResultParams(ob:any)
  {
    if(this.benchmarkUsers.length == 0)
    {
      this.toastr.show('Please add atleast one benchmark user')
    }
    else if(this.participentUsers.length == 0)
    {
      this.toastr.show('Please add atleast one participant user')
    }
    else
    {
      this.totalTeamMember = this.benchmarkUsers.length + this.participentUsers.length;
    }
   console.log(this.benchmarkUsers);
    console.log(this.participentUsers);
    console.log(this.totalTeamMember);
	
    this.subGroupID = ob.subgroup_id;
    this.resultOB = ob;
    if(this.auth.role == 3)
    {
	
      this.getPPCPlan(this.resultOB);
	 
      $("#participentList").modal('hide');
      $("#PPCPaymentModal").modal('show');
	
    }
    else
    {
      $("#participentList").modal('hide');
      this.global.setDemoTeamResultParam(ob.group_id,ob.user_id,ob.subgroup_id,ob.user_type,0);
      this.router.navigate(['user/app-questionnaire/survey-result-team']);
    }
  }
  showTeamReport(ob){
    //console.log(ob);
    $("#PPCPaymentModal").modal('hide');
    this.global.setDemoTeamResultParam(this.resultOB.group_id,this.resultOB.user_id,this.resultOB.subgroup_id,this.resultOB.user_type,ob.id);
    this.router.navigate(['user/app-questionnaire/survey-result-team']);
  }
  saveUserTeam(){
    this.api.addUserTeam(this.obTeamUser).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success('Success', res.message);
      this.obTeam = {group_id:'',name:''};
      $("#addTeamModal").modal('hide');
      this.getDetails(this.obTeamUser.group_id);
    });
  }
  showAddTeam(ob:any){
    $("#addTeamModal").modal('show');
    this.obTeamUser.group_id = ob.survey_group_id;
    this.obTeamUser.user_id = ob.profile_id;
    this.obTeamUser.user_name = ob.user_name;
  }

  getDetails(id:any){
    this.groupID = id;
    this.obTeam.group_id = id;
    this.obInvite.group_id = id;
    this.multipleInvites.group_id = id;
    this.dataDetail = {};
    this.api.questionnaireDetail(id).subscribe((data: any[])=>{
      let res:any = data;
      this.dataDetail = res.data;

      this.dataDetail.survey_group.max_size = parseInt(this.dataDetail.survey_group.max_size);
      this.dataDetail.survey_group.total_renewals = parseInt(this.dataDetail.survey_group.total_renewals);

      console.log(this.dataDetail);
      if(this.dataDetail.is_admin == true)
      {
        this.getTeams();
        this.getInviteeList(id);
      }
    });
  }

  getInviteeList(id:any){
    this.api.invitee_list(id).subscribe((data: any[])=>{
      let res:any = data;
      this.inviteeList = res.data;
      console.log(res.data);
    });
  }
  showInvite(){
    //console.log(this.inviteeList);
    $("#inviteeList").modal('show');
  }

  getTeams(){
    this.api.getTeams(this.obTeam.group_id).subscribe((data: any[])=>{
      let res:any = data;
      this.teamList = res.data;
      //this.getPPCPlan();
    });
  }
  createTeam(){
    this.api.createTeam(this.obTeam).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success('Success', res.message);
      this.obTeam.name = "";
      this.getDetails(this.obTeam.group_id);
    },(res:any)=>{
      this.toastr.error(res.error.data.name[0],'Error');
    });
  }

  showParticipent(ob:any)
  {
    if(ob.user_list.length == 0)
    {
      this.toastr.error("Please add participant in this group.",'Error');
    }
    else
    {
      this.teamUsers = [];
      this.participentUsers = [];
      this.benchmarkUsers = [];
      this.teamID = ob.id;

      for(var i = 0; i < ob.user_list.length; i++)
      {
        if(ob.user_list[i].user_type == '' || ob.user_list[i].user_type == null)
        {
          this.teamUsers.push(ob.user_list[i]);
        }
        else if(ob.user_list[i].user_type == 'participant')
        {
          this.participentUsers.push(ob.user_list[i]);
        }
        else if(ob.user_list[i].user_type == 'benchmark')
        {
          this.benchmarkUsers.push(ob.user_list[i]);
        }
      }

      $("#participentList").modal('show');
    }
  }

  changeUsertype(index){
    this.updateUserType(this.teamUsers[index]);
    if(this.teamUsers[index].user_type == 'benchmark')
    {
      this.benchmarkUsers.push(this.teamUsers[index]);
      this.teamUsers.splice(index,1);
    }
    else if(this.teamUsers[index].user_type == 'participant')
    {
      this.participentUsers.push(this.teamUsers[index]);
      this.teamUsers.splice(index,1);
    }
	this.showPPCPayment = false;
  }

  removeParticipent(index){
    this.participentUsers[index].user_type = "";
    this.updateUserType(this.participentUsers[index]);
    this.teamUsers.push(this.participentUsers[index]);
    this.participentUsers.splice(index,1);
	this.showPPCPayment = false;
  }
  removeBenchmark(index){
    this.benchmarkUsers[index].user_type = "";
    this.updateUserType(this.benchmarkUsers[index]);
    this.teamUsers.push(this.benchmarkUsers[index]);
    this.benchmarkUsers.splice(index,1);
	this.showPPCPayment = false;
  }

  updateUserType(ob){
    console.log(ob);
    this.api.updateTeamUsertype(ob).subscribe((data: any[])=>{
      console.log(data);
    });
  }

  sendInvite(){
    this.api.sendInvite(this.obInvite).subscribe((data: any[])=>{
      let res:any = data;
      this.obInvite.email = "";
      this.toastr.success('', res.message);
    },(res:any)=>{
      this.toastr.error(res.error.data.email[0],'Error');
    });
  }
  deleteInGroup(ob){
    this.api.deleteInGroup(ob).subscribe((data: any[])=>{
      let res:any = data;
      this.obInvite.email = "";
      this.toastr.success('', res.message);
      this.getDetails(this.groupID);
    },(res:any)=>{
      this.toastr.error(res.error.data.email[0],'Error');
    });
  }
  unDeleteInGroup(ob){
    this.api.unDeleteInGroup(ob).subscribe((data: any[])=>{
      let res:any = data;
      this.obInvite.email = "";
      this.toastr.success('', res.message);
      this.getDetails(this.groupID);
    },(res:any)=>{
      this.toastr.error(res.error.data.email[0],'Error');
    });
  }

  resendInvite(ob:any){
    this.obInvite.group_id = ob.group_id;
    this.obInvite.email = ob.email;

    this.api.sendInvite(this.obInvite).subscribe((data: any[])=>{
      let res:any = data;
      this.obInvite.email = "";
      this.toastr.success('', res.message);

    },(res:any)=>{
      this.toastr.error(res.error.data.email[0],'Error');
    });
  }

  deleteInvite(ob:any){
    this.obInvite.group_id = ob.group_id;
    this.obInvite.email = ob.email;

    this.api.deleteInvite(this.obInvite).subscribe((data: any[])=>{
      let res:any = data;
      this.obInvite.email = "";
      this.toastr.success('', res.message);
      this.getInviteeList(ob.group_id);

    },(res:any)=>{
      this.toastr.error(res.error.data.email[0],'Error');
    });
  }

  renew(ob:any){
    console.log(ob);
    var dataUser:any = {profile_id:ob.profile_id,survey_group_id:ob.survey_group_id};
    this.api.renewAccount(dataUser).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success('User account activated successfuly.', res.message);
      this.getDetails(ob.survey_group_id);
    },(res:any)=>{
      this.toastr.error(res.error.data.email[0],'Error');
    });
  }

  openMultipleInvite(){
    //console.log(this.inviteeList);
    $("#multipleInvitee").modal('show');
    this.startTagsInput();
  }
  startTagsInput(){
    var input = document.querySelector('.customLook'),
    tagify = new Tagify(input, {
      // email address validation (https://stackoverflow.com/a/46181/104380)
      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      callbacks: {
        "invalid": (e)=>{console.log("invalid", e.detail)}
      },
      dropdown : {
        position: 'text',
        enabled: 1 // show suggestions dropdown after 1 typed character
      }
    });
    console.log(tagify)
  }



  sendMultipleInvite(){
    var emailData = JSON.parse($("#emails").val());
    var emails = [];
    for(let i=0;i < emailData.length;i++)
    {
      emails[i] = emailData[i].value;
    }
    this.multipleInvites.emails = emails;

    this.api.sendMultipleInvite(this.multipleInvites).subscribe((data: any[])=>{
      let res:any = data;
      this.obInvite.email = "";
      this.toastr.success('', res.message);
      $("#emails").val('');
      $("#multipleInvitee").modal('hide');
    },(res:any)=>{
      this.toastr.error(res.error.data.email[0],'Error');
    });
  }
}
