
<section id="slider" class="inner_bnr" style="background:url(assets/images/partners_bnr.jpg) no-repeat center center / cover;">
	<h2 class="upr">Purchase</h2>
</section>

<section id="partner_cont">
	<div class="container">
		<div class="row">
      <div class="col-lg-12">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="tab-1" data-toggle="tab" href="#tab_1" role="tab" aria-controls="home" aria-selected="true">Full Questionnaire</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" *ngIf="user == undefined" id="tab-2" data-toggle="tab" href="#tab_2" role="tab" aria-controls="app-q" aria-selected="true">Organization/ Company Subscription</a>
            <a class="nav-link" *ngIf="user != undefined" id="tab-2" [routerLink]="['/user/subscription']" routerLinkActive="active" aria-controls="app-q" aria-selected="true">Organization/ Company Subscription</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" *ngIf="user == undefined" id="tab-3" data-toggle="tab" href="#tab_3" role="tab" aria-controls="app-q" aria-selected="true">APP-based Questionnaire </a>
            <a class="nav-link" *ngIf="user != undefined" id="tab-3" [routerLink]="['/user/purchase-app-questionnaire']" routerLinkActive="active" aria-controls="app-q" aria-selected="true">APP-based Questionnaire</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="showgetAppSubscription()"  id="tab-4" data-toggle="tab" href="#tab_4" role="tab" aria-controls="app-q" aria-selected="true">Organizations/Company Based APP Subscriptions</a>
          </li>
      </ul>
      </div>
		   <div class="col-lg-12">
				<div class="partner_bx purchage_bx_nw">
          <div class="tab-content profile-tab" id="myTabContent" role="tablist">
			  <!--<div class="tab-pane fade show active price-detail" id="purchse_tab" role="tabpanel">
				  <div class="top-with-q">
					<h4>Company cost</h4>
					<p>Q = questionnaire <span>Issue IM to put on CV</span></p>
				  </div>
				  <ul class="bult">
					<li>3 year Subscription - Annual renewal Has an Account # assigned.
					  <ul>
						<li>240 Pounds per person/per year</li>
						<li>Full IM Questionnaire</li>
						<li>All participants that complete Q</li>
					  </ul>
					</li>
					<li>IF Participants have Full Q IM company pays 200 pounds.
					  <ul>
						<li>200 Pounds per IM</li>
					  </ul>
					</li>
					<li>Purchase APP Q
					  <ul>
						<li>.99 pence per APP Q</li>
						<li>100 + APP Q purchase min. purchase</li>
					  </ul>
					</li>
					<li>1 pound Per Click to Compare APP IM's
					  <ul>
						<li>APP IM's Example: 15 APP IM's = 15 pounds</li>
					  </ul>
					</li>
					<li>Monthly APP Subscriptions FEE
					  <ul>
						<li>100 pounds Monthly OR 1,200 paid annually upfront</li>
					  </ul>
					</li>
				  </ul>
				  <div class="top-with-q mt-3">
					<h4>Participant / Individual Cost</h4>
				  </div>
				  <ul class="bult">
					<li>Purchase Full IM Questionnaire from Website 240 Pounds
					  <ul>
						<li>Gets Two Full Q (New User)
						  <ul>
							<li>One for the purchaser & one to invite other</li>
							<li>Gets assigned IM ID</li>
						  </ul>
						</li>
					  </ul>
					</li>
					<li>Purchase Full Q from Mobile APP
					  <ul>
						<li>50 Pounds</li>
						<li>Get one Full Q for participant / individual</li>
						<li>Gets assigned IM ID</li>
					  </ul>
					</li>
					<li>Mobile APP Q
					  <ul>
						<li>.99 pence or CV and can purchase more Q after signed up</li>
						<li>Get two APP Q (New User)
						  <ul>
							<li>One for participant / individual and one for Invitee</li>
							<li>Gets assigned IM ID</li>
						  </ul>
						</li>
					  </ul>
					</li>
					<li>Invitee of Mobile APP
					  <ul>
						<li>Payees zero .0 Pence when invited</li>
						<li>Can Purchase more APP Q to invite others at .99pence</li>
						<li>Can Also purchase Full Q
						  <ul>
							<li>50 Pound cost
							  <ul>
								<li>Gets IM to put on CV/Resume</li>
							  </ul>
							</li>
						  </ul>
						</li>
					  </ul>
					</li>
					<li>Monthly APP Subscriptions FEE
					  <ul>
						<li>100 pounds Monthly OR 1,200 paid annually upfront</li>
					  </ul>
					</li>
				  </ul>
				</div>-->
		  
            <div class="tab-pane price-detail fade show active" id="tab_1" role="tabpanel" aria-labelledby="tab_1">
			
				<a data-toggle="collapse" href="javascript:void(0);" data-target="#accor_one" aria-expanded="true" aria-controls="accor_one" class="resp_accor">
					Full Questionnaire
				</a>

				<div id="accor_one" class="collapse show" data-parent="#myTabContent" role="tabpanel">
              <!--<h5>Full Questionnaire</h5>-->
			  <div [innerHtml]="FullQuestionnairedata"></div>
			 
			  
              <div id="mainContainer">
			  
			  
              <div class="row">
                  <div class="col-md-12">
                      <p>Select a plan and click Buy Now. After your purchase is complete you will be assigned a Questionnaire to allow others to take the questionnaire.</p>
                      <!--<p>
                          <b>Questionnaire:</b> TeamPlyerHR
                      </p>-->
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12">
                      <table class="table table-border">
                          <thead>
                          <tr>
                              <th colspan="2" >Plan</th>
                          </tr>
                          </thead>

                          <tbody>
                              <tr *ngFor="let item of data">
                                  <td>{{item.name}} - £{{item.amount}}</td>
                                  <td *ngIf="user != undefined">
                                      <button *ngIf="item.id != mobilemsg" class="btn btn-xs btn-info" (click)="renderButton(item)">Buy Now</button>
                                      <button *ngIf="item.id == mobilemsg" class="btn btn-xs btn-info" (click)="showMobleMsg()">Buy Now</button>
                                  </td>
                                  <td *ngIf="user == undefined">
                                      <button class="btn btn-xs btn-info" (click)="showLoginMsg()">Buy Now</button>
                                  </td>
                              </tr>
                              <tr>
                                  <td colspan="2" *ngIf="user != undefined">
                                    <app-full-payment-button [plan]="plan" *ngIf="showButton"></app-full-payment-button>
                                  </td>
                              </tr>
                      </tbody>
                  </table>
                  </div>
              </div>
              </div>
              <div class="payment" id="paymentSuccess" style="display: none;">
                <div class="payment_header">
                  <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
                </div>
                <div class="content">
                  <h1>Payment Success !</h1>
                  <p>Questionnaire purchase successful. </p>
                </div>

              </div>
				</div>
			</div>
            <div class="tab-pane price-detail" id="tab_2" role="tabpanel" aria-labelledby="profile-tab">
				
				<a data-toggle="collapse" *ngIf="user == undefined" href="javascript:void(0);" data-target="#accor_two" aria-expanded="false" aria-controls="accor_two" class="resp_accor collapsed">
					Organization/ Company Subscription
				</a>
				<a class="resp_accor collapsed" data-toggle="collapse" href="javascript:void(0);" data-target="#accor_two" *ngIf="user != undefined" aria-expanded="false" aria-controls="accor_two" [routerLink]="['/user/subscription']" routerLinkActive="active">Organization/ Company Subscription</a>
			
			<div id="accor_two" class="collapse" data-parent="#myTabContent" role="tabpanel">
			
              <!--<h5>Organization/ company Subscription</h5>-->
			  <div [innerHtml]="CompanySubscriptiondata"></div>
			   
				  
              <div class="row">
                <div class="col-md-12">
                    <p>
                        <b>Subscription:</b> TeamPlayerHR
                    </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                    <table class="table table-border">
                        <thead>
                        <tr>
                            <th>Plan</th>
							<th></th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>£{{data[0].amount}}/Employee x number of current employees </td>
                            
                                <td>
                                  <button class="btn btn-xs btn-info" (click)="showLoginMsg()">Buy Now</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
              </div>
			</div>
			</div>
            <div class="tab-pane price-detail" id="tab_3" role="tabpanel" aria-labelledby="profile-tab">
			
				<a data-toggle="collapse" *ngIf="user == undefined" href="javascript:void(0);" data-target="#accor_three" aria-expanded="false" aria-controls="accor_three" class="resp_accor collapsed">
					APP-based Questionnaire
				</a>
				<a class="resp_accor collapsed" data-toggle="collapse" href="javascript:void(0);" data-target="#accor_three" *ngIf="user != undefined" aria-expanded="false" aria-controls="accor_three" [routerLink]="['/user/purchase-app-questionnaire']" routerLinkActive="active">APP-based Questionnaire</a>
			
			<div id="accor_three" class="collapse" data-parent="#myTabContent" role="tabpanel">
			
              <!--<h5>App Questionnaire</h5>-->
			  <div [innerHtml]="AppQuestionnairedata"></div>
			  
              <div class="row">
                <div class="col-md-12">
				<table class="table table-border">
                          <thead>
                          <tr>
                              <th colspan="2" >Plan</th>
                          </tr>
                          </thead>

                          <tbody>
                              <tr >
                                  <td *ngIf="dataApp.length >0">£{{dataApp[0].amount}}/App Questionnaire</td>
                                  <td *ngIf="user == undefined">
                                      <button class="btn btn-xs btn-info" (click)="showLoginMsg()">Buy Now</button>
                                  </td>
                                  
                              </tr>
							  <tr >
                                  <td *ngIf="dataApp.length >0">£{{dataApp[0].amount * 100}} For 100 Min purchase x £0.99/App Questionnaire </td>
                                  <td *ngIf="user == undefined">
                                      <button class="btn btn-xs btn-info" (click)="showLoginMsg()">Buy Now</button>
                                  </td>
                                  
                              </tr>
                              
                      </tbody>
                  </table>
                 

                </div>
              </div>
            
				</div>
			</div>
            <div class="tab-pane price-detail" id="tab_4" role="tabpanel" aria-labelledby="profile-tab">
			
				<a data-toggle="collapse" href="javascript:void(0);" data-target="#accor_four" aria-expanded="false" aria-controls="accor_four" class="resp_accor collapsed">
					Organizations/Company Based APP Subscriptions
				</a>
			
			<div id="accor_four" class="collapse" data-parent="#myTabContent" role="tabpanel">
              <!--<h5>App Subscription</h5>-->
			<div [innerHtml]="CompanyAppSubscriptionsdata"></div>
				  
			  <table class="table table-border">
                          <thead>
                          <tr>
                              <th colspan="2" >Plan</th>
                          </tr>
                          </thead>

                          <tbody>
                              <tr *ngFor="let item of dataAppSub">
                                  <td>{{item.title}} - £{{item.amount}}</td>
                                  <td *ngIf="user == undefined">
                                      <button class="btn btn-xs btn-info" (click)="showLoginMsg()">Buy Now</button>
                                  </td>
                                  
                              </tr>
                              
                      </tbody>
                  </table>
              <!--<div class="row">
                <div class="col-lg-12" *ngIf="dataAppSub.length > 0">
                  <p>{{dataAppSub[0].title}}</p>
                  <p>{{dataAppSub[0].detail}}</p>
                  <p>

                  </p>
                </div>
              </div>-->

				</div>
            </div>

          </div>

				</div>
		   </div>
		</div>
	</div>
</section>
