import { Component, OnInit, Input ,ViewChild, ElementRef, ViewChildren, QueryList, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../global';
import { DemoapiService } from '../../demoapi.service';
import { ToastrService } from 'ngx-toastr';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal'; 
declare var paypal;
declare var $: any;
declare var braintree:any;
declare var require: any;
@Component({
  selector: 'app-demo-paypal-button',
  templateUrl: './demo-paypal-button.component.html',
  styleUrls: ['./demo-paypal-button.component.css']
})
export class DemoPaypalButtonComponent implements OnInit {
  //braintree = require('BrainTreeWeb');
  // braintree = require('braintree-web');
  client = require('braintree-web/client');
  hostedFields = require('braintree-web/hosted-fields');

  @Input() numberOfEmployees:number;
  @ViewChild( 'paypal1' , { static:true } ) paypalElement1 : ElementRef;
  baseUrl:string = "https://teamplayerhr.com/admin/";
  paymentDetail:any;
  paypal:any;
  tnumber_survey:any;
  amount:any=null;
  plan_id:any;
  vdata:any= {"id":1,"vat":""};
  vatpricef:any;
  amountwithvat:any;
  taxprice:any;
  showPButton=false;
  data:any = [
    {"id":1,"title":"3 yr contract","number_survay":1,"amount":"150.00"}];
	public payPalConfig?: IPayPalConfig;
  constructor(private global: Globals,private route:ActivatedRoute,private api: DemoapiService,private toastr: ToastrService,private router: Router) {
    this.baseUrl = this.global.APIURL;
   }

  ngOnInit(): void {
	  
	  
    console.log("ngOnInit");

    //this.paypal = paypal;
    this.api.getPlan().subscribe((data: any[])=>{
      let res:any = data;
      this.data = res.data;
      console.log(this.numberOfEmployees);
	  this.api.getVATPrice().subscribe((vdata: any[])=>{
	  let vres:any = vdata;
      this.vdata = vres.data;
	  this.initConfig(this.data[0], this.data[0].amount * this.numberOfEmployees, this.data[0].amount, this.vdata.vat);
      //this.renderPaypalButton(this.data[0].amount * this.numberOfEmployees,this.data[0].id);
     // this.renderBraintree(this.data[0].amount * this.numberOfEmployees,this.data[0].id);
	  })
    })
    // this.api.getBrainTreeToken().subscribe((data: any[])=>{
    //   let res:any = data;
    //   console.log(res.data);
    // })
  }

  updatePayment(id:any,res:any){
    let param = {id:id,number_survay:this.numberOfEmployees,data:JSON.stringify(res)};


    this.api.updateDemoPayment(param).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success(res.message);
      $("#mainContainer").hide();
      $("#paymentSuccess").show();
    })
  }

  renderPaypalButton(amount:number,id){
    var amt = amount.toFixed(2);

    this.paypal.Button.render({
      // Configure environment
      env: this.global.paypalConfig.env,
      client: {
        sandbox: this.global.paypalConfig.client.sandbox,
        production: this.global.paypalConfig.client.production
      },
      // Customize button (optional)

      style: {
        layout:  'horizontal',
        color:   'blue',
        shape:   'rect',
        label:   'paypal'
      },

      // Enable Pay Now checkout flow (optional)
      commit: true,

      // Set up a payment
      payment: (data, actions) =>{
        return actions.payment.create({
          transactions: [{
            amount: {
              total: amt,
              currency: 'GBP'
            }
          }]
        });
      },
      // Execute the payment
      onAuthorize: (data, actions) => {
        return actions.payment.execute().then(() => {
          this.updatePayment(id,data);
        });
      }
    }, this.paypalElement1.nativeElement);

  }
  renderBraintree(amount:number,id){
    var amt = amount.toFixed(2);
    this.amount = amt;
    this.plan_id = id;
  }
  onPaymentStatus(res:any)
  {
    console.log('--Payment Status--');
    console.log(res);
    if(res.status != undefined && res.status == "COMPLETED")
    {
      let param = {id:this.plan_id,number_survay:this.numberOfEmployees,data:JSON.stringify(res)};
	  console.log(param);
      this.api.updateDemoPayment(param).subscribe((data: any[])=>{
        let res:any = data;
        this.toastr.success(res.message);
         $("#mainContainer").hide();
         $("#paymentSuccess").show();
		  setTimeout(()=>{
          this.router.navigateByUrl('user/app-questionnaire');
        }, 3000);
      })
    }
    else
    {
      $("#mainContainer").hide();
      $("#paymentError").show();
    }

  }
  
  private initConfig(plandata:any, amount:number, unitprice, vatprice): void {
	  console.log(vatprice);
	  this.showPButton=true;
	  var peramount;
	  var amt;
	  peramount= typeof unitprice === 'number' ? unitprice.toFixed(2) : 0;
	  amt = amount;
	  this.amount = amt;
	  this.vatpricef = vatprice;
	  this.taxprice= parseFloat(amt)*parseInt(vatprice)/100;
    //this.amountwithvat = parseFloat(amt)+ parseFloat(this.taxprice) ;
    var gamountwithvat = parseFloat(amt)+ parseFloat(this.taxprice);
    this.amountwithvat = gamountwithvat.toFixed(2);
    this.plan_id = plandata.id;
    this.tnumber_survey = this.numberOfEmployees;
      this.payPalConfig = {
      currency: 'EUR',
      clientId: this.global.paypalConfig.env=='production' ? this.global.paypalConfig.client.production : this.global.paypalConfig.client.sandbox,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'EUR',
              value: this.amountwithvat,
              breakdown: {
                item_total: {
                  currency_code: 'EUR',
                  value: this.amount.toFixed(2)
                },
				tax_total: {
                  currency_code: 'EUR',
                  value: this.taxprice.toFixed(2)
                }
              }
            },
            items: [
              {
                name: plandata.name,
                quantity: this.tnumber_survey,
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'EUR',
                  value: peramount,
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        //this.showSuccess = true;
		this.onPaymentStatus(data);
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        console.log('OnError', err);
		$("#mainContainer").hide();
      $("#paymentError").show();
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };
    }
}
