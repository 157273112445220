import {  Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../global';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from '../../filter.pipe';
import { RenewalPaymentButtonComponent } from '../../shared/renewal-payment-button/renewal-payment-button.component';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
declare var Tagify: any;
@Component({
  selector: 'app-manage-questionnaire',
  templateUrl: './manage-questionnaire.component.html',
  styleUrls: ['./manage-questionnaire.component.css']
})
export class ManageQuestionnaireComponent implements OnInit {
  data:any=[];
  survey_result_team:any=[];
  planData:any=[];
  showAddGroupBtn:boolean = false;
  showAddGroupMsg = true;
  obTeam:any = {group_id:'',name:''};
  dataDetail :any = {};
  searchName:string = '';
  searchName1:string = '';
  searchName2:string = '';
  searchName3:string = '';
  teamList:any = [];
  teamID:any;
  teamUsers:any = [];
  participentUsers:any = [];
  benchmarkUsers:any = [];
  obGroup:any = {name:"",code:""};
  obInvite:any = {group_id:"",email:""};
  inviteeList:any = [];
  showButton:boolean = false;
  obTeamUser:any = {group_id:"",subgroup_id:"",user_id:""};
  multipleInvites:any = {group_id:"",emails:[]};
  tagify:any = null;
  constructor(private global: Globals,private route:ActivatedRoute, private router: Router,private api: ApiService,private toastr: ToastrService,private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {
	  //console.log(localStorage.getItem('result_team_param'));
    this.survey_result_team = localStorage.getItem('result_team_param')?JSON.parse(localStorage.getItem('result_team_param')):'';
    //console.log(this.survey_result_team);
    this.getDetails(this.survey_result_team.group_id, true);
    //window.scrollTo(0, 0);
    this.getQuestionareList();
    this.getRenewalPlan();
  }

  getQuestionareList(){
    this.api.questionnaireList().subscribe((data: any[])=>{
      let res:any = data;
      this.data = res.data;
      //this.showAddGroupBtn = (this.data.length > 0)?false:true;
      //this.showAddGroupBtn = false;
      this.showAddGroupMsg = (this.data.length > 0)? false : true;
    });
  }

  createGroup(){
    this.api.createGroup(this.obGroup).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success('Success', res.message);
      this.obGroup = {name:"",code:""};
      this.getQuestionareList();
    });
  }

  sendReminder(ob:any){
    console.log(ob);
    this.api.sendReminder(ob).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success('Success', res.message);
      console.log(res);
    });
  }

  setResultParams(ob:any)
  {
    this.global.setResultParam(ob.survey_group_id,ob.profile_id);
    this.router.navigate(['user/survey-result']);
  }

  setTeamResultParams(ob:any)
  {
    console.log(ob);
    const participantIds = this.participentUsers.map((p:any) => p.user_id);
    this.global.setTeamResultParam(ob.group_id,ob.user_id,ob.subgroup_id,ob.user_type,participantIds);
    $("#participentList").modal('hide');
    this.router.navigate(['user/survey-result-team']);
  }
  saveUserTeam(){
    this.api.addUserTeam(this.obTeamUser).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success('Success', res.message);
      this.obTeam = {group_id:'',name:''};
      $("#addTeamModal").modal('hide');
      //this.getDetails(this.obTeamUser.group_id);
      this.obTeam.group_id = this.obTeamUser.group_id;
      this.getTeams();
    });
  }
  showAddTeam(ob:any){
    $("#addTeamModal").modal('show');
    this.obTeamUser.group_id = ob.survey_group_id;
    this.obTeamUser.user_id = ob.profile_id;
    this.obTeamUser.user_name = ob.user_name;
  }

  getDetails(id:any, init?:boolean){
    const isIndividualUser = this.global.user?.role === 2;

    if(isIndividualUser && !init) {
      return this.toastr.error("This user doesn't have rights to view that group.",'Error');
    }
    this.obTeam.group_id = id;
    this.obInvite.group_id = id;
    this.multipleInvites.group_id = id;
    this.dataDetail = {};

      this.api.questionnaireDetail(id).subscribe((data: any[])=>{
        let res:any = data;
        this.dataDetail = res.data;
        this.dataDetail.survey_group.max_size = parseInt(this.dataDetail.survey_group.max_size);
        this.dataDetail.survey_group.total_renewals = parseInt(this.dataDetail.survey_group.total_renewals);

        const participants = []
        res.data.survey_participants.map((participant: any) => {
          const index = participants.findIndex(p => p.email === participant.email)
          if(index < 0) {
            participants.push(participant);
          } else {
            participants[index].team_name += `, ${participant.team_name}`;
          }
        })
        this.dataDetail.survey_participants = participants;

        if(this.dataDetail.is_admin == true)
        {
          this.getTeams();
          this.getInviteeList(id);
        }
      });

  }
  getInviteeList(id:any){
    this.api.invitee_list(id).subscribe((data: any[])=>{
      let res:any = data;
      this.inviteeList = res.data;
      console.log(res.data);
    });
  }
  showInvite(){
    //console.log(this.inviteeList);
    $("#inviteeList").modal('show');
  }
  getTeams(){
    this.api.getTeams(this.obTeam.group_id).subscribe((data: any[])=>{
      let res:any = data;
      this.teamList = res.data;
    });
  }
  createTeam(){
    this.api.createTeam(this.obTeam).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success('Success', res.message);
      this.getTeams();
      this.obTeam = {name:''};

    },(res:any)=>{
      this.toastr.error(res.error.data.name[0],'Error');
    });
  }

  showParticipent(ob:any)
  {
    if(ob.user_list.length == 0)
    {
      this.toastr.error("Please add participant in this group.",'Error');
    }
    else
    {
      this.teamUsers = [];
      this.participentUsers = [];
      this.benchmarkUsers = [];
      this.teamID = ob.id;

      for(var i = 0; i < ob.user_list.length; i++)
      {
        if(ob.user_list[i].user_type == '' || ob.user_list[i].user_type == null)
        {
          this.teamUsers.push(ob.user_list[i]);
        }
        else if(ob.user_list[i].user_type == 'participant')
        {
          this.participentUsers.push(ob.user_list[i]);
        }
        else if(ob.user_list[i].user_type == 'benchmark')
        {
          this.benchmarkUsers.push(ob.user_list[i]);
        }
      }
	  
	  //console.log(this.teamUsers);

      $("#participentList").modal('show');
    }
  }

  changeUsertype(index){
    this.updateUserType(this.teamUsers[index]);
    if(this.teamUsers[index].user_type == 'benchmark')
    {
      this.benchmarkUsers.push(this.teamUsers[index]);
      this.teamUsers.splice(index,1);
    }
    else if(this.teamUsers[index].user_type == 'participant')
    {
      this.participentUsers.push(this.teamUsers[index]);
      this.teamUsers.splice(index,1);
    }
  }

  removeParticipent(index){
    this.participentUsers[index].user_type = "";
    this.updateUserType(this.participentUsers[index]);
    this.teamUsers.push(this.participentUsers[index]);
    this.participentUsers.splice(index,1);
  }
  removeBenchmark(index){
    this.benchmarkUsers[index].user_type = "";
    this.updateUserType(this.benchmarkUsers[index]);
    this.teamUsers.push(this.benchmarkUsers[index]);
    this.benchmarkUsers.splice(index,1);
  }

  updateUserType(ob){
    console.log(ob);
    this.api.updateTeamUsertype(ob).subscribe((data: any[])=>{
      console.log(data);
    });
  }

  updateGroupUserRole(ob){
    console.log('updateGroupUserRole');
    console.log(ob);
    this.api.updateGroupUserRole(ob).subscribe((data: any[])=>{
      console.log(data);
      let res:any = data;
      this.toastr.success('Success', res.message);
      this.getDetails(ob.survey_group_id);
    });
  }

  resendInvite(ob:any){
    this.obInvite.group_id = ob.group_id;
    this.obInvite.email = ob.email;
    this.spinner.show();
    this.api.sendInvite(this.obInvite).subscribe((data: any[])=>{
      this.spinner.hide();
      let res:any = data;
      this.obInvite.email = "";
      this.toastr.success('', res.message);

    },(res:any)=>{
      this.toastr.error(res.error.data.email[0],'Error');
    });
  }

  deleteInvite(ob:any){
    this.obInvite.group_id = ob.group_id;
    this.obInvite.email = ob.email;

    this.api.deleteInvite(this.obInvite).subscribe((data: any[])=>{
      let res:any = data;
      this.obInvite.email = "";
      this.toastr.success('', res.message);
      this.getInviteeList(ob.group_id);

    },(res:any)=>{
      this.toastr.error(res.error.data.email[0],'Error');
    });
  }

  sendInvite(){
    if(!this.obInvite.email) {
      return this.toastr.error("The Email / IM field is required.",'Error');
    }

    this.spinner.show();
    this.api.sendInvite(this.obInvite).subscribe((data: any[])=>{
      this.spinner.hide();
      let res:any = data;
      this.obInvite.email = "";
      this.toastr.success('', res.message);
      this.getInviteeList(this.obInvite.group_id);
    },(res:any)=>{
      this.spinner.hide();
      //purchase_renewal
      if(res.error.data.purchase_renewal != undefined && res.error.data.purchase_renewal == true)
      {
        this.toastr.error('Please purchase renewal.','Error');
        $("#RenewalPaymentList").modal('show');
        this.showButton = true;

      }
      else
      {
        this.toastr.error(res.error.data.email[0],'Error');
      }

    });
  }

  deleteInGroup(ob){
    this.api.deleteInGroup(ob).subscribe((data: any[])=>{
      let res:any = data;
      this.obInvite.email = "";
      this.toastr.success('', res.message);
      this.getDetails(ob.survey_group_id);
    },(res:any)=>{
      this.toastr.error(res.error.data.email[0],'Error');
    });
  }
  unDeleteInGroup(ob){
    this.api.unDeleteInGroup(ob).subscribe((data: any[])=>{
      let res:any = data;
      this.obInvite.email = "";
      this.toastr.success('', res.message);
      this.getDetails(ob.survey_group_id);
    },(res:any)=>{
      this.toastr.error(res.error.data.email[0],'Error');
    });
  }

  getRenewalPlan(){
    this.api.getRenewalPlans().subscribe((data: any[])=>{
      let res:any = data;
      this.planData = res.data;
      this.showButton = true;
    })
  }

  renew(ob:any){
    //console.log(ob);
    var dataUser:any = {profile_id:ob.profile_id,survey_group_id:ob.survey_group_id};
    this.api.renewAccount(dataUser).subscribe((data: any[])=>{
      let res:any = data;
      this.toastr.success('User account activated successfuly.', res.message);
      this.getDetails(ob.survey_group_id);
    },(res:any)=>{
      if(res.error.data.purchase_renewal != undefined && res.error.data.purchase_renewal == true)
      {
        this.toastr.error('Please purchase renewal.','Error');
        $("#RenewalPaymentList").modal('show');
        this.showButton = true;

      }
      else
      {
        this.toastr.error(res.error.data.email[0],'Error');
      }
    });
  }
  openMultipleInvite(){
    //console.log(this.inviteeList);
    $("#multipleInvitee").modal('show');
    this.startTagsInput();
  }

  startTagsInput(){
    if(this.tagify) {
      this.tagify?.removeAllTags();
      this.tagify?.destroy();
    }

    var input = document.querySelector('.customLook');
    this.tagify = new Tagify(input, {
      // email address validation (https://stackoverflow.com/a/46181/104380)
      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      callbacks: {
        "invalid": (e)=>{console.log("invalid", e.detail)}
      },
      dropdown : {
        position: 'text',
        enabled: 1 // show suggestions dropdown after 1 typed character
      }
    });
  }

  sendMultipleInvite(){
    var emailData = JSON.parse($("#emails").val());
    var emails = [];
    for(let i=0;i < emailData.length;i++)
    {
      emails[i] = emailData[i].value;
    }
    this.multipleInvites.emails = emails;
    this.spinner.show();
    this.api.sendMultipleInvite(this.multipleInvites).subscribe((data: any[])=>{
      this.spinner.hide();
      let res:any = data;
      this.obInvite.email = "";
      this.toastr.success('', res.message);
      $("#emails").val('');
      $("#multipleInvitee").modal('hide');
      this.getInviteeList(this.multipleInvites.group_id);
    },(res:any)=>{
      this.spinner.hide();
      this.toastr.error(res.error.data?.email[0] || 'Internal server error','Error');
    });
  }

}
