import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Globals } from '../global';
import { ApiService } from '../api.service';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  title = 'TeamPlayerHR : FAQ';
  metadata:any;
  questionData:any;
  activeAccordian:any;
  constructor(private titleService: Title,private metaTagService: Meta,private global: Globals, private api: ApiService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.setMeta();
    this.getQuestion();
  }

  toggleAccordian(i){
    this.activeAccordian = i;
    //this.activeAccordian = (this.activeAccordian != i)? i:-1;
  }
  getQuestion(){
    this.api.getFaqQuestion().subscribe((data:any)=>{
      var res:any = data;
      this.questionData = res.data;
      console.log(this.questionData);
    });
  }

  setMeta(){
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Our goal is to help you quickly find employees who will meet your exact needs and stay with your organization in the long run. How can we do this? The answer is simple: The Career Matching Assessment Tool (Team Player Hr) and its proprietary technology 2' }
    );
    this.api.getMeta('faq').subscribe((data: any[])=>{
      var res:any = data;
      this.metadata = res.data;
      this.titleService.setTitle(this.metadata.title);
      this.metaTagService.updateTag(
        { name: 'description', content: this.metadata.description }
      );
      this.metaTagService.updateTag(
        { name: 'keywords', content: this.metadata.keyword }
      );
    });
  }

}
