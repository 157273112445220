
<section id="slider" class="inner_bnr" style="background:url(assets/images/partners_bnr.jpg) no-repeat center center / cover;">
	<h2 class="upr">Privay Policy</h2>
</section>

<section id="partner_cont">
	<div class="container">
		<div class="row">
		   <div class="col-lg-12">
				<div class="partner_bx">
					<div class="row">
            <div class="col-md-12">
              <h4>Our Commitment To Privacy</h4>
              <p>Your privacy is important to us. To better protect your privacy we provide this notice explaining our onlineinformation practices and the choices you can make about the way your information is collected and used. Tomake this notice easy to find, we make it available on our homepage and at every point where personallyidentifiable information may be requested.</p>
              <h4>The Information We Collect</h4>
              <p>This notice applies to all information collected or submitted on the CMSI or CMATool website. On some pages, youcan order products, make requests, and register to receive materials. The types of personal information collectedat these pages are:</p>
              <div class="row">
                <div class="col-md-12">
                  <ul class="cslist">
                    <li>Name</li>
                    <li>Address</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                    <li>Credit/Debit Card Information</li>
                    <li>(etc.)</li>
                  </ul>
                </div>
              </div>
              <p></p>
              <h4>The Way We Use Information</h4>
              <p>We use the information you provide about yourself when placing an order to complete that order and taking theCMATool questionnaire for matching purposes. We do not share this information with outside parties except to theextent necessary to complete that order.</p>
              <p>We use the information you provide about someone else when placing an order only to expedite the matchingprocess. We do not share this information with outside parties except to the extent necessary to complete thatorder.</p>
              <p>We use return email addresses to answer the email we receive. Such addresses are not used for any other purposeand are not shared with outside parties.</p>
              <p>You can register with our website if you would like to receive information on our matching services. Informationyou submit on our website will not be used for this purpose unless you fill out the registration form.</p>
              <p>We use non-identifying and aggregate information to better design our website and to share with advertisers. Forexample, we may tell an advertiser that X number of individuals visited a certain area on our website, or that Ynumber of men and Z number of women filled out our registration form, but we would not disclose anything thatcould be used to identify those individuals.</p>
              <p>Finally, we never use or share the personally identifiable information provided to us online in ways unrelated tothe ones described above without also providing you an opportunity to opt-out or otherwise prohibit suchunrelated uses.</p>
              <h4>Our Commitment To Data Security</h4>
              <p>To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have putin place appropriate physical, electronic, and managerial procedures to safeguard and secure the information wecollect online.</p>
              <h4>Our Commitment To Children's Privacy</h4>
              <p>Protecting the privacy of the very young is especially important. For that reason, we never collect or maintaininformation at our website from those we actually know are under 13, and no part of our website is structured toattract anyone under 13.</p>
              <h4>How You Can Access Or Correct Your Information</h4>
              <p>You can access all your personally identifiable information that we collect online and maintain by the editingprocedure. We use this procedure to better safeguard your information.</p>
              <p>You can correct factual errors in your personally identifiable information by sending us a request that crediblyshows error.</p>
              <p>To protect your privacy and security, we will also take reasonable steps to verify your identity before grantingaccess or making corrections. How To Contact Us</p>
              <p>Should you have other questions or concerns about these privacy policies, please call us at 1-866-891-1022 orsend us an email at info@teamplayerhr.com.</p>
            </div>
          </div>
				</div>
		   </div>
		</div>
	</div>
</section>
