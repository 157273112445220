
<section id="slider" class="inner_bnr" style="background:url(assets/images/signup_bnr.jpg) no-repeat center center / cover;">
	<h2 class="upr">Login</h2>
</section>

<section id="signup_cont">
	<div class="container">
		<div class="row">
			<div class="col-lg-8 offset-lg-2 col-md-12">
				<h3 class="mb-4">Login</h3>
				<div class="signup_form">
					<form method="post" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
						<div class="form-group">
							<label>Email <span class="astik">*</span></label>
							<input type="text" formControlName="email"  class="form-control" required />
						</div>
						<div class="form-group">
							<label>Password <span class="astik">*</span></label>
							<input type="password" formControlName="password"  class="form-control" required />
						</div>
						<div class="clearfix"></div>
						<div class="form-group subm_btn mt-3">
							<button type="submit" class="theme-btn" [disabled]="!loginForm.valid">Submit</button>
							<a class="float-right theme-btn" href="javascript:void(0);" data-target="#forget_password" data-toggle="modal" type="modal" data-dismiss="modal">Forgot Password</a>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</section>


<div class="modal fade custom_modal" id="forget_password" tabindex="-1" role="dialog" aria-labelledby="demo_form_modal" aria-hidden="true">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Forgot Password</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body demo_form">
				<form [formGroup]="forgetPasswordForm" (ngSubmit)="onSubmitForgetPassword()">

					<div class="form-group">
						<input type="text" class="form-control" formControlName="email"  placeholder="Email Id" />
            <span class="error" *ngIf="errorFP.email.length > 0">
              {{errorFP.email}}
            </span>
					</div>
					<div class="clearfix"></div>
					<div class="form-group mb-0 mt-1">
						<input type="submit" class="btn btn-primary" name="submit" value="Submit" [disabled]="submitting">
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
