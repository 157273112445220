import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;

@Component({
  selector: 'app-company-user',
  templateUrl: './company-user.component.html',
  styleUrls: ['./company-user.component.css']
})
export class CompanyUserComponent implements OnInit {
  userlist: any=[];
  searchName:any=''
  no_of_participant:any='';
  no_of_invitee:any='';
  currentCuser: any;
  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
    ) { }

  ngOnInit(): void {
    this.getCompanyUserList();
  }

  getCompanyUserList(){
    this.api.companyUserList().subscribe((data: any[])=>{
      let res:any = data;
      this.userlist = res.data;
      //this.showAddGroupBtn = (this.data.length > 0)?false:true;
      //this.showAddGroupBtn = false;
     // this.showAddGroupMsg = (this.data.length > 0)? false : true;
    });
  }

  assignFreeModal(ob:any)
  {
     console.log(ob);
     this.no_of_participant=0;
     this.no_of_invitee=0;
     this.currentCuser=ob;
    // this.global.setTeamResultParam(ob.group_id,ob.user_id,ob.subgroup_id,ob.user_type);
    $("#assignModal").modal('show');
  }

  assignFreetrial(ob:any){
    this.spinner.show();
    //console.log(ob);
    var dataUser:any = {user_id:ob.id,company_id:ob.company_id, no_of_participant:this.no_of_participant, no_of_invitee:this.no_of_invitee};
    this.api.AssignfreeTrial(dataUser).subscribe((data: any[])=>{
      this.spinner.hide();
      let res:any = data;
      this.toastr.success('Subscription updated successfuly.', res.message);
      $("#assignModal").modal('hide');
      this.getCompanyUserList();
     // this.getDetails(ob.survey_group_id);
    },(res:any)=>{
      this.spinner.hide();
      $("#assignModal").modal('show');
     
    });
  }


}
