
<section id="news_cont">
	<div class="container">
		<div class="row">
			<div class="col-lg-7 col-md-12 news-detail-pg mb-3 mb-lg-0">
				<figure>
					<img src="{{baseUrl+'storage/app/public/'+news.feature_image}}" />
				</figure>
				<h4>{{news.title}}</h4>
				 <div [innerHTML]="news.content"></div> 
			</div>
			<div class="col-lg-5 col-md-12">
				<div class="more_news">
					<ul>
						<li *ngFor="let item of newsList" >
              <div *ngIf="news.id != item.id">
                <figure>
                  <img src="{{baseUrl+'storage/app/public/'+item.feature_image}}" />
                </figure>
                <div class="nws_cnt">
                  <h4>{{item.title}}</h4>
                  <!-- <p>{{item.description}}...</p> -->
                  <a class="readmore"  routerLinkActive="router-link-active"  href="news/{{item.slug}}">
                    know more <i class="flaticon-right-arrow"></i>
                    <span></span>
                  </a>
                </div>
              </div>
						</li>

					</ul>
				</div>
			</div>
		</div>
	</div>
</section>
