<div class="row">
    <div class="col-md-12">
      <a [routerLink]="['/user/app-questionnaire/question']" routerLinkActive="router-link-active" class="btn btn-xs btn-info">Begin App Questionaire</a>
      <h4>TeamPlayerHR Introduction</h4>
      <p>Hello and welcome to the TeamPlayerHR! You are on your way to finding the most compatible job seekers/employers who share your professional goals and work styles.</p>

      <h4>What is the TeamPlayerHR?</h4>
      <p>The TeamPlayerHR is a comprehensive questionnaire that provides you with a detailed work profile. The work profile shows those things that are important to you in your work environment. The TeamPlayerHR can help us identify job seekers/employers who are likely to be the most compatible 'match' with you. </p>

      <h4>What do I have to do?</h4>
      <p>The TeamPlayerHR is simple to complete. You will be asked a series of questions, and you will be asked to select an answer. You should answer as fast as you can because questions are time based and will fade out after a period of time. However, don’t worry: we have found that most people have no trouble answering the questions; and, if you haven’t answered all questions, the computer will come back and prompt you again.

        There will be four sections. At the beginning of each section, you will be given specific instructions. Each time you complete a section, your answers will be automatically saved. If you fail to answer a question, the computer will come back and prompt you.

        It is important that when you are filling out the TeamPlayerHR, you should go with the first answer that seems right to you. Do not think too long or hard about any answer. And, remember—there are no right or wrong answers. Just be as honest as you can be and trust your first response. </p>
        <a [routerLink]="['/user/app-questionnaire/question']"  class="btn btn-xs btn-info">Begin App Questionaire</a>
    </div>
  </div>

