import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], filter: any): any {    
    var tmp:any = [];
    var search = "";
    var value = "";
    if (!items || !filter) {
        return items;
    }
    items.forEach(item => {
      search = filter.value.toLowerCase();
      value = item[filter.key].toLowerCase();
      if(value.indexOf(search) >= 0)
      {
        tmp.push(item);
      }      
    });
    return tmp;
}

}
