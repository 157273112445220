
<section id="slider" class="inner_bnr" style="background:url(assets/images/contact_bnr.jpg) no-repeat center center / cover;">
	<h2 class="upr">"What’s your IM?"</h2>
</section>

<section id="aboutus">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 align-self-center order-2 order-md-1">
				<div class="about_cnt">
					<h1 class="mb-3">What’s your IM?</h1>
					<div class="subhdng">By James Lanas, TeamPlayerHR, Group CEO</div>
					<div class="RightTxtb">
						<p>IM (noun) – pronounced "eam" as in team</p>
						<p>a person’s "IM" Intrinsic Matrix- compatibility profile </p>
					</div>
					<p>"What’s your IM?" is the question with the answer only a click away.</p>
					<p>The burning questions that everyone wants to know when they meet new people:</p>
					<div class="FirstPara">
						<figure><img src="assets/images/im_pic1.jpg" class="" /></figure>
						<ul>
							<li>Are we compatible?</li>
							<li>Do I like you?</li>
							<li>Can I trust you?</li>
							<li>Have we anything in common?</li>
							<li>Can we do business together?</li>
							<li>And a whole lot more…</li>
						</ul>
					</div>
					<div class="SecoundPara">
						<figure><img src="assets/images/im_pic2.jpg" class="" /></figure>
						<p>it’s true everywhere but in recruitment the real problem has been masked because of the world recession. People have been routed to their roles in a false state of loyalty, as the job market for many has been stagnant, or falling.</p>
						<p>But those times are beginning to change and the pressures building from the skills shortage and lack of talented people will start to impact the job market.</p>
						<p>So those that do become available won’t be there for long!</p>
						<p>Hence the process of recruitment will need help to speed it up whilst not making costly mistakes.</p>
						<div class="clearfix"></div>
					</div>
					<p>The answer to "What’s your IM?" is a large part of the answer to the recruitment/hiring and retention problem.</p>
					<p>Why? Because <span class="logoText"><b>Team</b>Player<sup>HR</sup></span> is now available so that you can quickly compare all potential candidates/participants (their IMs) with your current team. Unlike any <span class="logoText"><b>Team</b>Player<sup>HR</sup></span> other tool in the market does not measure inherent psychological traits it is an assessment of compatibility of the candidate/participants and the organization. It works on the simple basis,
					that like minds attracts and work well together. But it doesn’t stop there. It can easily be customized to fit your purpose.</p>
					<p><span class="logoText"><b>Team</b>Player<sup>HR</sup></span> Is designed as an intelligent matching tool for jobseekers and employers, but can be used lots of other ways including finding your future life partner. It’s quick, simple and online. Candidates answer various questions, enabling the software to build a compatibility profile. And then you can be matched to anyone. Speed dating will never be the same again!</p>
					<p>How does <span class="logoText"><b>Team</b>Player<sup>HR</sup></span> help the recruitment/hiring process speed up?</p>
					<ul class="helpList">
						<li>The questionnaire is completed even before being shortlisted</li>
						<li>The compatibility of the candidate is ranked against either an organization’s culture as a whole, a sub-team, or specific individuals (eg, the line manager)</li>
						<li>Results are taken into account by both the employer and candidate as part of the decision making process. It can produce quicker, compatible, shortlists and adds value to the process</li>
					</ul>
					<p>There are further advantages of this objective assessment approach. For instance compatibility reports can be used to support recruitment decisions from accusations of discrimination and provides objective evidence in support of selection.</p>
					<p>There are lots of tools companies and recruiters can use to sort CVs and even without interview you can assess whether a candidate is qualified and experienced.</p>
					<p>With <span class="logoText"><b>Team</b>Player<sup>HR</sup></span> you can now go one step further and preselect compatible candidates to shortlist, again without the necessity to interview. The recruitment process can then continue knowing you have a list of candidates that fit!</p>
					<div class="thartPara">
						<figure><img src="assets/images/im_pic3.jpg" class="" /></figure>
						<p>As <span class="logoText"><b>Team</b>Player<sup>HR</sup></span> builds, the network of candidates will grow and options become almost limitless.</p>
						<p>Already "What’s your IM?" App is planned and could easily become a speed dating game played in any social context. It could also speed up business networking and accelerate business activity too.</p>
						<p>Naturally we respect everyone’s rights and your approval will always be requested, optionally activated by your location.</p>
						<div class="clearfix"></div>
					</div>
					<div class="SecoundPara">
						<figure><img src="assets/images/im_pic4.jpg" class="" /></figure>
						<p>As to what the future holds for <span class="logoText"><b>Team</b>Player<sup>HR</sup></span> will only be limited by your own imagination. But if it involves putting teams of people together who are compatible, within your specific context, we’ll be able to provide a solution. If  you  would  further  information  or  like  a  trial  of <span class="logoText"><b>Team</b>Player<sup>HR</sup></span> please contact:</p>
						<p>Jim Lanas, Founder - CEO <a href="mailto:jim@TeamPlayerHR.com">jim@TeamPlayerHR.com</a></p>
						<p><a href="tel:+16197920808">USA +1 619-792-0808</a> &nbsp;&nbsp; <a href="tel:+447734950648">UK +44 (0)7734 950648</a></p>
						<div class="clearfix"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

