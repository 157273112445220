<div class="container emp-profile">

        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
              <form [formGroup]="profileImageForm"  enctype="multipart/form-data">
                <div class="profile-img">
                    <img *ngIf="userData.image == null || userData.image == '' " src="../../../assets/images/profile-user.png" alt=""/>
                    <img *ngIf="userData.image != null && userData.image != '' " src="{{baseURL}}storage/app/public/{{userData.image}}" alt=""/>
                    <div class="file btn btn-lg btn-primary">
                        Change Photo
                        <input type="file" name="file" (change)="handleFileInput($event.target.files)"/>
                    </div>
                </div>
              </form>

            </div>
            <div class="col-lg-5 col-md-5 col-sm-6 col-12">
                <div class="profile-head">
                  <h5>
                    {{capitalizeFirstLetter(userData.first_name)}} {{capitalizeFirstLetter(userData.last_name)}}
                  </h5>
                  <h6 *ngIf="userData.occupation_data != undefined">
                      {{userData.occupation_data.name}}
                  </h6>

                </div>
            </div>
            <div class="co-lg-3 col-md-3 col-sm-12 col-12">
                <input type="button" (click)="editProfile()" class="profile-edit-btn" name="btnAddMore" value="Edit Profile"/>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">About</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="app-q-tab" data-toggle="tab" href="#app-q" role="tab" aria-controls="app-q" aria-selected="true">App Questionnaire</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="app-sub-tab" data-toggle="tab" href="#app-sub" role="tab" aria-controls="app-sub" aria-selected="true">App Subscription</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="app-ppc-tab" data-toggle="tab" href="#app-ppc" role="tab" aria-controls="app-ppc" aria-selected="true">PPC</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="q-tab" data-toggle="tab" href="#q" role="tab" aria-controls="q" aria-selected="true">Full Questionnaire</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="sub-tab" data-toggle="tab" href="#sub" role="tab" aria-controls="app-sub" aria-selected="true">Subscription</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="renewal-tab" data-toggle="tab" href="#renewal" role="tab" aria-controls="app-renewal" aria-selected="true">Renewal</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-12">
                <div class="tab-content profile-tab" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>IM ID</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{userData.im}}
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>Name</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{capitalizeFirstLetter(userData.first_name)}} {{capitalizeFirstLetter(userData.last_name)}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{auth.email}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>Phone</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p>{{userData.phone}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>Profession</label>
                                    </div>
                                    <div class="col-md-6" >
                                        <p *ngIf="userData.occupation_data != undefined">{{userData.occupation_data.name}}</p>
                                    </div>
                                </div>
                                <div class="row" *ngIf="userData.cv != null">
                                  <div class="col-md-6">
                                      <label>CV</label>
                                  </div>
                                  <div class="col-md-6">
                                      <p>
                                        <a target="_blank" [href]="'https://teamplayerhr.com/admin/storage/app/public/'+userData.cv" [download]="userData.cv">Open</a>
                                      </p>
                                  </div>
                                </div>
                    </div>
                    <div class="tab-pane" id="app-q" role="tabpanel" aria-labelledby="profile-tab">
                      <h6>App Questionnaire Purchase History</h6>
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Amount</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of appQPurchaseList">
                            <td>{{item.plan_title}}</td>
                            <td>£{{item.amount}}</td>
                            <td>{{item.on_date}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="tab-pane" id="app-sub" role="tabpanel" aria-labelledby="profile-tab">
                      <h6>App Subscription Purchase History</h6>
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Expire On</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of appSubPurchaseList">
                            <td>{{item.plan_title}}</td>
                            <td>£{{item.amount}}</td>
                            <td>{{item.on_date}}</td>
                            <td>{{item.expire_on}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="tab-pane" id="app-ppc" role="tabpanel" aria-labelledby="profile-tab">
                      <h6>PPC Purchase History</h6>
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Amount</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of ppcPurchaseList">
                            <td>{{item.plan_title}}</td>
                            <td>£{{item.amount}}</td>
                            <td>{{item.on_date}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="tab-pane" id="q" role="tabpanel" aria-labelledby="profile-tab">
                      <h6>Full Questionnaire Purchase History</h6>
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Amount</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of fullQPurchaseList">
                            <td>{{item.plan_title}}</td>
                            <td>£{{item.amount}}</td>
                            <td>{{item.on_date}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="tab-pane" id="sub" role="tabpanel" aria-labelledby="profile-tab">
                      <h6>Subscription Purchase History</h6>
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Expire On</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of subscriptionPurchaseList">
                            <td>{{item.plan_title}}</td>
                            <td>£{{item.amount}}</td>
                            <td>{{item.on_date}}</td>
                            <td>{{item.expire_on}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="tab-pane" id="renewal" role="tabpanel" aria-labelledby="profile-tab">
                      <h6>Renewal Purchase History</h6>
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Amount</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of renewalPurchaseList">
                            <td>{{item.plan_title}}</td>
                            <td>£{{item.amount}}</td>
                            <td>{{item.on_date}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
            </div>
        </div>

</div>

<!-- Update Profile Modal -->
<div class="modal" id="updateProfileModal" >
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Update Profile</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form class="form" >
          <div class="form-group">
            <label>First Name</label>
            <input [(ngModel)]="userProfile.first_name" class="form-control" name="first_name" id="first_name"/>
            <span class="error" *ngIf="errorOrg.first_name.length > 0">
              {{errorOrg.first_name}}
            </span>
          </div>
          <div class="form-group">
            <label>Last Name</label>
            <input [(ngModel)]="userProfile.last_name" class="form-control" name="last_name" id="last_name"/>
            <span class="error" *ngIf="errorOrg.last_name.length > 0">
              {{errorOrg.last_name}}
            </span>
          </div>
          <div class="form-group">
            <label>Phone</label>
            <input type="text" [(ngModel)]="userProfile.phone" class="form-control" name="phone" id="phone"/>
            <span class="error" *ngIf="errorOrg.phone.length > 0">
              {{errorOrg.phone}}
            </span>
          </div>
          <div class="form-group">
            <label>Profession</label>
            <select [(ngModel)]="userProfile.occupation" name="occupation" id="occupation" class="form-control" >
              <option value="">Select Occupation</option>
							<option *ngFor="let item of occupationList" value="{{item.id}}">{{item.name}}</option>
            </select>
            <span class="error" *ngIf="errorOrg.occupation.length > 0">
              {{errorOrg.occupation}}
            </span>
          </div>
        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-info"  (click)="saveProfile()">Save</button>
      </div>

    </div>
  </div>
</div>
